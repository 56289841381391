.product-overview-wrap {
  overflow: visible !important;

  > .banner {
    position: relative;
    margin-bottom: 30px;
    aspect-ratio: 834/257;

    > a {
      display: block;
    }

    .text {
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    > .banner-price {
      position: absolute;
      left: 3.5%;
      bottom: 5%;

      > table {
        width: 350px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
  
        @include breakpoint-max(1000px) {
          width: 300px;
        }

        .center {
          text-align: center;
        }

        .bold {
          font-weight: bold;
        }
        
        > thead {
          th {
            padding: 8px 5px 8px 10px;
            background: #323234;
            color: #fff;
            font-weight: normal;
            font-size: 13px;
  
            @include breakpoint-max(1000px) {
              padding: 5px 5px 5px 10px;
              font-size: 12px;
            }
          }

          .sale {
            font-weight: bold;
            text-align: center;
          }
        }

        > tbody {
          td {
            padding: 3px 5px 3px 10px;
            border-bottom: 1px solid #ccc;
  
            @include breakpoint-max(1000px) {
              padding: 2px 5px 2px 10px;
              font-size: 12px;
            }
          }

          .price-gram {
            background: #d30600;
            color: #fff;
          }
        }
      }
    }
  }
  
  @include breakpoint-max(1200px) {
    padding: 0 20px;
  }

  &.jewellery, &.auction {
    padding: 0;

    // @include breakpoint-max(1200px) {
    //   padding: 0 20px 20px 0;
    // }

    > .body {
      @include breakpoint-max(1200px) {
        padding: 0 20px;
      }
    }
  }

  &.auction {
    // padding: 0 20px 0 0;

    @include breakpoint-max(1200px) {
      padding: 0 0 20px;
    }

    > .banner {
      > a {
        font-weight: 500;
        width: auto;
        display: inline-block;
        padding: 0 10px;
        font-size: 13px;
        height: 32px;
        line-height: 32px;
        position: absolute;
        top: 53%;
        left: 4.8%;

        &.btn-cta-secondary {
          top: 69%;
        }

        @include breakpoint-max(1024px) {
          font-size: 12px;
          height: 28px;
          line-height: 28px;

          &.btn-cta-secondary {
            top: 73%;
          }
        }
      }
    }

    > .body {
      @include breakpoint-max(1200px) {
        padding: 0 20px;
      }
    }
  }

  > .header {
    margin: 20px 0 0;

    &.jewellery, &.auction {

      @include breakpoint-max(1200px) {
        padding: 0 0 20px 20px;
      }
    }

    > h1 {
      margin: 0 20px 5px 0;
      line-height: 20px;
      font-size: $standard-font-size-heading;
    }

    > .filter-heading {
      margin: 0 0 7px;
      line-height: 20px;

      > .filter-control {
        margin-right: 6px;
        @include no-select();
        cursor: pointer;

        > span {
          margin-right: 3px;
          vertical-align: -2px;
          font-size: $standard-font-size;
        }
      }

      > .result {
        font-style: italic;
        font-size: 12px;
        vertical-align: -1px;
      }
    }

    .right {
      float: right;
      // margin-top: 5px;
      text-align: right;

      @include breakpoint-max(1200px) {
        float: none;
        padding: 5px 0;
        display: block;
        clear: both;
        text-align: left;
      }

      > .bs-tooltip-2 {
        position: relative;
        padding-top: 0;
        padding-bottom: 8px;
        color: $orange-mid;
        font-size: 14px;

        @include breakpoint-max(990px) {
          width: auto;
        }

        > img {
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align: -3px;
        }

        > .body {
          position: absolute;
          top: 25px;
          left: auto !important;
          right: -200px !important;
          width: 600px;
          color: #333;
          text-align: left;
          font-size: 14px;

          @include breakpoint-max(1200px) {
            left: 0 !important;
            right: auto !important;
            width: 550px;
          }

          > .arrow {
            display: none;
          }

          p, ul, li {
            font-size: 14px;
          }

          p, ul {
            margin: 0 0 10px;
          }

          ul {
            list-style: disc;
            margin-left: 20px;
          }
        }
      }
      
      > a {
        margin-bottom: 5px;
        display: block;
        font-size: 14px;

        > img {
          margin-right: 3px;
          vertical-align: -3px;
        }
      }
    }
  }

  > .location-change-wrap {
    margin: 10px 0;
    padding: 15px 20px;
    background: #f3f3f3;

    label {
      margin: 0;
    }

    select {
      margin-left: 4px;
      padding: 2px;
      background: transparent;
      border-radius: 3px;
      border: 1px solid #a2a2a2;
    }
  }

  > .secondary-title {
    margin: 0;
    padding: 30px 0 0;

    > span {
      margin-right: 15px;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
    }
  }

  > .buy-items {
    > .no-product {
      margin: 20px 0;
    }

    > .items {
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
