.skeleton-loader {
  &.load-solid, .load-solid {
    @include skeleton-solid();
    border-radius: 5px;
    min-height: 30px;
  }

  &.load-shine, .load-shine {
    @include skeleton-shiny();
    border-radius: 5px;
    min-height: 25px;
    border-radius: 5px;
  }
}