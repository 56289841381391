.backdrop1 {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 30%);
  z-index: 19;

  > .popup-login {
    position: relative;
    top: 10%;
    width: 370px;
    margin: 0 auto;
    padding: 40px 40px 15px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    .btn-close-popup {
      position: absolute;
      right: -9px;
      top: -9px;
      
      > img {
        background: #fff;
        border-radius: 18px;
        height: 31px;
        width: 31px;
        border: 2px solid #fff;
        box-sizing: content-box;
      }
    }

    > .login {
      font-size: 16px;
      color: #666;

      h3 {
        padding-top: 0;
        margin: 0 0 20px;
        font-size: 24px;
        font-weight: normal;
        color: #333;

        > img {
          width: 18px;
          height: 21px;
          vertical-align: -3px;
          margin-right: 7px;
        }
      }

      > p.message {
        color: #333;
        font-size: 13px;
        padding: 2px 0 10px;
      }

      .forgot-wrap {
        text-align: right;

        > .forgot {
          font-size: 14px;
          color: #808285;
        }
      }

      .btn-common {
        width: 100%;
        margin: 10px 0;
        padding: 12px 20px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }

      > .register {
        padding-top: 10px;
        color: #000;

        > h3 {
          font-size: $standard-font-size;
          font-weight: bold;
        }

        a {
          &:hover {
            color: $orange-mid;
          }
        }
      }
    }
  }

  > .popup-trusted-machine, 
  > .popup-authenticate-ip, 
  > .popup-2fa, 
  > .popup-2fa-no-account, 
  > .popup-details-2fa,  
  > .popup-reset-password,
  > .popup-document-upload,
  > .popup-email-us,
  > .popup-notify-me,
  > .popup-change-currency,
  > .popup-storage-location,
  > .popup-location,
  > .popup-portfolio-location,
  > .popup-share-via-email,
  > .popup-details-2fa-google, 
  > .popup-2fa-google,
  > .popup-no-account-2fa-google,
  > .popup-one-time-change-location {
    position: relative;
    top: 10%;
    width: 400px;
    margin: 0 auto;
    padding: 20px 30px 25px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > h3 {
      margin: 0 0 10px;
      font-size: 18px;
      font-weight: bold;
    }

    > .btn-close-popup {
      position: absolute;
      top: 17px;
      right: 30px;
    }

    > .content {
      > .btn-common {
        width: 100%;
        padding: 12px 20px;
        text-align: center;
        font-size: 14px;
      }

      > .buttons {
        > .btn-common-radius {
          margin: 0 7px;
        }

        > .btn-grey {
          background: #cdcdcd;
          border-color: #cdcdcd;
        }

      }
    }
  }

  > .popup-email-us {
    width: 500px;

    h3 {
      margin-bottom: 20px;
    }
    
    textarea {
      resize: none;
      overflow-x: hidden;
    }
  }

  > .popup-2fa, > .popup-2fa-no-account, > .popup-details-2fa {
    .form-field-with-button {
      > .form-field {
        width: 65%;
      }

      > .btn-common {
        width: 30%;
        margin-top: 10px;
        padding: 5px 0;
        text-align: center;
        font-size: 12px;
        font-weight: normal;

        &.disabled {
          background: #c6c6c6;
          border: 1px solid #c6c6c6;
          pointer-events: none;
        }
      }
    }
  }

  > .popup-details-2fa-google, 
  > .popup-2fa-google,
  > .popup-no-account-2fa-google {
    .info {
      margin-bottom: 15px;

      > figure {
        width: 50%;
        text-align: center;
      }

      > div {
        width: 50%;
        margin-top: 15px;
        padding: 0 20px;
        text-align: center;
        border-left: 2px solid #ddd;

        > span {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
        }

        > .secret-key {
          padding: 15px 10px;
          background: #f2f2f2;
          overflow-wrap: break-word;
        }
      }
    }
  }

  > .popup-add-alerts {
    position: relative;
    top: 10%;
    max-width: 350px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > .body {
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      border-radius: 5px;

      > .title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid $orange-light;
      }

      > .content {
        font-size: 12px;
        margin-bottom: 10px;
        
        > p {
          font-size: 12px;
        }

        > .checkbox-one-line {
          width: 100%;

          > label {
            > .bullion-checkbox {
              margin-left: 0;
            }
          }
        }
      }
        
      > .buttons {
        text-align: center;

        > div {
          margin: 0 3px 0 0;
          padding: 3px 10px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  > .popup-tnc {
    position: relative;
    top: 10%;
    width: 550px;
    height: 75%;
    padding: 10px 10px 0;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      padding: 0 0 5px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    .btn-close-popup {
      position: absolute;
      top: 14px;
      right: 15px;
    }

    > .body {
      width: 100%;
      height: 92%;
      margin: 0 auto;
      overflow-y: scroll; 
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;
    }
  }

  > .popup-photos {
    position: relative;
    top: 10%;
    width: 600px;
    margin: 0 auto;
    padding: 20px 30px 25px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    .btn-close-popup {
      position: absolute;
      top: 20px;
      right: 31px;
    }

    > .body {
      > .name {
        margin-bottom: 0;
        font-size: $standard-font-size;
        font-weight: bold;
      }

      > .date {
        font-size: 14px;
      }

      > figure {
        position: relative;
        min-height: 300px;
        margin-top: 10px;
        text-align: center;
        border: 1px solid #ccc;
        overflow: hidden;

        > .carousel {
          z-index: 2;
        }

        > .loading {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;

          > .bullion-loader {
            position: relative;
            font-size: 10px;
            text-indent: -9999em;
            border-top: 0.7em solid $orange-mid;
            border-right: 0.7em solid $orange-mid;
            border-bottom: 0.7em solid $orange-mid;
            border-left: 0.7em solid rgba(192, 104, 1, 50%);
            transform: translateZ(0);
            animation: load 1.1s infinite linear;
          }

          .bullion-loader,
          .bullion-loader::after {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;
          }
        }

        > .prev {
          position: absolute;
          top: 50%;
          margin-top: -21px;
          right: -1px;
          z-index: 5;
          @extend %img-swipe-right-arrow;
          cursor: pointer;
        }

        > .next {
          position: absolute;
          top: 50%;
          margin-top: -21px;
          left: -1px;
          z-index: 5;
          @extend %img-swipe-left-arrow;
          cursor: pointer;
        }
      }
    }
  }

  > .popup-myaccount-bullion-photo {
    position: relative;
    top: 10%;
    width: 400px;
    margin: 0 auto;
    padding: 20px 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    .btn-close-popup {
      position: absolute;
      top: 20px;
      right: 35px;
    }

    > .title {
      width: 310px;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }

    > .body {
      position: relative;
      width: 100%;
      min-height: 200px;
      text-align: center;
      border: 1px solid #ccc;

      > img {
        position: relative;
        width: 70%;
        z-index: 2;
      }

      > .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        > .bullion-loader {
          position: relative;
          font-size: 10px;
          text-indent: -9999em;
          border-top: 0.7em solid $orange-mid;
          border-right: 0.7em solid $orange-mid;
          border-bottom: 0.7em solid $orange-mid;
          border-left: 0.7em solid rgba(192, 104, 1, 50%);
          transform: translateZ(0);
          animation: load 1.1s infinite linear;
        }

        .bullion-loader,
        .bullion-loader::after {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin: -25px 0 0 -25px;
        }
      }
    }
  }

  > .popup-document-upload {
    position: relative;
    top: 10%;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      padding: 0 0 5px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    .btn-close-popup {
      position: absolute;
      top: 14px;
      right: 15px;
    }

    > .body {
      width: 100%;
      margin: 0 auto;
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;
    }

    .progress-upload {
      height: 15px;
      margin: 10px 0;
      border-radius: 0;
      border: 1px solid darken(#ccc, 20%);
      background: #ccc;

      .bar {
        background: $orange-mid;
        width: 0;
        height: 13px;
      }
    }
  }

  > .popup-youtube, > .popup-virtual-tour {
    position: relative;
    top: 10%;
    width: 600px;
    margin: 0 auto;
    padding: 10px;
    background: transparent;

    .btn-close-popup {
      position: absolute;
      top: -5px;
      right: 10px;
    }

    > .embed-responsive {
      margin-top: 20px;
    }
  }

  > .popup-virtual-tour {
    .btn-close-popup {
      top: -25px;
    }
  }

  > .popup-added-item-cart {
    position: relative;
    top: 10%;
    width: 380px;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > .title {
      margin: 0 0 10px;
      padding: 10px 0;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ccc;
    }
    
    .btn-close-popup {
      position: absolute;
      top: 22px;
      right: 17px;
      padding: 5px;
    }

    > .body {
      width: 100%;

      > .warning {
        margin: 10px 0 0;
        background: #fff4cd;
        border: 1px solid #feefb6;
        display: table;

        > p {
          width: 100%;
          margin: 0;
          padding: 8px 10px;
          font-size: 14px;
          color: #6e5c1a;
        }

        > .close-box {
          width: 15%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          font-weight: bold;
          font-size: 14px;
          cursor: pointer;
          color: #ab9750;
          border-left: 1px solid #f0e3ae;
          pointer-events: auto;
        }
      }
      
      > .shopping-cart-add-popup-item-wrap {
        width: 94%;
        margin: 15px auto 10px;
        display: block;
        
        > .title {
          margin-bottom: 10px;
          font-size: $standard-font-size;
          font-weight: bold;
          color: #000;
        }
        
        > .item {
          display: flex;
          justify-content: space-between;

          > figure {
            width: 80px;
            text-align: center;
          }
          
          > .info {
            width: calc(100% - 90px);
        
            > .expected-release-date {
              margin-bottom: 5px;
              font-size: 13px;
              font-style: italic;
              color: #ccc;
            }
        
            > .count-controls {
              margin-bottom: 5px;
              display: flex;
        
              input {
                width: 80px;
                height: 30px;
                padding: 0 5px;
                font-weight: normal;
                color: #000;
                font-size: 15px;
              }
        
              .add {
                margin-left: 5px;
                display: inline-block;
                
                > img {
                  width: 30px;
                  height: 30px;  /* For IE 11 */
                }
              }
        
              .remove {
                margin-left: 5px;
                display: inline-block;
                
                > img {
                  width: 25px;
                }
              }
            }
        
            .price {
              margin-bottom: 5px;
              font-size: 13px;
              color: #58595b;
              white-space: nowrap;
            }
        
            .total {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: bold;
              color: #000;
              white-space: nowrap;
            }
          }
        }
      }

      > p {
        width: 80%;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;

        > .add-to-lower {
          font-weight: bold;
          text-decoration: underline;
        }

        > .amount {
          color: #b12704;
          font-weight: bold;
        }
      }

      > .buttons {
        margin: 10px 0;
        text-align: center;
        display: flex;
        justify-content: space-between;

        > .btn-common {
          padding: 10px 14px;
          font-size: 13px;
        }

        > #btn-continue {
          background: #fff;
          color: $orange-mid;
          font-size: 13px;
        }
      }

      > .checkbox-one-line {
        .bullion-checkbox {
          margin-left: 0;
        }

        label {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
  
  > .popup-change-currency, > .popup-storage-location, > .popup-portfolio-location, > .popup-location {
    width: 280px;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      margin: 0;
      padding: 10px 0 5px 11px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    .btn-close-popup {
      position: absolute;
      top: 14px;
      right: 12px;
    }

    > .body {
      width: 100%;
      height: 95%;
      margin: 0 auto;
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;
    }
  }

  > .popup-review-us {
    position: relative;
    top: 10%;
    width: 80%;
    max-width: 1000px;
    height: auto;
    padding: 35px 10px 45px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);
    color: #333;

    @media (max-width: 768px) {
      width: 550px !important;
    }

    &.one {
      width: 55%;
      max-width: 650px;

      .type {
        width: 100% !important;
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          margin: 0;
        }

        > span {
          width: calc(100% - 55px);
          text-align: left;
        }
      }
    }

    > .title {
      width: 95%;
      margin: 0 auto;
      padding: 10px;
      font-size: 20px;
      font-weight: normal;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 800px) {
        font-size: 18px;
        text-align: left;
      }

      > span {
        margin-left: 10px;

        > b {
          font-weight: 600;
        }
      }
    }

    .btn-close-popup {
      position: absolute;
      top: 14px;
      right: 20px;
    }

    > .body {
      width: 95%;
      margin: 0 auto;
      padding: 10px;

      h4 {
        margin: 0 0 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;

        > img {
          margin: 3px 10px 0 0;
        }
      }

      ul {
        > li {
          margin-bottom: 10px;
          font-size: 12px;

          > span {
            width: 96%;
            margin-bottom: 10px;
          }

          > .num {
            width: 3%;
          }

          input {
            margin-right: 5px;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #ccc;
            width: 50%;
          }
        }
      }

      > .type {
        width: 49%;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 4px;

        &:first-child {
          margin-right: 2%;
        }
      }
    }

    > .note {
      width: 95%;
      margin: 10px auto 0;
      padding: 0 10px;
      font-size: 11px;
      text-align: center;
      font-style: italic;
    }
  }

  > .popup-notify-me {
    .buttons {
      text-align: center;

      .btn-common {
        width: 30%;
        text-align: center;
        font-size: 14px;
      }
    }
  }

  > .popup-picture-upload {
    position: relative;
    top: 10%;
    width: 600px;
    height: auto;
    margin: 0 auto;
    padding-bottom: 1px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > .title {
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 10px 20px;
      background: #f6f7f9;
      font-weight: bold;
      font-size: 13px;
      border-bottom: 1px solid #eee;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      > .btn-close-popup {
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }

    > .body {
      margin: 20px;
      padding: 20px;
      border: 5px dashed #ddd;
      border-radius: 5px;

      > p {
        text-align: center;
      }

      > .plus {
        margin: 20px 0;
      }

      > .large {
        font-size: 22px;
      }

      > .medium {
        font-size: 15px;
      }

      > .small {
        margin: 0;
        font-size: 10px;
        color: #ccc;
      }

      > .buttons {
        margin: 15px 0 20px;
        text-align: center;

        > button {
          width: 130px;
          height: 100px;
          background: $orange-mid;
          border: none;
          border-bottom: 2px solid #7c4313;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          text-align: center;

          > img {
            margin: 15px auto;
            display: block;
          }

          &:hover {
            background: $orange-light;
          }

          &:first-child {
            margin-right: 15px;
          }
        }
      }
    }
  }

  > .popup-take-photo {
    position: relative;
    top: 10%;
    width: 600px;
    height: auto;
    margin: 0 auto;
    padding-bottom: 1px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > .title {
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 10px 20px;
      background: #f6f7f9;
      font-weight: bold;
      font-size: 13px;
      border-bottom: 1px solid #eee;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      > .btn-close-popup {
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }

    > .body {
      margin: 20px;

      > .buttons {
        text-align: right;
      }
    }
  }

  > .popup-bid-history {
    position: relative;
    top: 10%;
    max-width: 600px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > .title {
      font-size: 20px;
      font-weight: bold;
    }
    
    .btn-close-popup {
      position: absolute;
      top: 22px;
      right: 17px;
      padding: 5px;
    }

    > .body {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px 0;
      display: flex;
      border-bottom: 1px solid #ccc;

      > figure {
        margin-right: 10px;
      }

      > .text {
        width: 100%;

        > .name {
          font-weight: bold;
          font-size: $standard-font-size;
        }

        > p {
          margin: 3px 0;
        }
      }
    }

    > .table-wrap {
      max-height: 200px;
      height: auto;
      margin-bottom: 20px;
      overflow-y: auto;

      > table {
        width: 100%;
        padding: 10px 0;
  
        th, td {
          padding: 5px;
        }
  
        td {
          background: #f9f9f9;
          border-bottom: 3px solid #fff;
        }
      }

    }

    > .bottom {
      text-align: center;
    }
  }

  > .popup-confirm-bid {
    position: relative;
    top: 10%;
    max-width: 550px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);
    
    .btn-close-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
    }

    > .product-info {
      width: 200px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid #ccc;

      > .time-left {
        margin: 0 0 5px;
        font-size: $standard-font-size;
        font-weight: bold;
        color: $font-black;

        &.lessThan24hrs {
          color: $orange-light;
        }
        &.lessThan15min {
          color: $font-red-dark;
        }

        > .time-label.narrow {
          display: none;
        }
      }
    
      > .ends {
        margin: 0 0 5px;
        text-align: center;
        line-height: 1;
        color: #8D8F92;
        font-size: 12px;
      }

      > header {
        > .name {
          margin: 15px 0;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #231F20;
        }
      }

      > .price-text {
        margin: 0 0 2px;
        color: #333;
      }

      > .price {
        height: 30px;
        margin: 0 0 5px;
        color: $orange-mid;
        font-size: 18px;
        font-weight: bold;
      }

      &.my-leading {
        > .price-text {
          color: #0c9358;
        }
      }

      &.change-leading:not(.my-leading) {
        > .price-text, > .price {
          color: $font-red;
        }
      }
    }

    > .bid-info {
      width: calc(100% - 200px);
      padding: 0 20px;

      > .title {
        font-size: $standard-font-size;
        text-align: center;
        font-weight: bold;
        color: $font-black;
      }

      > .btn-confirm-place-bid {
        margin: 0 0 5px;
        width: 100%;
        font-size: $standard-font-size;
      }

      > .btn-confirm-edit-bid {
        margin: 0 0 5px;
        width: 100%;
        font-size: $standard-font-size;
      }

      > .tos {
        font-size: 11px;
        color: #8D8F92;

        &.instruction {
          color: $font-black;
        }
      }

      > .or {
        position: relative;
        margin: 20px 0;
        text-align: center;
        border-bottom: 1px solid #ccc;

        > span {
          width: 40px;
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          margin: 0 auto;
          padding: 10px;
          background: #fff;
          color: #8D8F92;
        }
      }

      > .input {
        margin: 0 0 5px;

        > input {
          width: 100%;
          padding: 5px;
        }
      }

      > .btn-auto-bid {
        width: 100%;
        margin: 0 0 5px;
        font-size: $standard-font-size;
      }
    }
  }

  > .popup-edit-auto-bid {
    position: relative;
    top: 10%;
    max-width: 550px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);
    
    .btn-close-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
    }

    > .product-info {
      width: 200px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid #ccc;

      > .time-left {
        margin: 0 0 5px;
        font-size: $standard-font-size;
        font-weight: bold;
        color: $font-black;

        &.lessThan24hrs {
          color: $orange-light;
        }
        &.lessThan15min {
          color: $font-red-dark;
        }

        > time-label.narrow {
          display: none;
        }
      }
    
      > .ends {
        margin: 0 0 5px;
        text-align: center;
        line-height: 1;
        color: #8D8F92;
        font-size: 12px;
      }

      > header {
        > .name {
          margin: 15px 0;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #231F20;
        }
      }

      > .price-text {
        margin: 0 0 2px;
        color: #333;
      }

      > .price {
        height: 30px;
        margin: 0 0 5px;
        color: $orange-mid;
        font-size: 18px;
        font-weight: bold;
      }

      &.my-leading {
        > .price-text {
          color: #0c9358;
        }
      }

      &.change-leading:not(.my-leading) {
        > .price-text, > .price {
          color: $font-red;
        }
      }
    }

    > .bid-info {
      width: calc(100% - 200px);
      padding: 0 20px;

      > .title {
        font-size: $standard-font-size;
        font-weight: bold;
        color: $font-black;
        text-align: center;
      }

      > .btn-place-bid {
        margin: 0 0 5px;
        width: 100%;
        font-size: $standard-font-size;
      }

      > .max-text {
        margin: 0;
        font-size: 14px;
        color: $font-black;
      }

      > .max {
        margin: 0 0 10px;
        font-size: 14px;
      }

      > .input {
        margin: 0 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > span {
          font-size: 14px;
          color: $font-black;

          &.bid-label {
            flex-basis: 55%;
            padding-right: 5px;
          }
          
        }

        > .bid-price {
          position: relative;
          border: 1px solid #cccc;
          border-radius: 4px;
          padding: 4px 5px 5px 10px;

          > span.currency {
            position: relative;
            opacity: 0.5;
          }

          > input {
            width: 50%;
            padding: 5px;
            margin-left: 10px;
            border: 0;
          }
        }
      
        > input {
          width: 100%;
          padding: 5px;
        }

        &.horizontal {
          > .bid-price {
            flex: 1;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: 0;

            > input {
              width: 60%;
            }
          }

          > button {
            flex: 1;
            padding: 10px 20px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      > .tos {
        font-size: 11px;
        color: #8d8f92;

        &.instruction {
          color: $font-black;
        }
      }

      > .btn-auto-bid {
        width: 100%;
        margin: 0 0 5px;
        font-size: $standard-font-size;
      }

      > .or {
        position: relative;
        margin: 20px 0;
        text-align: center;
        border-bottom: 1px solid #ccc;

        > span {
          width: 40px;
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          margin: 0 auto;
          padding: 10px;
          background: #fff;
          color: #8D8F92;
        }
      }
    }
  }

  > .popup-edit-bid {
    position: relative;
    top: 10%;
    max-width: 550px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);
    
    .btn-close-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
    }

    > .product-info {
      width: 200px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid #ccc;

      > .time-left {
        margin: 0 0 5px;
        font-size: $standard-font-size;
        font-weight: bold;
        color: $font-black;

        &.lessThan24hrs {
          color: $orange-light;
        }
        &.lessThan15min {
          color: $font-red-dark;
        }

        > .time-label.narrow {
          display: none;
        }
      }
    
      > .ends {
        margin: 0 0 5px;
        text-align: center;
        line-height: 1;
        color: #8D8F92;
        font-size: 12px;
      }

      > header {
        > .name {
          margin: 15px 0;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #231F20;
        }
      }

      > .price-text {
        margin: 0 0 2px;
        color: #333;
      }

      > .price {
        height: 30px;
        margin: 0 0 5px;
        color: $orange-mid;
        font-size: 18px;
        font-weight: bold;
      }

      &.my-leading {
        > .price-text {
          color: #0c9358;
        }
      }

      &.change-leading:not(.my-leading) {
        > .price-text, > .price {
          color: $font-red;
        }
      }
    }

    > .bid-info {
      width: calc(100% - 200px);
      padding: 0 20px;

      > .title {
        font-size: $standard-font-size;
        font-weight: bold;
        color: $font-black;
        text-align: center;

        &.secondary {
          font-size: 16px;
          font-weight: bold;
        }
      }

      > .btn-place-bid {
        margin: 0 0 5px;
        width: 100%;
        font-size: $standard-font-size;
      }

      > .btn-confirm-edit-bid {
        margin: 0 0 5px;
        width: 100%;
        font-size: $standard-font-size;
      }

      > .max-text {
        margin: 0 0 10px;
        font-size: 14px;
        color: $font-black;
      }

      > .input {
        margin: 0 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > span {
          font-size: 14px;
          color: $font-black;

          &.bid-label {
            flex-basis: 40%;
          }
          
        }

        > .bid-price {
          position: relative;
          border: 1px solid #cccc;
          border-radius: 4px;
          padding: 4px 5px 5px 10px;

          > span.currency {
            position: relative;
            opacity: 0.5;
          }

          > input.bid {
            width: 50%;
            padding: 5px;
            margin-left: 10px;
            border: 0;
          }
        }
      
        > input {
          width: 100%;
          padding: 5px;
        }
      }

      > .tos {
        font-size: 11px;
        color: #8d8f92;

        &.instruction {
          color: $font-black;
        }
      }

      > .or {
        position: relative;
        margin: 20px 0;
        text-align: center;
        border-bottom: 1px solid #ccc;

        > span {
          width: 40px;
          position: absolute;
          top: -20px;
          left: 0;
          right: 0;
          margin: 0 auto;
          padding: 10px;
          background: #fff;
          color: #8D8F92;
        }
      }

      > .btn-auto-bid {
        width: 100%;
        margin: 0 0 5px;
        font-size: $standard-font-size;
      }
    }
  }
  
  > .popup-saved-watchlist {
    position: relative;
    top: 10%;
    max-width: 550px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);
    
    .btn-close-popup {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
    }

    > .product-info {
      width: 200px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid #ccc;

      > .time-left {
        margin: 0 0 5px;
        font-size: $standard-font-size;
        font-weight: bold;
        color: $font-black;

        &.lessThan24hrs {
          color: $orange-light;
        }
        &.lessThan15min {
          color: $font-red-dark;
        }

        > .time-label.narrow {
          display: none;
        }
      }
    
      > .ends {
        margin: 0 0 5px;
        text-align: center;
        line-height: 1;
        color: #8D8F92;
        font-size: 12px;
      }

      > header {
        > .name {
          margin: 15px 0;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #231F20;
        }
      }

      > .price-text {
        margin: 0 0 2px;
        color: #333;
      }

      > .price {
        height: 30px;
        margin: 0 0 5px;
        color: $orange-mid;
        font-size: 18px;
        font-weight: bold;
      }

      &.my-leading {
        > .price-text {
          color: #0c9358;
        }
      }

      &.change-leading:not(.my-leading) {
        > .price-text, > .price {
          color: $font-red;
        }
      }
    }

    > .bid-info {
      width: calc(100% - 200px);
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > .top {
        margin-bottom: 20px;

        > .title {
          font-size: $standard-font-size;
          font-weight: bold;
          color: $font-black;
        }
  
        > .buttons {
          display: flex;
          justify-content: space-between;
  
          > button, a {
            width: 49%;
            padding: 5px;
            text-align: center;
          }
        }
      }

      > .checkbox-one-line {
        width: 100%;
      }

    }
  }

  > .popup-captcha {
    position: relative;
    top: 10%;
    max-width: 380px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      padding: 5px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    > .body {
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;

      > .message {
        font-size: 12px;
        margin-bottom: 20px;

        a {
          font-size: 12px;
        }
      }

      .captcha-image {
        border: 1px solid #e6e6e6;
      }

      > .field {
        margin: 10px auto;
        width: 220px;

        > input {
          width: 100%;
          padding: 5px;
        }
      }

      > .buttons {
        > button, > a {
          margin: 0 7px;
          padding: 3px 30px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  > .popup-hcaptcha {
    position: relative;
    top: 10%;
    max-width: 380px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      padding: 5px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    > .body {
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;

      > .captcha-widget {
        display: flex;
        justify-content: center;
        margin: 10px 0;
      }

      > .message {
        font-size: 12px;
        margin-bottom: 15px;

        a {
          font-size: 12px;
        }
      }

      .captcha-image {
        border: 1px solid #e6e6e6;
      }

      > .field {
        margin: 10px auto;
        width: 220px;

        > input {
          width: 100%;
          padding: 5px;
        }
      }

      > .buttons {
        > button, > a {
          margin: 0 7px;
          padding: 3px 30px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  > .popup-api-keys {
    position: relative;
    top: 10%;
    max-width: 380px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      padding: 5px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    > .body {
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;

      > .message {
        font-size: 12px;
        margin-bottom: 5px;

        a {
          font-size: 12px;
        }
      }

      > .api-key-text {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        > #api-key {
          background: transparent;
          border: none;
          border-bottom: 1px solid #e3d3c3;
          border-radius: 0;
          box-shadow: none;
          outline: none;
          padding: 12px 10px 6px 0;
          font-size: 18px;
          flex: 1;
        }

        > #copy-api-key {
          font-size: 12px;
          font-weight: 400;
          border-width: 1px;
        }
      }

      > .buttons {
        > button, > a {
          margin: 0 7px;
          padding: 3px 30px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  > .popup-generate-api-key {
    position: relative;
    top: 10%;
    max-width: 380px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > h3 {
      color: #333;
      font-size: 24px;
      font-weight: 400;
      margin: 0;
      padding: 10px 10px 0;
    }

    > .form-wrap {
      padding: 0 10px 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;

      > h4 {
        font-size: 16px;
        margin: 15px 0 5px;
        font-weight: 600;
      }

      .radio-one-line input[type=radio]+label {
        font-weight: 400;
      }

      > textarea {
        border: 1px solid #ccc;
        font-size: 14px;
        margin-top: 14px;
        padding: 5px;
        resize: none;
        width: 100%;
        margin: 0;
      }

      > .field-description {
        font-size: 14px;
      }

      > .buttons {
        margin-top: 20px;

        > button {
          font-size: 12px;
          font-weight: 400;
          margin: 0 7px;
          padding: 3px 30px;
        }
      }
    }
  }
}

.backdrop2 {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 30%);
  z-index: 20;

  > .popup.wide {
    max-width: 480px;
  }

  > .popup {
    position: relative;
    top: 10%;
    max-width: 380px;
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: -6px 6px 5px 0 rgba(70, 71, 73, 50%);

    > .title {
      padding: 5px 10px;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid $orange-light;
    }

    > .body {
      padding: 10px;
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 );
      border-radius: 5px;

      > .message {
        font-size: 12px;
        margin-bottom: 20px;
        overflow-y: auto;
        // overflow-wrap: break-word;

        a {
          font-size: 12px;
        }
      }

      > .buttons {
        > button, > a {
          margin: 0 7px;
          padding: 3px 30px;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }

  > .loading {
    position: absolute;
    top: 50%;
    left: 50%;

    > .bullion-loader {
      position: relative;
      font-size: 10px;
      text-indent: -9999em;
      border-top: 1.1em solid $orange-mid;
      border-right: 1.1em solid $orange-mid;
      border-bottom: 1.1em solid $orange-mid;
      border-left: 1.1em solid rgba(192, 104, 1, 50%);
      transform: translateZ(0);
      animation: load 1.1s infinite linear;
    }

    .bullion-loader,
    .bullion-loader::after {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      margin: -50px 0 0 -50px;
    }
  }
}

.backdrop3 {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 60%);
  z-index: 21;

  > .popup-photos-original-size {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-close-popup {
      position: absolute;
      top: 6px;
      right: 15px;
      z-index: 22;
    }

    > .body {
      position: relative;
      width: 90%;
      height: 90%;
      margin: 5% auto;
      overflow-y: scroll;
      z-index: 22;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }

      > .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        > .bullion-loader {
          position: relative;
          font-size: 10px;
          text-indent: -9999em;
          border-top: 1.1em solid $orange-mid;
          border-right: 1.1em solid $orange-mid;
          border-bottom: 1.1em solid $orange-mid;
          border-left: 1.1em solid rgba(192, 104, 1, 50%);
          transform: translateZ(0);
          animation: load 1.1s infinite linear;
        }

        .bullion-loader,
        .bullion-loader::after {
          border-radius: 50%;
          width: 100px;
          height: 100px;
          margin: -50px 0 0 -50px;
        }
      }
    }

    
  }
}

/* .backdrop-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 19;

  > .popup-cart-container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    > .popup-cart {
      position: absolute;
      top: 70px;
      right: 7px;
      width: 320px;
      max-height: 60vh;
      padding: 10px 0;
      display: block;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      z-index: 10;
      cursor: auto;

      @include breakpoint(1200px) {
        right: -3px;
      }
      
      > .arrow {
        position: absolute;
        top: 0;
        right: 17px;
        background: #88b7d5;
      }

      > .arrow:after, > .arrow:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      > .arrow:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }

      > .arrow:before {
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: #ccc;
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }
} */

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
