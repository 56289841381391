.wholepage {
  min-height: 800px;
  padding-bottom: 50px;
  background: #fff;

  > h1 {
    width: 86%;
    margin: 15px auto 0;
    padding: 15px 0;
    text-align: left;
    font-size: $standard-font-size-heading;
    line-height: 32px;

    @include breakpoint-max(1000px) {
      width: 98%;
    }
  }

  > p {
    width: 86%;
    margin: 0 auto 20px;
    font-size: 14px;

    @include breakpoint-max(1000px) {
      width: 98%;
    }
  }

  > .generic-480-h1 {
    width: 520px;
  }

  > .set-alerts-h1 {
    width: 560px;
  }
}
