.new-zealand-wrap {
  // padding-right: 20px;

  >.banner {
    position: relative;

    >.text {
      position: absolute;
      top: 0;
      left: 0;
    }

    >img {
      width: 100%;
    }
  }

  >.new-zealand-wrap-body {
    @include breakpoint-max(1200px) {
      padding: 0 20px 20px;
    }

    >h2 {
      margin: 30px 0;
      font-size: $standard-font-size-heading;
    }

    >.nz-list-wrap {
      display: flex;
      flex-wrap: wrap;

      @include breakpoint-max(1000px) {
        display: block;
      }

      >.item {
        width: 48%;
        margin-bottom: 20px;

        @include breakpoint-max(1000px) {
          width: 100%;
        }

        >figure {
          float: left;
          margin-right: 15px;
          text-align: center;
        }

        >.text {
          float: left;
          width: calc(100% - 125px);
          font-size: $standard-font-size;

          >h3 {
            margin-top: 0;
            font-size: 20px;
            line-height: 26px;
          }

          >p {
            font-size: $standard-font-size;
            line-height: 24px;
          }
        }
      }
    }

    >.new-zealand {
      >figure {
        margin: 20px 0;

        >img {
          width: 100%;
        }
      }

      >h2 {
        margin: 20px 0;
        font-size: $standard-font-size-heading;
      }

      >p {
        margin: 0 0 10px;
        font-size: $standard-font-size;
        line-height: 24px;
      }

      >.question {
        margin: 20px 0;
        font-weight: bold;
        line-height: 24px;
      }
    }

    >.map {
      margin-top: 20px;
      display: flex;
      border-radius: 5px;
      border: 1px solid #f1f1f1;
      box-shadow: 0 1px 9px 0 #d0d0d0;

      @include breakpoint-max(1000px) {
        display: block;
      }

      >.google-map-us {
        padding: 15px 25px;
        width: 350px;

        @include breakpoint-max(1000px) {
          width: 100%;
          display: flex;
        }

        >.map-link {
          margin-bottom: 15px;
          display: flex;
          align-items: flex-start;

          >img {
            margin-right: 10px;
          }

          >span {
            margin: 0;
            display: inline-block;
            font-weight: bold;
            color: #000;
            font-size: 14px;

            @include breakpoint-max(1200px) {
              padding-right: 5px;
              width: 150px;
            }

          }
        }

        >.open {
          >p {
            margin: 0;
            font-size: 14px;
          }

          >.opening {
            margin: 0;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      >figure {
        max-height: 270px;
        overflow: hidden;

        >a {
          display: block;

          >img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}