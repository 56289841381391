.bs-tooltip {
  position: relative;
  height: auto;
  padding-bottom: 5px;
  display: inline-block;

  > img {
    display: inline-block;
    vertical-align: top;
  }

  > .body {
    display: none;

    > .arrow {
      position: absolute;
      top: 0;
      left: 16px;
      background: #88b7d5;
    }

    > .arrow::after, > .arrow::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    > .arrow::after {
      border-color: rgb(255 255 255 / 0%);
      border-bottom-color: #fff;
      border-width: 8px;
      margin-left: -8px;
      top: -14px;
    }

    > .arrow::before {
      border-color: rgb(204 204 204 / 0%);
      border-bottom-color: #ccc;
      border-width: 8px;
      margin-left: -8px;
    }
  }

  &:hover {
    > .body {
      position: absolute;
      top: 20px;
      left: -12px;
      width: 230px;
      display: block;
      background: #fff;
      padding: 10px;
      border: 2px solid #ccc;
      border-radius: 5px;
      font-size: 12px;
      z-index: 5;

      &.small {
        width: 170px;
      }

      &.wide {
        width: 400px;
      }

      &.auto {
        width: auto;
        white-space: nowrap;
      }

      > .text {
        display: inline-block;
      }
    }
  }
}

.bs-tooltip-2 {
  position: relative;
  height: auto;
  padding: 6px 0;
  display: inline-block;

  > img {
    display: inline-block;
    vertical-align: top;
  }

  > .body {
    display: none;
    

    > .arrow {
      position: absolute;
      top: 0;
      left: 87px;
      background: #88b7d5;
    }

    > .arrow::after, > .arrow::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    > .arrow::after {
      border-bottom-color: #fff;
      border-width: 8px;
      margin-left: -8px;
      top: -15px;
    }

    > .arrow::before {
      border-bottom-color: #ccc;
      border-width: 8px;
      margin-left: -8px;
    }
  }

  &:hover {
    > .body {
      position: absolute;
      top: 28px;
      left: -82px;
      width: 260px;
      display: block;
      background: #fff;
      padding: 15px;
      border: 1px solid #ccc;
      font-size: 12px;
      line-height: 1.4;
      z-index: 5;
      box-shadow: 1px 5px 28px -5px rgb(0 0 0 / 75%);

      &.small {
        width: 170px;
      }

      &.wide {
        width: 400px;
      }

      &.auto {
        width: auto;
        white-space: nowrap;
      }

      > .text {
        display: inline-block;
      }
    }
  }
}
