.header-announcement {
  width: 100%;
  padding: 10px 0;
  background: #fef8cb;

  > .body {
    position: relative;
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    // @include breakpoint-max(1220px) {
    //   padding: 0 5px;
    // }

    > p {
      margin: 0;
      padding-right: 40px;
      font-size: 14px;
      display: flex;

      > img {
        margin-right: 8px;
        vertical-align: -4px;
        flex: 0 0 20px;
      }

      > a {
        text-decoration: underline;
      }
    }

    > #close-announcement {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;

      @include breakpoint-max(1200px) {
        right: 10px;
      }
    }
  }

}