.btn-common {
  width: auto;
  padding: 5px 20px;
  display: inline-block;
  background: $orange-light;
  border: 1px solid $orange-light;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  @include no-select();

  &.tall {
    padding: 12px 20px !important;
  }

  &:hover, &:active, &:focus, &:visited {
    color: #fff;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    background: #ca6f20;
  }

  &.default {
    height: 26px;
    line-height: 26px;
    padding: 0 20px;
    background: #f0f1f1;
    border: 1px solid #c7c8cb;
    font-size: 12px;
    color: #231f20;

    &:hover, &:active, &:focus, &:visited {
      color: #231f20;
      text-decoration: none;
      outline: none;
    }
  }
}

.btn-common-radius {
  width: auto;
  padding: 5px 20px;
  display: inline-block;
  background: $orange-light;
  border-radius: 4px;
  border: 1px solid $orange-light;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  @include no-select();

  &:hover, &:active, &:focus, &:visited {
    color: #fff;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    background: #ca6f20;
  }

  &.small-font {
    height: 29px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 11px;
  }
}

.btn-common-hollow {
  padding: 4px 20px;
  display: inline-block;
  color: $orange-light;
  text-align: center;
  border: 2px solid $orange-light;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;

  &:hover, &:active, &:focus, &:visited {
    color: $orange-light;
    text-decoration: none;
    outline: none;
  }

  &:hover {
    color: $orange-mid;
    border: 2px solid $orange-mid;
  }

  &.small-font {
    height: 29px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 11px;
  }

}

.btn-common-sidebar {
  width: 100%;
  padding: 5px;
  display: block;
  text-align: center;
  color: $orange-mid;
  border: 2px solid $orange-mid;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;

  &:active, &:focus, &:visited {
    color: $orange-mid;
    border-color: $orange-mid;
  }

  &:hover {
    color: $orange-light;
    border-color: $orange-light;
  }
}
