.btn-contact-us-wrap-buttons {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 30px;

  a,
  div {
    display: block;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .text {
    display: block;
    font-size: 15px;
    font-weight: bold;
    color: #000;
    padding-top: 8px;
    width: 250px;
  }

  .number {
    display: flex;
    font-size: 14px;
    color: #000;
    flex: wrap;
  }

  img {
    display: block;
    float: left;
    margin-right: 10px;

    @include breakpoint-max(800px) {
      margin-right: 5px;
    }
  }

  .btn-contact-call {
    height: 80px;
    margin-bottom: 10px;

    >img {
      width: 43px;
      height: 64px;
    }

    .phone-numbers {
      width: 150px;

      @include breakpoint-max(800px) {
        width: 130px;
      }

      .numbers-img {
        width: 24px;
        height: 24px;
        margin-top: 5px;
      }

      .number-cell {
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;

        .number-sg {
          padding: 4px 0 1px;
        }
      }

      .number-note {
        font-size: 12px;
      }

      .number-note-sg {
        margin-left: 35px;
      }
    }

    .phone-numbers-us {
      width: 140px;

      @include breakpoint-max(800px) {
        width: 120px;
      }

      .numbers-img {
        width: 24px;
        height: 24px;
        margin-top: 5px;
      }

      .number-cell {
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;
      }

      .number-note {
        font-size: 12px;
      }
    }
  }

  .email-text {
    display: flex;
    flex: wrap;

    .contact-email-text {
      width: 210px;

      @include breakpoint-max(800px) {
        width: 190px;
      }

      div {
        .email-img {
          width: 24px;
          height: 24px;
          margin-top: 5px;
        }

        .text-img {
          width: 24px;
          height: 24px;
          margin-top: 5px;
          margin-right: 5px;
        }

        .email-text-cell {
          font-size: 14px;
          line-height: 14px;
          padding-top: 4px;

          .email-text-content {
            display: flex;
            font-size: 14px;
            color: #000;
            flex: wrap;
            padding-top: 5px;
          }
        }
      }
    }
  }
}