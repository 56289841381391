.sidebar {
  position: relative;
  width: 26%;
  // margin-right: 5px;
  padding-bottom: 20px;
  float: right;
  overflow: hidden;
  user-select: none;

  > *:nth-child(1) {
    // margin-top: 10px !important;

    @include breakpoint-max(988px) {
      margin-top: 5px !important;
    }
  }

  @include breakpoint-max(988px) {
    width: calc(30% - 5px);
  }
    
  @include breakpoint-max(820px) {
    width: calc(31% - 5px);
  }

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  h2 {
    margin: 0;
    padding: 0 0 15px;
    font-size: 15px;
  }

  > .best-price {
    position: relative;
    width: 100%;
    margin-top: 10px;
    display: block;

    @include clickable-image-hover-effect();

    // & + div {
    //   margin-top: 10px !important;
    // }

    > img {
      width: 100%;
      height: auto;
    }

    > .text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .promo-wrapper {
    position: relative;
    padding: 0;
    margin: 20px 0 0;

    &.multiple {
      .product-promo-wrap {
        justify-content: flex-start;
      }
    }

    .promo-nav {
      position: absolute;
      bottom: 0;
      right: 50%;
      left: 50%;
      margin: 10px -50% 0;
      text-align: center;
  
      > li {
        display: inline-block;
        padding: 10px;
  
        &.active {
          pointer-events: none;
  
          > span {
            background: #dc8500;
          }
        }
  
        > span {
          width: 15px;
          height: 15px;
          display: inline-block;
          border: 1px solid #dc8500;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }

    .product-promo-wrap {
      padding: 15px 10px;
      background: #fff;
      border: 1px solid #ebebeb;
      display: flex;
      flex-direction: column;
      
      &.hidden-item {
        display: none;
      }
  
      &.in-blogs {
        margin: 0 0 20px;
      }
  
      p {
        margin: 0;
      }
  
      > header {
        margin: 0 0 5px;
        cursor: pointer;
  
        > .text {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          
          > img {
            margin-right: 10px;
          }
  
          p {
            > .name {
              text-transform: uppercase;
              font-size: 17px;
              line-height: 1.3;
              font-weight: 700;
              color: $orange-light;
  
              @include breakpoint-max(1175px) {
                font-size: 16px;
              }
  
              @include breakpoint-max(1084px) {
                font-size: 15px;
              }
  
              > img {
                width: 40px;
                height: 42px;
                vertical-align: -24px;
                margin-right: 7px;
        
                @include breakpoint-max(1175px) {
                  margin-right: 5px;
                  vertical-align: -22px;
                }
        
                @include breakpoint-max(870px) {
                  width: 30px;
                  height: 37px;
                }
              }
            }
  
            > .time {
              font-size: 15px;
              font-weight: 700;
              line-height: 1.3;
              color: $orange-light;
        
              @include breakpoint-max(1175px) {
                font-size: 14px;
              }
            }
          }
        }
      }
  
      > .info {
        cursor: pointer;
  
        > .name {
          margin: 5px 0;
          text-align: center;
  
          > a {
            font-weight: bold;
            font-size: 16px;
            color: #000;
            line-height: 1.2;
      
            @include breakpoint-max(1175px) {
              font-size: 14px;
            }
  
            &:hover {
              color: inherit;
            }
          }
        }
  
        > .particulars {
          display: flex;
          margin-top: 10px;
  
          .image-holder {
            .image {
              width: 125px;
  
              @include breakpoint-max(1175px) {
                width: 100px;
              }
              
              @include breakpoint-max(820px) {
                width: 98px;
              }
  
              img {
                width: 100%;
                height: auto;
                // min-width: 105px;
                // @include breakpoint-max(1175px) {
                //   min-width: 105px;
                // }
              }
            }
          }
  
          > .details {
            padding-left: 10px;
            flex: 1;
            overflow: hidden;
  
            > .save {
              font-size: 11px;
              line-height: 1.3;
            }
  
            > .price-new {
              margin-top: 10px;
  
              > .price-label {
                span {
                  display: block;
                  color: #333;
                  text-align: left;
                  font-size: 9px;
                  line-height: 1;
                  font-weight: bold;
  
                  &.currency {
                    font-weight: bold;
                    color: $font-red;
                  }
                }
              }
  
              > .value {
                font-size: 19px;
                color: $font-red;
                font-weight: bold;
                line-height: 1;
  
                @include breakpoint-max(1200px) {
                  font-size: 18px;
                }
                @include breakpoint-max(1100px) {
                  font-size: 15px;
                }
                @include breakpoint-max(1024px) {
                  font-size: 14px;
                }
                @include breakpoint-max(900px) {
                  font-size: 11px;
                }
              }
            }
  
            > .price-old {
              margin-top: 5px;
              font-size: 10px;
  
              @include breakpoint-max(1175px) {
                font-size: 9px;
              }
  
              span {
                text-decoration: line-through;
                font-size: 11px;
  
                @include breakpoint-max(1175px) {
                  font-size: 9px;
                }
              }
            }
  
            > .order-field {
              margin-top: 10px;
  
              > .controls {
                width: 100%;
                text-align: center;
                font-size: 0;
                display: flex;
        
                > .quantity {
                  width: 50px;
                  height: 32px;
                  padding: 5px;
                  display: inline-block;
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                  border: 1px solid #ccc;
                  border-right: 0;
                  font-size: 12px;
  
                  @include breakpoint-max(930px) {
                    width: 35px;
                  }
                }
        
                > .btn-add-to-cart {
                  height: 32px;
                  padding: 9px;
                  font-size: 11px;
                  border-radius: 0;
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border: 0;
                  vertical-align: top;
                  flex: 1;
                  @include breakpoint-max(1026px) {
                    font-size: 10px;
                    padding: 9px 3px;
                  }
                }
              }
        
              > .notification-wrap {
                width: 100%;
                margin: 0 auto;
                text-align: center;
        
                > .btn-email-notif {
                  width: 100%;
                  font-size: 11px;
                  line-height: 1.2;
                  @include breakpoint-max(1200px) {
                    font-size: 10px;
                    line-height: 1.1;
                    padding: 5px 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  > .spot-price {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border: 1px solid #ebebeb;
    position: relative;

    > ul {
      > li {
        width: 33.33%;
        cursor: pointer;
      }
    }
    
    .label {
      padding: 0;
      text-align: left;
      font-size: 11px;
      font-weight: bold;
      color: #000;

      @include breakpoint-max(1092px) {
        font-size: 10px;
      }
    }

    .value {
      margin: 0;
      padding: 0;
      font-size: 10px;
      color: #737373;

      @include breakpoint-max(1092px) {
        font-size: 9px;
      }
    }

    > .selection {
      margin: 10px 0;

      > .selectbox {
        width: 47%;

        select {
          width: 100%;
          border-radius: 5px;
          padding: 7px 5px;
          font-weight: bold;
          outline: none;
          background: #fff;

          @include breakpoint-max(1133px) {
            font-size: 12px;
            padding: 3px 2px;
          }
        }

        &:first-child {
          margin-right: 5%;
        }
      }
    }

    .chart-options {
      display: flex;
      place-content: center center;

      .chart-option {
        width: 23%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 2px;
        padding: 8px 5px;
        text-align: center;
        font-size: 11px;
        color: #333;
        cursor: pointer;
        background: #e8e8e8;
        @include no-select;

        @include breakpoint-max(1133px) {
          margin-right: 1%;
        }

        @include breakpoint-max(1093px) {
          font-size: 9px;
        }

        &.active {
          color: #fff;
          background: #dd8705;
          pointer-events: none;
        }

        &.small {
          width: 20%;
        }

        &.wide {
          width: 35%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    > .chart-wrap {
      position: relative;
      width: 100%;
      // max-height: 215px;
      height: auto;
      z-index: 0;
      aspect-ratio: 280 / 230;
      margin-bottom: 2px;

      @include breakpoint-max(1133px) {
        min-height: 155px;
      }

      @include breakpoint-max(1046px) {
        min-height: 145px;
      }

      > .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -15px;
        z-index: 2;

        > .bullion-loader {
          position: relative;
          font-size: 10px;
          text-indent: -9999em;
          border-top: 0.7em solid $orange-mid;
          border-right: 0.7em solid $orange-mid;
          border-bottom: 0.7em solid $orange-mid;
          border-left: 0.7em solid rgba(192, 104, 1, 50%);
          transform: translateZ(0);
          animation: load 1.1s infinite linear;
        }

        .bullion-loader,
        .bullion-loader::after {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin: -25px 0 0 -25px;
        }
      }

      > .chart {
        position: relative;
        background: #fff;
        z-index: 3;
      }
    }

    > .btn-common-sidebar {
      margin-bottom: 5px;
    }

    > .skeleton-loader {
      display: none;
    }

    &.is-loading {
      > .skeleton-loader {
        display: block;
        margin-bottom: 4px;
        position: absolute;
        width: 100%;
        z-index: 3;
        left: 0;
        top: 0;
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
        // display: none;

        ~ * {
          visibility: hidden;
        }

        .sp-title {
          width: 50%;
          margin-bottom: 7px;
        }
        
        .sp-types {
          display: flex;
          margin-bottom: 10px;
          
          > .item {
            flex: 1;

            &:nth-child(2) {
              margin: 0 4px;
            }
          }
        }
        
        .sp-selections {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          > .item {
            width: calc(50% - 5px);
          }
        }
        
        .sp-chart {
          flex: 1;
          margin-bottom: 7px;
        }
        
        .sp-button {
          margin-bottom: 5px;
          height: 32px;
        }
      }
    }
  }

  > .news {
    padding: 15px;
    background: #fff;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    > .news-new {
      display: block;
      margin: 15px 0 0;

      > span {
        width: 60px;
        height: 50px;
        margin: 0 10px 10px 0;
      }

      > p {
        > .title {
          font-weight: bold;
          color: #000;

          @include breakpoint-max(1046px) {
            font-size: 11px;
          }
        }

        > .date {
          font-size: 10px;
          color: #676767;
        }
      }
    }

    > .news-list {
      min-height: 150px;

      > li {
        margin-bottom: 10px;

        > a {
          display: block;

          &:hover {
            > .title {
              text-decoration: underline;
            }
          }

          > .title {
            width: 100%;
            display: block;
            font-size: 12px;
            font-weight: bold;
            color: #000;
            line-height: 14px;
          }

          > .time {
            color: #676767;
            font-size: 11px;
          }
        }
      }
    }
  }

  > .latest-blog-posts {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border: 1px solid #ebebeb;
    
    > .subscribe {
      margin-bottom: 15px;

      > input {
        width: 70%;
        height: 30px;
        padding: 5px;
        font-size: 12px;
        border: 0;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        @include breakpoint-max(1133px) {
          font-size: 11px;
        }
      }

      > button {
        width: 30%;
        height: 30px;
        padding: 5px 10px;
        font-weight: normal;
        font-size: 12px;
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0;

        @include breakpoint-max(1133px) {
          height: 30px;
          padding: 5px;
          font-size: 11px;
        }
      }
    }

    > .blog-post-list {
      min-height: 239px;

      > li {
        margin-bottom: 10px;

        > a {
          display: flex;
          color: #000;

          &:hover {
            > span > .title {
              text-decoration: underline;
            }
          }

          > figure {
            width: 50px;
            height: 50px;
            margin-right: 5px;
            overflow: hidden;

            > img {
              width: 100%;
              height: auto;
            }
          }

          > span {
            width: 75%;

            > .title {
              width: 100%;
              display: block;
              font-size: 12px;
              font-weight: bold;
              color: #000;
              line-height: 14px;

              @include breakpoint-max(1046px) {
                font-size: 11px;
              }
            }

            > .time {
              color: #676767;
              font-size: 11px;

              @include breakpoint-max(1046px) {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    > .gold-news {
      margin-top: 20px;
    }
  }

  > .twitter {
    margin-top: 20px;
    width: 100%;
    height: 395px;
    border: 1px solid #ebebeb;
    background: #fff;

    > h2 {
      padding: 15px 15px 10px;
    }

    > .twitter-timeline {
      padding: 0 1px 0 5px;
    }

    &.twitter-widget {
      height: 450px;

      .twitter-timeline {
        height: 406px;
        overflow-y: scroll;
        padding: 0;
        .tweet {
          padding: 15px;
          cursor: pointer;
          overflow-wrap: break-word;

          &:hover {
            background: #f9f9f9;
          }

          .tweet-header {
            display: flex;

            > .tweet-header-left {
              margin-right: 8px;
              img {
                border-radius: 40px;
              }
            }

            .tweet-header-right {

              > .tweet-header-right-top {
                margin-bottom: 2px;

                .tweet-name {
                  color: #000;
                  font-size: 16px;
                  font-weight: bold;
                  line-height: 1;
                  vertical-align: bottom;
                }
              }
              > .tweet-header-right-bottom {
                .tweet-username {
                  font-size: 15px;
                  color: #737373;
                  line-height: 1;
                }
                
                .tweet-date {
                    color: #737373;
                    font-size: 15px;
                    line-height: 1;
                }
              }
            }
          }
          > .tweet-text {
            margin: 8px 0;
            font-size: 15px;
            line-height: 20px;
          }
          > .tweet-media {
            text-align: center;

            > .tweet-photo {
              > img {
                width: 100%;
                height: auto;
              }
            }

            &.multiple {
              display: flex;
              flex-wrap: wrap;
              gap: 5px;

              > .tweet-photo {
                width: calc(50% - 5px);

                > img {
                  margin-bottom: 0;
                }
              }
            }
            > .tweet-external {
              display: flex;
              justify-content: center;
              > img {
                width: auto;
                height: auto;
                max-width: 280px;
              }
            }
          }
          .reference-tweet {
            border: 1px solid #ebebeb;
            border-radius: 16px;
            padding: 8px 12px 12px;
            margin: 10px 0;

            // .reference-tweet-header {
            //   display: flex;
            //   flex-wrap: wrap;

            //   > span {
            //     padding: 0 5px 3px 0;
            //   }

            //   .tweet-name {
            //     color: #000;
            //     font-size: 16px;
            //     font-weight: bold;
            //     line-height: 1;
            //     vertical-align: bottom;
            //   }

            //   .tweet-username {
            //     font-size: 15px;
            //     color: #737373;
            //     line-height: 1;
            //   }
            // }
            .reference-tweet-text {
              font-size: 15px;
              line-height: 20px;
               margin-top: 5px;
            }
            .reference-tweet-media {
              width: 100%;
              margin-top: 8px;
              text-align: center;

              > .tweet-photo {
                > img {
                  margin-bottom: 10px;
                  width: 100%;
                  height: auto;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              > .tweet-external {
                display: flex;
                justify-content: center;
                > img {
                  width: auto;
                  height: auto;
                  max-width: 100%;
                }
              }

              &.multiple {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                > .tweet-photo {
                  width: calc(50% - 5px);

                  > img {
                    margin-bottom: 0;
                  }
                }
              }

              .tweet-video {
                margin-bottom: 0;
              }
            }
          }

          .tweet-video {
            margin-bottom: 10px;
            position: relative;
            
            > .tweet-thumbnail {
              width: 100%;
              height: auto;
              margin-bottom: 5px;
            }

            > .icon-play {    
              height: 60px;
              width: 60px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }

            > video {
              width: 100%;
              height: auto;
            }
          }

          .tweet-footer {
            margin-top: 10px;
            > a {
              line-height: 1;
              vertical-align: text-bottom;
              margin-right: 2px;

              > span {
                color: #737373;
                vertical-align: middle;
                font-size: 15px;
                margin-left: 1px;
              }
            }
          }
          
        }

        > .view-more {
          text-align: center;
          padding: 20px 15px;

          > a {
            margin: 0 auto;
          }
        }
      }
    }

    > .skeleton-loader {
      display: none;
    }

    &.is-loading {
      > .skeleton-loader {
        display: block;
        padding: 15px;

        ~ * {
          display: none;
        }

        .twt-title {
          width: 60%;
          margin-bottom: 15px;
        }
        
        .twt-header {
          display: flex;
          margin-bottom: 10px;
          
          > .twt-profile {
            width: 40px;
            height: 40px;
            border-radius: 40px;
          }
        
          > .twt-details {
              flex: 1;
              margin-left: 8px;
          }
        }
        > .twt-text {
          margin-top: 15px;
          height: 150px;
        }
        > .twt-photo {
          margin-top: 15px;
          height: 160px;
        }
      }
    }
  }

  > .twitter-author {
    margin-top: 20px;
    text-align: center;
  }

  > .subscribe-blog {
    padding: 10% 5%;
    @extend %blog-subscribe-bg;
    width: 100%;
    display: block;
    margin-top: 20px;

    > h2 {
      margin: 0 0 25px;
      font-size: 21px;
      text-align: center;
      color: #fff;
    }

    > .inputs {
      margin-bottom: 20px;
      border-radius: 5px;
      background: #fff;

      > img {
        width: 15px;
        margin: 15px 0 0 13px;
      }

      > input {
        width: 80%;
        padding: 10px 5px;
        display: block;
        color: #000;
        border: none;
      }
    }

    > button {
      width: 100%;
      padding: 10px 5px;
      display: block;
      font-weight: bold;
      background: #da8518;
    }
  }

  > .blog-post-filter {
    margin-top: 20px;

    > .filter-item {
      background: #fff;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      > figure {
        width: 30%;

        > a {
          width: 100%;
          display: block;

          > img {
            width: 100%;
          }
        }
      }

      > .desc {
        width: 70%;
        padding: 10px;
        display: block;

        > h3 {
          margin: 0;
          font-size: 18px;
          color: #000;
        }

        > span {
          font-size: 11px;
          color: #676767;
        }
      }
    }
  }

  > .no-spread {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    cursor: pointer;
    border: 1px solid #ebebeb;

    @include breakpoint-max(768px) {
      margin-top: 0;
    }

    > h2 {
      text-align: center;
      color: $orange-mid;
      font-size: 24px;
      font-family: AvenirLTStd-Heavy, sans-serif;
    }

    > figure {
      width: 100%;
      margin-bottom: 15px;
      display: flex;

      > .left {
        width: 54.5%;
      }

      > a {
        width: 45.5%;
        display: block;

        > img {
          width: 100%;
        }
      }
    }

    > ul {
      margin-bottom: 15px;

      > li {
        padding: 3px 0;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        > img {
          margin-top: 3px;
        }

        > .text {
          width: calc(100% - 20px);
          font-family: AvenirLTStd-Roman, sans-serif;
        }
      }
    }

    > .spread {
      width: 100%;
      padding: 15px;
      border-radius: 5px;
      text-align: center;

      > p {
        margin: 0;
        font-family: AvenirLTStd-Roman, sans-serif;
        font-size: 16px;
        color: #666;

        > span {
          display: block;
          font-size: 24px;
          font-family: AvenirLTStd-Roman, sans-serif;
          color: #333;
        }
      }

      > .middle {
        position: relative;
        margin: 5px 0 10px;
        padding: 20px 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        font-family: AvenirLTStd-Heavy, sans-serif;

        > .up {
          display: block;
          background: #d30600;
        }

        > .up::after, > .up::before {
          top: -10px;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        > .up::after {
          border-bottom-color: #d30600;
          border-width: 10px;
          margin-left: -10px;
        }

        > .down {
          display: block;
          background: #d30600;
        }

        > .down::after, > .down::before {
          bottom: -10px;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        > .down::after {
          border-top-color: #d30600;
          border-width: 10px;
          margin-left: -10px;
        }
      }
    }

    .red {
      color: #d30600;
    }

    > .learn-more {
      display: flex;
      justify-content: space-between;
      text-align: center;

      > a {
        width: 48%;
        padding: 5px 10px;
      }
    }
  }

  > .feature-products {
    margin-top: 20px;

    > .product-item {
      margin-bottom: 15px;
      padding: 10px 20px 20px 0;
      background: #fff;
    
      @include breakpoint-max(1090px) {
        padding: 10px 10px 20px;
      }

      > h3 {
        font-size: 14px;
        line-height: 18px;
      }

      > .body {
        > figure {
          width: 70px;
          height: 70px;
          margin-right: 10px;
    
          @include breakpoint-max(1130px) {
            width: 60px;
            height: 60px;
          }

          > img {
            width: 100%;
          }
        }

        > .order {
          > .as-low {
            font-size: 11px;
            color: #bbb;
          }

          > .price {
            margin-bottom: 5px;
            color: red;
            font-weight: bold;
          }

          > input {
            width: 30%;
            max-width: 60px;
            padding: 3px;
            margin-right: 3px;
            vertical-align: top;
            border-radius: 5px;
            border: 1px solid #ccc;
    
            @include breakpoint-max(1008px) {
              margin-right: 1px;
            }
          }

          > button {
            padding: 4px 7px;
            font-weight: normal;
            font-size: 12px;
    
            @include breakpoint-max(1008px) {
              padding: 4px 5px;
            }

            > img {
              width: 11px;
            }
          }
        }
      }
    }
  }

  > .latest-video {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border: 1px solid #ebebeb;

    > .body {
      min-height: 183px;
      margin-bottom: 10px;

      > figure {
        position: relative;
        width: 100%;
        max-height: 155px;
        overflow: hidden;
        margin-bottom: 5px;

        > a {
          width: 100%;
          height: 100%;
          display: block;

          @include clickable-image-hover-effect();

          > img {
            width: 100%;
            height: auto;
          }
  
          > .play {
            position: absolute;
            width: 50px;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
          }
        }
      }

      > .video-title {
        color: #333;
        font-size: 13px;
        line-height: 16px;
        display: block;

        &:hover {
          color: $orange-mid;
        }

        > span {
          font-weight: bold;
        }
      }
    }
  }

  // > div {

  //   @include breakpoint-max(940px) {
  //     padding: 15px 20px !important;
  //   }

  //   @include breakpoint-max(840px) {
  //     padding: 15px !important;
  //   }
  // }

  .product-launch-banner {
    position: relative;
    margin-top: 20px;
    padding: 0 !important;

    > a {
      display: block;
      height: 100%;
      width: 100%;

      @include clickable-image-hover-effect();

      img {
        width: 100%;

        &.text {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
