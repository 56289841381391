.search-page-wrap {

  @include breakpoint-max(1200px) {
    padding: 20px;
  }

  > .header {
    margin: 20px 0 0;

    > h1 {
      margin: 10px 5px 0 0;
    }

    > .bs-tooltip-2 {
      margin-right: 10px;

      > img {
        display: inline-block;
        vertical-align: -5px;
      }

      > .body {
        width: 350px;
      }
    }

    > a {
      float: right;
      margin-top: 10px;
      font-size: 12px;

      > img {
        margin-right: 5px;
        vertical-align: -5px;
      }
    }
  }

  > .search-result-nav {
    position: relative;
    margin-top: 20px;

    > ul {
      width: 100%;
      height: 37px;
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      border-bottom: 2px solid $orange-light;
      font-size: 0;
      white-space: nowrap;
      background: #f1f1f1;
      overflow: hidden;

      > li {
        width: auto;
        height: 37px;
        display: inline-block;
        text-align: center;
        padding: 2px 6px;
        overflow: hidden;

        &.active {
          background: $orange-light;
          font-weight: bold;

          > a {
            color: #fff;
          }
        }

        > a {
          float: left;
          width: 100%;
          height: 34px;
          padding: 0 10px;
          line-height: 34px;
          text-decoration: none;
          color: #171717;
          font-size: 13px;
        }
      }

      > .product {
        position: relative;
      }

      > .article {
        position: relative;
      }

      > .video {
        position: relative;
      }
    }
  }

  > .filtering {
    margin-top: 10px;
    text-align: right;

    > .checkbox-one-line {
      width: auto;

      > label {
        margin-bottom: 0;
        display: inline-block;
      }
    }
  }

  > .search-result {
    margin-top: 30px;

    > .search-result-product {
      display: flex;
      flex-wrap: wrap;

      > .product {
        width: 205px;
        margin: 0 11px 15px 0;
        height: auto;
        min-height: auto;
        padding: 10px;

        > .price.invisible {
          height: 0;
        }
        > .extra {
          min-height: 0;
        }
        > .sale {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    > .search-result-article, > .search-result-video {
      .entry {
        position: relative;
        width: 280px;
        height: 370px;
        float: left;
        margin-top: 2px;
        padding: 10px 15px;
        overflow: hidden;
        border-right: 2px dotted #ccc;

        @include breakpoint(1200px) {
          &:nth-child(3n+1) {
            padding-left: 0;
          }

          &:nth-child(3n) {
            width: 265px;
            border-right: none;
            padding-right: 0;
          }
        }
        
        @include breakpoint-max(1200px) {
          &:nth-child(2n+1) {
            padding-left: 0;
          }
  
          &:nth-child(2n) {
            width: 265px;
            border-right: none;
            padding-right: 0;
          }
        }
        
        @include breakpoint-max(840px) {
          & {
            width: 300px;
            border-right: none;
            padding-left: 0;
          }

          &:nth-child(2n+1) {
            padding: 10px 0 40px;
          }
  
          &:nth-child(2n) {
            width: 300px;
            border-right: none;
            padding: 10px 0 40px;
          }

          .read-more {
            width: 300px !important;
          }
        }

        > a, > div {
          position: relative;
          width: 100%;
          height: 130px;
          background: #f5f5f5;
          display: table;

          img:not(.play) {
            width: 100%;
            height: 130px;
            object-fit: cover;
          }

          .play {
            position: absolute;
            width: 50px;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
          }

          .no-image {
            height: 250px;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
          }
        }

        > div {
          background: #fff;
          cursor: pointer;
        }

        > .titles {
          width: 100%;
          font-size: 13px;
          overflow: hidden;

          a {
            min-height: 230px;
            display: block;

            > .title {
              margin: 12px 0 0;
              display: block;
              font-weight: bold;
              line-height: 15px;
            }

            > .date {
              margin: 2px 0;
              display: block;
              color: #676767;
             
              > img {
                width: 12px;
                height: 12px;
                object-fit: contain;
              }
            }

            > .desc {
              width: 100%;
              display: inline-block;
              word-wrap: break-word;
              color: #000;
            }

            > .read-more {
              position: absolute;
              bottom: 40px;
              left: 0;
              width: 250px;
              padding: 5px;
              background: $orange-mid;
              text-align: center;
              color: #fff;
              font-size: 15px;
            }
          }
        }
      }
    }

    > .search-result-article {
      > .entry {
        height: 490px;
        
        > a {
          background: #fff;
          text-align: center;

          > img {
            width: 250px !important;
            height: 250px !important;
          }
        }
      }
    }

    > .search-result-video {
      > .entry {
        height: 303px;
      }
    }

  }
}

.search-page-wrap-v2 {
  display: flex;
  padding-top: 10px;

  &.silent-search {
    > .filters {
      display: none;
    }

    > .search-content > .search-result {
      > .item {
        width: calc(25% - 10px);
  
        &:nth-child(3n+3) {
          margin-right: 12px;
        }
        &:nth-child(4n+4) {
          margin-right: 0;
        }

        @include breakpoint-max(1030px) {
          width: calc(33% - 11px) !important;
          margin-right: 12px !important;

          &:nth-child(2n+2), &:nth-child(4n+4) {
            margin-right: 12px;
          }

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }
      }
    }
  }

  > .filters {
    flex: 1;
    max-width: 215px;
    margin-right: 20px;

    @include breakpoint-max(840px) {
      margin-right: 15px;
      max-width: 170px;
    }

    > .filter-title {
      font-size: 18px;
    }
    > .filter-group {
      margin-bottom: 15px;

      &.price-slider-wrap {
        margin-bottom: 25px;
        
        > .inputs {
          margin-bottom: 12px;
          display: flex;
  
          > input {
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
            color: #333;
            width: 44%;
            padding: 4px 8px;
          }
  
          > span {
            flex: 1;
            text-align: center;
          }
        }
      }

      > .chb-toggle:checked {
        + label {
          > .collapse-icon {
            display: none;
          }
          > .expand-icon {
            display: block;
          }
        }

        ~ .filter-options {
          display: none;
        }

        ~ .see-more {
          display: none;
        }
      }

      > .chb-toggle {
        display: none;
      }

      > label {
        margin-bottom: 5px;
        font-size: 13px;
        width: 100%;
        cursor: pointer;

        > img {
          float: right;
          margin-top: 5px;

          &.collapse-icon {
            display: block;
          }

          &.expand-icon {
            display: none;
          }
        }
      }

      > .filter-options {
        &.columns {
          display: flex;
          flex-wrap: wrap;

          > input[type=checkbox] {
            & + label {
              > .text {
                max-width: 77px;
              }
            }
          }

          > .checkbox-one-line-normal {
            flex: 1;
            flex-basis: 50%;

            &:nth-child(n+6) {
              display: block;
            }

            &:nth-child(n+7) {
              display: none;
            }
          }
        }
        > .checkbox-one-line-normal {
          padding: 2px 0 0;
          overflow: hidden;

          &:nth-child(n+6) {
            display: none;
          }
          
          &:nth-child(odd) {
            padding-right: 5px;
          }

          > input[type=checkbox] {
            &:checked + label {
              .bullion-checkbox {
                margin: 0 4px 0 0;
                min-width: 16px;
                height: 16px;
                background-size: 16px;
              }
            }

            & + label {
              display: flex;
              align-items: flex-start;
              margin-bottom: 3px;

              .bullion-checkbox {
                margin: 0 4px 0 0;
                min-width: 16px;
                height: 16px;
                background-size: 16px;
              }
              
              > .text {
                font-size: 11px;
                padding-top: 1px;
                text-align: left;
              }
            }
          }
        }
      }

      > .see-more {
        font-size: 12px;
      }

      &.showmore {
        > .filter-options {
          > .checkbox-one-line-normal {
            &:nth-child(n+6) {
              display: block;
            }

            &.columns {
              > .checkbox-one-line-normal {
                &:nth-child(n+7) {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    
    > .price-slider-wrap {
      .noUi-horizontal {
        .noUi-handle {
          border: 1px solid #333;
          height: 15px;
          width: 15px;
          top: -4px;
          background: #fff;
        }
        .noUi-connect {
          background: $orange-light;
        }
      }
    }
  }

  > .search-content {
    flex: 1;

    > .active-filters {
      > .active-filter {
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        width: auto;
        padding: 2px 22px 2px 8px;
        margin-right: 6px;
        margin-bottom: 4px;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        padding-right: 22px;

        &.clear-all {
          border: 1px solid #dc8500;
          cursor: pointer;
        }
  
        > .remove {
          display: inline-block;
          position: absolute;
          right: 6px;
          top: 50%;
          transform: translateY(-50%);
          outline: 0;
        }
      }
    }

    .search-result {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;

      @include breakpoint-max(1030px) {
        > .item {
          width: calc(50% - 12px) !important;
          margin-right: 12px !important;

          &:nth-child(3n+3) {
            margin-right: initial;
          }

          &:nth-child(2n+2) {
            margin-right: 0;
          }
        }
      }

      > .item {
        display: flex;
        flex-direction: column;
        padding: 12px 5px;
        align-items: center;
        border: 1px solid #ebebeb;
        width: calc(33% - 7px);
        min-width: 155px;
        margin-right: 12px;
        margin-bottom: 12px;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        &.promo {
          .price {
            color: $font-red-dark !important;
          }
          .product-img::before {
            background-image: url(/img/m/sprite-retina-v12.png);
            background-position: -180px -100px;
            background-repeat: no-repeat;
            background-size: 490px;
            content: "";
            display: block;
            height: 18px;
            left: 0px;
            position: absolute;
            top: 0;
            width: 48px;     
          }
        }

        &.auction {
          .product-img::before {
            background: #cd5c5c;
            color: #fff;
            content: "Auction";
            display: inline-block;
            font-size: 11px;
            text-align: center;
            font-size: 11px;
            padding: 2px 4px;
            position: absolute;
            bottom: -3px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &.unavailable {
          .price {
            color: red !important;
          }
        }

        > a {
          @extend %disable-drag;
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-bottom: 3px;

          > .product-img {
            position:relative;
            align-self: center;
          }

          > .name {
            display: block;
            text-align: center;
            color: $font-black;
            font-weight: 700;
            font-size: 12px;
            margin: 8px 0;
            line-height: 1.2;
            flex: 1;
          }

          > .product-price {
            > .price-label {
              color: #8d8f92;
              font-size: 11px;
              font-weight: 400;
              display: block;
              text-align: center;
              padding: 0;
              line-height: 1;
              margin-bottom: 3px;

              > small {
                font-size: 11px;
                text-decoration: line-through;
                color: #8d8f92;
              }
            }
            > .price {
              text-align: center;
              display: block;
              font-weight: 700;
              font-size: 15px;
              color: $orange-light;
              line-height: 1;
              margin-bottom: 4px;
            }
          }
        }

        > .product-form {
          > button, a {
            padding: 4px 10px;
            font-size: 11px;
            font-weight: normal;
          }
          > .actions {
            text-align: center;
            display: flex;
            justify-content: center;

            > input {
              background: transparent;
              border: 1px solid #ccc;
              border-bottom-left-radius: 4px;
              border-right: 0;
              border-top-left-radius: 4px;
              color: #231f20;
              font-size: 12px;
              vertical-align: top;
              padding: 0 5px;
              height: 25px;
              width: 60px;
            }
            > button {
              @extend %disable-drag;
              border: 0;
              border-radius: 0;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
              height: 25px;
              padding: 4px 10px;
              width: auto;
              font-size: 11px;
              font-weight: 400;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
