/* State */

// Radio buttons

.radio-one-line {
  padding: 2px 0;
  margin: 0;
  
  label {
    padding-left: 0 !important;
    @include no-select();
  }
  
  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label {
    height: 20px;
    font-weight: bold;
    display: block;
    cursor: pointer;
  }

  input[type='radio'] + label.no-height {
    height: auto;
    font-weight: bold;
    display: block;
  }

  input[type='radio'] + label .bullion-radio {
    float: left;
    @extend %chbox-default;
    margin: 3px 5px 0 0; 
  }

  input[type='radio']:checked + label .bullion-radio {
    float: left;
    @extend %chbox-active;
    margin: 3px 5px 0 0; 
  }

  input[type='radio'] + label .bullion-radio-title {
    width: 85%;
    float: left;
    margin: 3px 0 0 5px;
    font-size: 14px;
  }

  input[type='radio'] + label .bullion-radio-title-half {
    float: left;
    margin: 3px 0 0;
    font-size: 14px;
  }
}

.radio-description  {
  padding-left: 30px;
  margin-bottom: 0;
  font-size: 14px;
}

.radio {
  width: 100px;
  margin: 0 auto;

  > input[type='radio'] {
    display: none;
  }

  > input[type='radio'] + label {
    padding: 0;
    display: block;

    > .text {
      width: 100%;
      display: block;
      font-size: 13px;
      text-align: center;
      @include no-select();
    }
  }

  > input[type='radio'] + label .bullion-radio {
    @extend %chbox-default;
    margin: 0 0 5px 40px;
  }

  > input[type='radio']:checked + label .bullion-radio {
    @extend %chbox-active;
    margin: 0 0 5px 40px;
  }

}

// Checkbox below text

.checkbox {
  width: 100px;
  margin: 0 auto;

  > input[type='checkbox'] {
    display: none;
  }

  > input[type='checkbox'] + label {
    padding: 0;
    display: block;

    > .text {
      width: 100%;
      display: block;
      font-size: 13px;
      text-align: center;
      @include no-select();
    }
  }

  > input[type='checkbox'] + label .bullion-checkbox {
    @extend %chbox-default;
    margin: 0 0 5px 40px;
  }

  > input[type='checkbox']:checked + label .bullion-checkbox {
    @extend %chbox-active;
    margin: 0 0 5px 40px;
  }

}

.checkbox-one-line-normal {
  margin: 0 auto;
  padding: 2px 6px;

  &.full-width {
    width: 100%;
  }

  > input[type='checkbox'] {
    display: none;
  }

  > input[type='checkbox'] + label {
    padding: 0;
    display: block;
    @include no-select();

    > .text {
      display: inline-block;
      font-size: 13px;
      font-weight: normal;
      text-align: center;
      @include no-select();
    }
  }

  > input[type='checkbox'] + label .bullion-checkbox {
    @extend %chbox-default;
    margin: 0 3px;
    vertical-align: -4px;
  }

  > input[type='checkbox']:checked + label .bullion-checkbox {
    @extend %chbox-active;
    margin: 0 3px;
    vertical-align: -4px;
  }
}

.checkbox-one-line {
  width: 100px;
  margin: 0 auto;

  &.full-width {
    width: 100%;
  }

  &.agreement {
    > input[type='checkbox'] + label .bullion-checkbox {
      margin-left: 0;
    }
  }

  > input[type='checkbox'] {
    display: none;
  }

  > input[type='checkbox'] + label {
    padding: 0;
    display: block;
    @include no-select();

    > .text {
      display: inline-block;
      font-size: 13px;
      text-align: center;
      @include no-select();
    }
  }

  > input[type='checkbox'] + label .bullion-checkbox {
    @extend %chbox-default;
    margin: 0 5px -4px 3px;
  }

  > input[type='checkbox']:checked + label .bullion-checkbox {
    @extend %chbox-active;
    margin: 0 5px -4px 0;
  }

  .long-text-terms {
    width: 90%;
    margin-left: 3px;
    display: inline-block;
  }

}
