.form-field {
  position: relative;
  border-bottom: 1px solid $orange-form-line-default;
  margin: 3px 0 17px;

  > .input-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 92%;
    padding: 12px 0 1px 24px;
    overflow: hidden;
    z-index: 1;
    font-size: 13px;
    border: none;
    background: #fff;
  }

  /* Set up base styles, we're going to scale instead of animating width/height */
  &::before {
    display: block;
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 50%;
    bottom: 2px;
    transform-origin: center;
    pointer-events: none;
  }

  &.error-field {
    border-bottom: 1px solid red;
  }

  &.note {
    margin-bottom: 35px;

    .note {
      position: absolute;
      bottom: -28px;
      font-size: 11px;
    }
  }

  &.no-icon {
    > label {
      left: 0;
      -webkit-font-smoothing: subpixel-antialiased;
      backface-visibility: hidden;
      transform: translateZ(0);

      &.active {
        left: 0;
        top: -6px !important;
      }
    } 

    > input {
      padding-left: 0;
    }
  }

  &.no-border {
    border-bottom: none !important;
  }

  &.show-label {
    > label {
      position: absolute;
      top: -8px;
      left: 0;
      width: 100%;
      padding: 0;
      font-size: 11px !important;

      &.occupation-label, &.nature-of-business-label, &.reset-account-label {
        top: -6px;
      }
    }
  }

  &.file {
    input[type=file] {
      outline: 0;
    }
  }

  &.focus {
    border-bottom: 1px solid $orange-form-line-active;
  }

  &.focus::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    border-bottom: 1px solid $orange-form-line-active;
    transition: 0.5s;
  }

  &.no-border.focus::before {
    border-bottom: none !important;
  }

  &.with-textarea {
    > label {
      top: 5px;
      left: 5px;
      -webkit-font-smoothing: subpixel-antialiased;
      backface-visibility: hidden;
      transform: translateZ(0);

      &.active {
        top: 0;
        left: 0;
      }
    }
  }

  &.with-tel {
    margin-top: -5px;

    > .active {
      top: 3px;
    }
  }

  > .icon {
    position: absolute;
    left: 0;
    bottom: 9px;
    z-index: 2;
  }

  > .icon.icon-mail-pin {
    bottom: 6px;
  }

  .icon.icon-map {
    bottom: 5px;
    top: auto;
  }

  > .icon-email {
    @extend %icon-form-mail;
  }

  > .icon-lock {
    @extend %icon-form-lock;
    bottom: 6px;
  }

  > .icon-user, > .icon-account-name {
    @extend %icon-form-user;
  }

  > .icon-pin, > .icon-bank-address {
    @extend %icon-form-pin;
  }

  > .icon-buildings {
    @extend %icon-form-buildings;
  }

  > .icon-mail-pin {
    @extend %icon-form-mail-pin;
  }

  > .icon-map {
    @extend %icon-form-map-pin;
  }

  > .icon-pin-code {
    @extend %icon-form-pin-code;
  }

  > .icon-house {
    @extend %icon-form-house;
  }

  > .icon-edit {
    @extend %icon-form-edit;
  }

  > .icon-dollar-sign {
    @extend %icon-form-dollar-sign;
  }

  > .icon-bank {
    @extend %icon-form-bank;
  }

  > .icon-swift {
    @extend %icon-form-swift;
  }

  > .icon-account-number {
    @extend %icon-form-bank-account-number;
  }

  > .icon-credit {
    @extend %icon-form-credit;
  }

  > .icon-bitcoin-address {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../img/bitcoin-v1.svg") 0 0 no-repeat;
    bottom: 6px;
  }

  > .icon-bitcoin-cash-address {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../img/bitcoincash.svg") 0 0 no-repeat;
    bottom: 6px;
  }

  > .icon-ethereum-address {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../img/ethereum.svg") 0 0 no-repeat;
    bottom: 6px;
  }

  > .icon-litecoin-address {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../img/litecoin.svg") 0 0 no-repeat;
    bottom: 6px;
  }

  > .icon-dogecoin-address {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url("../img/dogecoin.svg") 0 0 no-repeat;
    bottom: 6px;
  }

  > .icon-branch {
    @extend %icon-form-branch-code;
  }

  > .icon-clip {
    bottom: 7px;
    @extend %icon-form-paperclip;
  }

  > label {
    position: absolute;
    top: 0;
    left: 25px;
    width: auto;
    padding: 12px 30px 6px 0;
    margin: 0;
    z-index: 5;
    display: block;
    font-size: 11px;
    font-weight: normal;
    color: $font-gray;

    &:not(.active) {
      font-size: 14px;
    }
    
    &.active:not(.static) {
      position: absolute;
      top: -8px;
      left: 0;
      width: 100%;
      padding: 0;
      font-style: normal;
    }

    &.optional {
      font-style: italic;
    }
  }

  > input {
    width: 100%;
    padding: 12px 30px 6px 24px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    outline: none;

    &:focus::placeholder {
      opacity: 0;
    }
  }

  > textarea {
    width: 100%;
    border-radius: 0;
    border: none;
    background: transparent;
  }

  > .bordered {
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
    border: 1px solid #ccc;
  }

  .txt-capitalize {
    text-transform: capitalize;
  }
  
  .txt-password {
    -webkit-text-security: disc;
  }    
 
  > select {
    width: 100%;
    padding: 17px 30px 6px 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
  }

  > .image {
    position: absolute;
    right: 3px;
    bottom: 5px;
    z-index: 2;
    @extend %icon-form-check;
  }

  > .country-code {
    width: 100%;
    padding-bottom: 5px;

    > select {
      display: none;
    }

    > .mobile-phone {
      width: 75%;
      margin-top: 12px;
    
      @include breakpoint-max(884px) {
        width: 65%;
      }

      &.gray {
        &::before {
          background: #f9f9f9;
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -3px;
        width: 10px;
        height: 5px;
        background: #fff;
      }

      > input {
        width: 90%;
        padding: 7px 0 0 12px;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: transparent;
        outline: none;
        font-size: 14px;
      }

      > .image {
        @extend %icon-form-check;
        margin-top: 12px;
      }
    }
  }

  .fail {
    position: absolute;
    right: 1px;
    bottom: 5px;
  }
}
