.product-detail-wrap {
  padding: 20px 20px 0 0;
      
  @include breakpoint-max(1200px) {
    padding: 20px;
  }
  
  > .paging {
    > a {
      width: 50%;
      font-size: 13px;
      vertical-align: top;

      .prev {
        margin: 3px 10px 0 0;
        @extend %icon-page-prev;
      }

      .next {
        margin: 3px 0 0 10px;
        @extend %icon-page-next;
      }

      .next-text {
        text-align: right;
      }

      > .text {
        width: 93%;
        display: inline-block;
        color: #737373;
        @extend %ellipsis;
      
        @include breakpoint-max(880px) {
          width: 85%;
        }
      }
    }
  }

  .wide-window {
    display: block;
      
    @include breakpoint-max(1200px) {
      display: none;
    }
  }

  .narrow-window {
    display: none;
      
    @include breakpoint-max(1200px) {
      display: block;
      width: 400px;
    }
  }

  > .product-details {
    padding: 40px 0 0;

    > .items {
      > .product-default-wrap {
        > .top {
          position: relative;

          > h1 {
            margin: 0 0 15px;
            font-size: $standard-font-size-heading;
            line-height: 24px;

            &.lto {
              @include breakpoint-max(1200px) {
                text-align: center;
              }
            }

          }

          > .photo {
            width: 386px;
            float: left;
            margin: 0 30px 10px 0;
            text-align: center;

            @include breakpoint-max(1200px) {
              width: 400px;
              margin-right: 0;
            }

            &.no-nav {
              width: 316px;
            }

            > ul {
              margin-right: 7px;
              text-align: left;
              font-size: 0;

              > li {
                display: block;
                margin: 0 0 3px;

                &:nth-child(3n) {
                  margin-right: 0;
                }

                > img {
                  min-height: 70px;
                  min-width: 70px;
                  padding: 1px;
                  border: 1px solid #ccc;
                  cursor: pointer;
                }

                > .active {
                  pointer-events: none;
                  border: 1px solid $orange-mid;
                }
              }
            }
            
            > .main-image-wrap {
              margin: 0 auto;

              &.jewellery {
                position: relative;

                > .sale {
                  position: absolute;
                  bottom: 70px;
                  left: 0;
                  right: 0;
                  margin: auto;
                  z-index: 1;
                }
              }

              > figure {
                position: relative;
                text-align: center;
                background: #fff;

                > .loader {
                  position: absolute;
                  top: 45%;
                  left: 50%;
                  z-index: 1;

                  > .bullion-loader {
                    position: relative;
                    font-size: 10px;
                    text-indent: -9999em;
                    border-top: 0.7em solid $orange-mid;
                    border-right: 0.7em solid $orange-mid;
                    border-bottom: 0.7em solid $orange-mid;
                    border-left: 0.7em solid rgba(192, 104, 1, 50%);
                    transform: translateZ(0);
                    animation: load 1.1s infinite linear;
                  }

                  .bullion-loader,
                  .bullion-loader::after {
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    margin: -25px 0 0 -25px;
                  }
                }

                > .invisible {
                  visibility: hidden;
                }
              }

              > .rollover {
                margin: 5px 0;
                color: #888a89;
                font-size: 14px;
              }
            }
          }

          > .prices {
            position: relative;
            width: 400px;
            float: left;

            > h1 {
              margin: 0 0 5px;
              font-size: $standard-font-size-heading;
              line-height: 24px;

              &.lto {
                width: 100%;
                text-align: center;
              }
            }

            > .default {
              table {
                width: 230px;
                margin-bottom: 10px;

                th {
                  padding: 3px 3px 3px 0;
                  font-size: 14px;
                }

                td {
                  padding: 1px 3px 1px 0;
                  font-size: 14px;
                }
              }
            }

            > .promo {
              margin-bottom: 10px;

              > header {
                margin: 15px 0 12px;

                > .text {
                  width: 100%;
                  text-align: center;

                  > .name {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 700;
                    color: #000;

                    > img {
                      vertical-align: -26px;
                      margin-right: 7px;
                    }
                  }

                  > .time {
                    margin: -33px 0 0 35px;
                    font-size: 15px;
                    font-weight: 700;
                    text-align: center;

                    &.no-nav {
                      margin-left: 30px;
                    }
                  }
                }
              }

              > .price-old {
                width: 215px;
                margin-top: 2px;
                margin-bottom: 4px;
                font-size: 11px;
                text-align: center;

                > span {
                  text-decoration: line-through;
                }

                &.lto {
                  width: 100%;
                  text-align: center;
                }
              }

              > .price-new {
                width: 215px;
                margin-bottom: 4px;
                font-size: 18px;
                font-weight: 700;
                color: #be1e2d;
                text-align: center;

                &.lto {
                  width: 100%;
                  text-align: center;
                }
              }

              > .save {
                width: 215px;
                margin-bottom: 15px;
                font-size: 11px;
                text-align: center;

                &.lto {
                  width: 100%;
                  text-align: center;
                }
              }
            }

            .note, .desc {
              margin: 10px 0;
              font-size: 14px;
            }

            > .order-field {
              margin-bottom: 10px;
              margin-top: 10px;

              &.lto {
                width: 100%;
                text-align: center;

                > .notification-wrap {
                  text-align: center;
                }
              }

              > .notification-wrap {
                text-align: left;

                @include breakpoint-max(1200px) {
                  display: flex;
                  gap: 10px;
                }

                > .btn-auction-link {
                  @include breakpoint-max(1200px) {
                    flex: 1;
                    text-align: center;
                  }
                }
              }

              > .controls {
                > input {
                  width: 70px;
                  margin-right: 5px;
                  padding: 4px 4px 5px;
                  display: inline-block;
                  border-radius: 5px;
                  border: 1px solid #ccc;
                  vertical-align: top;
                }

                > .btn-common-radius {
                  padding: 5px 8px;
                }
              }
            }

            > .extra {
              margin: 10px 0;
              display: block;
              font-size: 13px;
              color: $font-red;
              font-weight: bold;

              > img {
                width: 15px;
                vertical-align: -3px;
              }
            }

            > .extra-lto {
              margin: 10px 0;
              display: block;
              font-size: 13px;
              color: #be1e2d;
              font-weight: bold;

              > img {
                width: 15px;
                vertical-align: -3px;
              }
            }

            &.promo {
              > .extra {
                text-align: center;
              }
            }

            > .status {
              margin-bottom: 0;
              font-size: 14px;

              // @include breakpoint-max(1181px) {
              //   text-align: center;
              // }

              &.lto {
                text-align: center;
              }
  
              > span {
                font-weight: bold;
                display: block;
              }
  
              .date {
                color: #4a4a4a;
                font-weight: normal;
              }
            }

            > .great-price-premium {
              margin-top: 5px;
              font-size: 14px;
              color: $font-red;

              &.lto {
                margin-left: 117px;
              }

              > img {
                margin-right: 3px;
                vertical-align: -3px;
              }
            }
          }

          > #zoom-loader {
            position: absolute;
            top: -1px;
            left: 381px;
            width: 500px;
            height: 500px;
            border: 1px solid #888;
            z-index: 10;
            background: #fff;

            > .loader {
              position: absolute;
              top: 45%;
              left: 50%;
              z-index: 1;

              > .bullion-loader {
                position: relative;
                font-size: 10px;
                text-indent: -9999em;
                border-top: 0.7em solid $orange-mid;
                border-right: 0.7em solid $orange-mid;
                border-bottom: 0.7em solid $orange-mid;
                border-left: 0.7em solid rgba(192, 104, 1, 50%);
                transform: translateZ(0);
                animation: load 1.1s infinite linear;
              }

              .bullion-loader,
              .bullion-loader::after {
                border-radius: 50%;
                width: 50px;
                height: 50px;
                margin: -25px 0 0 -25px;
              }
            }
          }
        }

        > .bottom {
          margin-top: 15px;

          h2 {
            font-size: 20px;
          }

          > .product-highlight {
            width: 400px;
            float: left;
            margin: 0 20px 5px 0; 

            > .heading {
              position: relative;

              > .share {
                position: absolute;
                top: 0;
                right: 0;

                > p {
                  margin-bottom: 5px;
                  font-size: 11px;
                  font-weight: bold;
                  text-align: right;
                }

                > ul {
                  text-align: right;

                  li {
                    margin-left: 3px;
                    display: inline-block;
                  }

                }
              }

              & + p {
                width: calc(100% - 120px);
              }
            }
            
            p {
              margin-bottom: 4px;
              font-size: 14px;
            }

            a {
              font-size: 14px;
            }

            > div {
              font-size: 14px;

              > p {
                margin: 8px 0;
              }
            }
          }

          > .price-information {
            float: left;
            width: 380px;

            table {
              width: 250px;
              margin-bottom: 15px;

              td {
                padding: 0 0 3px;
                font-size: 14px;

                &.price-sell {
                  font-weight: bold;
                }

                a:hover {
                  color: $orange-mid;
                }
              }
            }

            .gst-footnote {
              font-size: 11px;
              font-style: italic;
              margin-top: -10px;
              margin-bottom: 15px;
            }

            > .price-chart {
              position: relative;
              width: 380px;
              margin-bottom: 20px;
              min-height: 340px;

              @include breakpoint-max(1200px) {
                height: 400px;
              }

              > .product-chart-options {
                > .product-chart-option {
                  width: 24%;
                  margin-right: .8%;
                  padding: 5px;
                  text-align: center;
                  font-size: 13px;
                  color: #333;
                  background: #e8e8e8;
                  cursor: pointer;
                  @include no-select;

                  &.active {
                    color: #fff;
                    background: #dd8705;
                    pointer-events: none;
                  }

                  &.small {
                    width: 21%;
                  }

                  &.wide {
                    width: 31%;
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }

              > .loader {
                position: absolute;
                top: 45%;
                left: 50%;
                z-index: 2;

                > .bullion-loader {
                  position: relative;
                  font-size: 10px;
                  text-indent: -9999em;
                  border-top: 0.7em solid $orange-mid;
                  border-right: 0.7em solid $orange-mid;
                  border-bottom: 0.7em solid $orange-mid;
                  border-left: 0.7em solid rgba(192, 104, 1, 50%);
                  transform: translateZ(0);
                  animation: load 1.1s infinite linear;
                }

                .bullion-loader,
                .bullion-loader::after {
                  border-radius: 50%;
                  width: 50px;
                  height: 50px;
                  margin: -25px 0 0 -25px;
                }
              }

              > #main-chart {
                min-height: 340px;

                > svg {
                  margin-left: -5px;
                }
              }

              .chart-footnote {
                margin: 0;
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    > .auction-item {
      margin-top: -20px;

      @keyframes bellshake {
        0% { transform: rotate(0); }
        15% { transform: rotate(3deg); }
        30% { transform: rotate(-3deg); }
        45% { transform: rotate(2deg); }
        80% { transform: rotate(-2deg); }
        90% { transform: rotate(1deg); }
        100% { transform: rotate(0); }
      }
      
      &:hover, &:focus {
        /* animate bell icon to shake */
        .bell.watchlist {
          animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
          backface-visibility: hidden;
          transform-origin: top right;
        }
      }
      
      > .product-default-wrap {
        > .top {
          position: relative;

          .top-info {
            margin-left: 25px;

            > h1 {
              margin: 0 0 5px;
              font-size: $standard-font-size-heading;
              line-height: 24px;
              text-align: center;
  
              &.lto {
                @include breakpoint-max(1200px) {
                  text-align: center;
                }
              }
            }

            > .time-left {
              margin: 0;
              font-weight: bold;
              font-size: $standard-font-size;
              text-align: center;

              &.lessThan24hrs {
                color: $orange-light;
              }
              &.lessThan15min {
                color: $font-red-dark;
              }

              > .with-bell {
                position: relative;
                display: inline-block;
                min-width: 64px;

                > .bell {
                  cursor: pointer;
                  position: absolute;
                  top: -3px;
                  right: -35px;
                }
              }
            }

            > .ends-in {
              margin: 0 0 15px;
              color: #737373;
              font-size: 13px;
              text-align: center;
            }
          }

          > .photo {
            width: 386px;
            float: left;
            margin: 0 30px 10px 0;
            text-align: center;

            @include breakpoint-max(1200px) {
              width: 400px;
              margin-right: 0;
            }

            &.no-nav {
              width: 316px;
            }

            > ul {
              margin-right: 7px;
              text-align: left;
              font-size: 0;

              > li {
                display: block;
                margin: 0 0 3px;

                &:nth-child(3n) {
                  margin-right: 0;
                }

                > img {
                  min-height: 70px;
                  min-width: 70px;
                  padding: 1px;
                  border: 1px solid #ccc;
                  cursor: pointer;
                }

                > .active {
                  pointer-events: none;
                  border: 1px solid $orange-mid;
                }
              }
            }
            
            > .main-image-wrap {
              margin: 0 auto;

              &.jewellery {
                position: relative;

                > .sale {
                  position: absolute;
                  bottom: 70px;
                  left: 0;
                  right: 0;
                  margin: auto;
                  z-index: 1;
                }
              }

              > figure {
                position: relative;
                text-align: center;
                background: #fff;

                > .loader {
                  position: absolute;
                  top: 45%;
                  left: 50%;
                  z-index: 1;

                  > .bullion-loader {
                    position: relative;
                    font-size: 10px;
                    text-indent: -9999em;
                    border-top: 0.7em solid $orange-mid;
                    border-right: 0.7em solid $orange-mid;
                    border-bottom: 0.7em solid $orange-mid;
                    border-left: 0.7em solid rgba(192, 104, 1, 50%);
                    transform: translateZ(0);
                    animation: load 1.1s infinite linear;
                  }

                  .bullion-loader,
                  .bullion-loader::after {
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    margin: -25px 0 0 -25px;
                  }
                }

                > .invisible {
                  visibility: hidden;
                }

                > .auction-status {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  padding: 10px;
                  display: block;
                  background: $orange-light;
                  color: #fff;
                  font-size: $standard-font-size-heading;
                  font-weight: bold;
                  white-space: nowrap;
                }
              }

              > .rollover {
                margin: 5px 0;
                color: #888a89;
                font-size: 14px;
              }
            }
          }

          > .prices {
            position: relative;
            width: 400px;
            float: left;
            text-align: center;

            @include breakpoint-max(1200px) {
              margin-left: 25px;
            }
      
            > h2 {
              margin: 0 0 5px;
              font-size: $standard-font-size-heading;
              line-height: 24px;
            }

            > .time-left {
              margin: 0;
              font-weight: bold;
              font-size: $standard-font-size;

              &.lessThan24hrs {
                color: $orange-light;
              }
              &.lessThan15min {
                color: $font-red-dark;
              }

              > .with-bell {
                position: relative;
                display: inline-block;
                min-width: 64px;

                > .bell {
                  cursor: pointer;
                  position: absolute;
                  top: -3px;
                  right: -35px;
                }
              }
            }

            > .ends-in {
              margin: 0 0 15px;
              color: #737373;
              font-size: 13px;
            }

            > .price-text {
              margin: 0 0 2px;
              text-align: center;
              color: #333;
              transition: color 0.5s;
            }

            > .status-text {
              margin: 0;
              color: green;
            }

            > .price {
              margin: 0;
              color: $orange-light;
              font-size: $standard-font-size-heading;
              font-weight: bold;
            }
            
            .autobid-reached {
              color: $font-red-dark;
              font-size: 13px;
              text-align: center;
              line-height: 1;
              margin-bottom: 1px;
            }

            > .btn-view-bids {
              margin: 15px 0;
            }

            > .bid-text {
              margin: 0 0 8px;
              color: #737373;
            }

            > .actions {
              margin-bottom: 10px;
              display: flex;
              justify-content: center;
              
              > input {
                width: 80px;
                padding: 5px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border: 1px solid #ccc;
                border-right: 0;
                vertical-align: top;
                font-size: 12px;
                color: #231F20;
                background: transparent;
              }
          
              > button {
                width: 80px;
                padding: 7px;
                font-size: 11px;
                border-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border: 0;

                &.btn-edit-bid, &.btn-view-bid {
                  border-radius: 4px;
                }
              }
            }

            > .bid-count {
              color: $orange-mid;
              cursor: pointer;

              &.no-bid {
                cursor: auto;
                color: #8D8F92;
              }
            }

            > .btn-buy-now {
              display: block;
              width: 100%;
              padding: 5px;
              max-width: 160px;
              margin: 0 auto;
            }
          }

          > #zoom-loader {
            position: absolute;
            top: -1px;
            left: 381px;
            width: 500px;
            height: 500px;
            border: 1px solid #888;
            z-index: 10;
            background: #fff;

            > .loader {
              position: absolute;
              top: 45%;
              left: 50%;
              z-index: 1;

              > .bullion-loader {
                position: relative;
                font-size: 10px;
                text-indent: -9999em;
                border-top: 0.7em solid $orange-mid;
                border-right: 0.7em solid $orange-mid;
                border-bottom: 0.7em solid $orange-mid;
                border-left: 0.7em solid rgba(192, 104, 1, 50%);
                transform: translateZ(0);
                animation: load 1.1s infinite linear;
              }

              .bullion-loader,
              .bullion-loader::after {
                border-radius: 50%;
                width: 50px;
                height: 50px;
                margin: -25px 0 0 -25px;
              }
            }
          }
        }

        > .bottom {
          margin-top: 15px;
          overflow: hidden;

          h2 {
            margin: 10px 0;
            font-size: 20px;
          }

          > .product-highlight {
            width: 400px;
            margin: 0 30px 5px 0;
            float: left;

            > .heading {
              position: relative;

              > .share {
                position: absolute;
                top: 0;
                right: 0;

                > p {
                  margin-bottom: 5px;
                  font-size: 11px;
                  font-weight: bold;
                  text-align: right;
                }

                > ul {
                  text-align: right;

                  li {
                    margin-left: 3px;
                    display: inline-block;
                  }

                }
              }

              & + p {
                width: calc(100% - 120px);
              }
            }
            
            p {
              margin-bottom: 4px;
              font-size: 14px;
            }

            a {
              font-size: 14px;
            }

            > div {
              font-size: 14px;

              > p {
                margin: 8px 0;
              }
            }
          }

          > .delivery {
            float: left;
            width: 400px;
            margin-bottom: 10px;

            p {
              font-size: 14px;
            }

            > .list {
              margin-bottom: 15px;

              > li {
                margin-bottom: 5px;
                display: flex;
                align-items: flex-start;

                > img {
                  margin: 5px 5px 0 0;
                }
              }
            }

            > .ship-to {
              padding: 0 15px;

              > .form-field {
                margin-bottom: 10px;
              }
            }
          }
        }

        &.my-leading {
          > .top > .prices {
            > .price-text {
              color: #0c9358;
            }
          }
        }

        &.change-leading:not(.my-leading) {
          > .top > .prices {
            > .price-text, > .price {
              color: $font-red;
            }
          }
        }
      }
    }
  }

  > .suggestion-product {
    display: flex;

    > .product {
      float: left;
      flex-grow: 0;

      @include breakpoint-max(1018px) {
        &:last-child {
          display: none;
        }
      }

      @include breakpoint-max(819px) {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }

  > h2 {
    padding: 20px 0;
    font-size: 20px;
  }

  &.bs-dragonsilver-2024 {
    padding: 0;

    .page-title {
      text-align: center;
      font-size: 62px;
      color: #cf8830;
      line-height: 1;

      @include breakpoint-max(950px) {
        font-size: 44px;
      }
    }
    
    .page-subtitle {
      text-align: center;
      font-size: 26px;
      color: #b8b8b8;
      margin-bottom: 30px;

      @include breakpoint-max(950px) {
        font-size: 24px;
      }
    }

    > .product-details {
      background: #000;
      background-image: url('../img/d/bg-dragonround-2024.jpg');
      background-repeat: no-repeat;
      background-position: top;
      padding: 30px;

      @include breakpoint-max(820px) {
        padding: 30px 20px;
      }

      h4 {
        font-size: 24px;
        margin: 0;
        text-align: center;
        color: #e8e8e8;

        @include breakpoint-max(820px) {
          font-size: 22px;
        }
      }

      h3 {
        margin: 0 0 20px;
        font-size: 40px;
        color: #cf8830;
        text-align: center;

        @include breakpoint-max(820px) {
          font-size: 34px;
          margin: 0 0 15px;
        }
      }

      >.items >.product-default-wrap {
        >.top {
          >.photo {
            @include breakpoint-max(1220px) {
              float: none;
              margin: 0 auto;
            }

            > .main-image-wrap >figure {
              background: transparent;
            }
          }

          >.prices {
            width: 380px;
            @include breakpoint-max(1220px) {
              float: none;
              margin: 20px auto 0;
            }
    
            > .wide-window {
              color: #e8e8e8;
    
              @include breakpoint-max(1200px) {
                display: block;
              }
            }
    
            > .default table {
              color: #e8e8e8;
            }
  
            > .status {
              @include breakpoint-max(1220px) {
                text-align: left;

                &.lto {
                  text-align: center;
                }
              }
            }

            &.promo {
              >.promo {
                >header >.text {
                  >.name {
                    color: #fff;
                  }

                  >.time {
                    color: #e8e8e8;
                  }
                }

                >.price-old.lto {
                  color: #e8e8e8;
                  
                  >span {
                    color: #e8e8e8;
                  }
                }

                >.price-new.lto {
                  color: $font-red;
                }

                >.save.lto {
                  color: #e8e8e8;
                }
              }
            }
          }
        }

        > .bottom {
          color: #e8e8e8;

          > .product-highlight {
            margin: 0 30px 0 0;
            width: 393px;

            @include breakpoint-max(820px) {
              margin: 0;
              width: 100%;
            }

            .highlights-banner {
              margin-left: -30px;
              margin-top: 20px;
              margin-bottom: 30px;
              width: calc(100% + 30px);

              @include breakpoint-max(820px) {
                margin: 30px 0;
                width: 100%;
              }

              img {
                width: 100%;

                &.tablet-view {
                  display: none;
                }
                @include breakpoint-max(820px) {
                  &.desktop-view {
                    display: none;
                  }

                  &.tablet-view {
                    display: block;
                  }
                }
              }
            }
          }

          > .price-information >.price-chart {
            .product-chart-options {
              .product-chart-option {
                background: #222;
                color: #e8e8e8;
                border: 0;
                width: calc(25% - 4px);
                margin-right: 4px;
                
                &.active {
                  border: 0;
                  background: #DC8500;
                  color: #e8e8e8;
                }
              }
            }

            >#main-chart >svg {
              text {
                fill: #e8e8e8;
              }

              g {
                line {
                  stroke: #333 !important;
                }
              }
            }
          }
        }
      }
  
      .extensive-details {
        p {
          font-size: 16px;
          color: #e8e8e8;

          @include breakpoint-max(820px) {
            line-height: 1.3;
          }
        }
  
        > .intro {
          margin: 30px 0;
  
          > p {
            margin-bottom: 20px;
          }
        }
  
        > .banner-middle-wrap {
          position: relative;
          margin: 30px 0;
      
          > .banner-middle {
            height: auto;
            margin: 0 -30px;

            @include breakpoint-max(820px) {
              margin: 0 -20px;
            }
  
            img {
              width: 100%;
            }
          }
      
          > .banner-middle-desc {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            text-align: center;
      
            p {
              font-size: 16px;
              width: 360px;
              margin: 0 auto;

              @include breakpoint-max(820px) {
                width: 304px;
                line-height: 1.3;
              }
            }

            @include breakpoint-max(1024px) {
              transform: translateY(-50%) scale(0.8);
              right: -30px;
            }
          }
        }
  
        > .banner-bottom-wrap {
          margin: 30px 0;

          > .banner-bottom-desc {
            text-align: center;

            > span {
              display: block;

              @include breakpoint-max(820px) {
                display: inline;
              }
            }
          }
        
          > .banner-bottom {
            margin: 30px -30px;

            @include breakpoint-max(820px) {
              margin: 30px -20px;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }

    > .contact {
      padding: 0 30px;
      margin: 30px 0;

      @include breakpoint-max(820px) {
        padding: 0 20px;
      }

      h2 {
        font-size: 20px;
      }
    }

    > .also-bought-header {
      padding: 0 30px;

      @include breakpoint-max(820px) {
        padding: 0 20px;
      }
    }

    > .suggestion-product {
      padding: 0 30px;

      @include breakpoint-max(820px) {
        padding: 0 20px;
      }
    }
  }
}