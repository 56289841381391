.flag {
  position: absolute;
  left: 0;
  top: 10px;
  width: 24px;
  height: 24px;

  &.flag-ad {
    @extend %flag-ad;
  }

  &.flag-ae {
    @extend %flag-ae;
  }

  &.flag-af {
    @extend %flag-af;
  }

  &.flag-ag {
    @extend %flag-ag;
  }

  &.flag-ai {
    @extend %flag-ai;
  }

  &.flag-al {
    @extend %flag-al;
  }

  &.flag-am {
    @extend %flag-am;
  }

  &.flag-an {
    @extend %flag-an;
  }

  &.flag-ao {
    @extend %flag-ao;
  }

  &.flag-ar {
    @extend %flag-ar;
  }

  &.flag-at {
    @extend %flag-at;
  }

  &.flag-as {
    @extend %flag-as;
  }

  &.flag-au {
    @extend %flag-au;
  }

  &.flag-aw {
    @extend %flag-aw;
  }

  &.flag-ax {
    @extend %flag-ax;
  }

  &.flag-az {
    @extend %flag-az;
  }

  &.flag-ba {
    @extend %flag-ba;
  }

  &.flag-bb {
    @extend %flag-bb;
  }

  &.flag-bd {
    @extend %flag-bd;
  }

  &.flag-be {
    @extend %flag-be;
  }

  &.flag-bf {
    @extend %flag-bf;
  }

  &.flag-bg {
    @extend %flag-bg;
  }

  &.flag-bh {
    @extend %flag-bh;
  }

  &.flag-bi {
    @extend %flag-bi;
  }

  &.flag-bj {
    @extend %flag-bj;
  }

  &.flag-bm {
    @extend %flag-bm;
  }

  &.flag-bn {
    @extend %flag-bn;
  }

  &.flag-bo {
    @extend %flag-bo;
  }

  &.flag-br {
    @extend %flag-br;
  }

  &.flag-bs {
    @extend %flag-bs;
  }

  &.flag-bt {
    @extend %flag-bt;
  }

  &.flag-bv {
    @extend %flag-bv;
  }

  &.flag-bw {
    @extend %flag-bw;
  }

  &.flag-by {
    @extend %flag-by;
  }

  &.flag-bz {
    @extend %flag-bz;
  }

  &.flag-ca {
    @extend %flag-ca;
  }

  &.flag-catalonia {
    @extend %flag-catalonia;
  }

  &.flag-cc {
    @extend %flag-cc;
  }

  &.flag-cd {
    @extend %flag-cd;
  }

  &.flag-cf {
    @extend %flag-cf;
  }

  &.flag-cg {
    @extend %flag-cg;
  }

  &.flag-ch {
    @extend %flag-ch;
  }

  &.flag-ci {
    @extend %flag-ci;
  }

  &.flag-ck {
    @extend %flag-ck;
  }

  &.flag-cl {
    @extend %flag-cl;
  }

  &.flag-cm {
    @extend %flag-cm;
  }

  &.flag-cn {
    @extend %flag-cn;
  }

  &.flag-co {
    @extend %flag-co;
  }

  &.flag-cr {
    @extend %flag-cr;
  }

  &.flag-cs {
    @extend %flag-cs;
  }

  &.flag-cu {
    @extend %flag-cu;
  }

  &.flag-cv {
    @extend %flag-cv;
  }

  &.flag-cx {
    @extend %flag-cx;
  }

  &.flag-cy {
    @extend %flag-cy;
  }

  &.flag-cz {
    @extend %flag-cz;
  }

  &.flag-de {
    @extend %flag-de;
  }

  &.flag-dj {
    @extend %flag-dj;
  }

  &.flag-dk {
    @extend %flag-dk;
  }

  &.flag-dm {
    @extend %flag-dm;
  }

  &.flag-do {
    @extend %flag-do;
  }

  &.flag-dz {
    @extend %flag-dz;
  }

  &.flag-ec {
    @extend %flag-ec;
  }

  &.flag-ee {
    @extend %flag-ee;
  }

  &.flag-eg {
    @extend %flag-eg;
  }

  &.flag-eh {
    @extend %flag-eh;
  }

  &.flag-england {
    @extend %flag-england;
  }

  &.flag-er {
    @extend %flag-er;
  }

  &.flag-es {
    @extend %flag-es;
  }

  &.flag-et {
    @extend %flag-et;
  }

  &.flag-europeanunion {
    @extend %flag-europeanunion;
  }

  &.flag-farm {
    @extend %flag-farm;
  }

  &.flag-fi {
    @extend %flag-fi;
  }

  &.flag-fj {
    @extend %flag-fj;
  }

  &.flag-fk {
    @extend %flag-fk;
  }

  &.flag-fm {
    @extend %flag-fm;
  }

  &.flag-fo {
    @extend %flag-fo;
  }

  &.flag-fr {
    @extend %flag-fr;
  }

  &.flag-ga {
    @extend %flag-ga;
  }

  &.flag-gb {
    @extend %flag-gb;
  }

  &.flag-gd {
    @extend %flag-gd;
  }

  &.flag-ge {
    @extend %flag-ge;
  }

  &.flag-gf {
    @extend %flag-gf;
  }

  &.flag-gg {
    @extend %flag-gg;
  }

  &.flag-gh {
    @extend %flag-gh;
  }

  &.flag-gi {
    @extend %flag-gi;
  }

  &.flag-gl {
    @extend %flag-gl;
  }

  &.flag-gm {
    @extend %flag-gm;
  }

  &.flag-gn {
    @extend %flag-gn;
  }

  &.flag-gp {
    @extend %flag-gp;
  }

  &.flag-gq {
    @extend %flag-gq;
  }

  &.flag-gr {
    @extend %flag-gr;
  }

  &.flag-gs {
    @extend %flag-gs;
  }

  &.flag-gt {
    @extend %flag-gt;
  }

  &.flag-gu {
    @extend %flag-gu;
  }

  &.flag-gw {
    @extend %flag-gw;
  }

  &.flag-gy {
    @extend %flag-gy;
  }

  &.flag-hk {
    @extend %flag-hk;
  }

  &.flag-hm {
    @extend %flag-hm;
  }

  &.flag-hn {
    @extend %flag-hn;
  }

  &.flag-hr {
    @extend %flag-hr;
  }

  &.flag-ht {
    @extend %flag-ht;
  }

  &.flag-hu {
    @extend %flag-hu;
  }

  &.flag-id {
    @extend %flag-id;
  }

  &.flag-ie {
    @extend %flag-ie;
  }

  &.flag-il {
    @extend %flag-il;
  }

  &.flag-im {
    @extend %flag-im;
  }

  &.flag-in {
    @extend %flag-in;
  }

  &.flag-io {
    @extend %flag-io;
  }

  &.flag-iq {
    @extend %flag-iq;
  }

  &.flag-ir {
    @extend %flag-ir;
  }

  &.flag-is {
    @extend %flag-is;
  }

  &.flag-it {
    @extend %flag-it;
  }

  &.flag-jm {
    @extend %flag-jm;
  }

  &.flag-jo {
    @extend %flag-jo;
  }

  &.flag-jp {
    @extend %flag-jp;
  }

  &.flag-ke {
    @extend %flag-ke;
  }

  &.flag-kg {
    @extend %flag-kg;
  }

  &.flag-kh {
    @extend %flag-kh;
  }

  &.flag-ki {
    @extend %flag-ki;
  }

  &.flag-km {
    @extend %flag-km;
  }

  &.flag-kn {
    @extend %flag-kn;
  }

  &.flag-kp {
    @extend %flag-kp;
  }

  &.flag-kr {
    @extend %flag-kr;
  }

  &.flag-kw {
    @extend %flag-kw;
  }

  &.flag-ky {
    @extend %flag-ky;
  }

  &.flag-kz {
    @extend %flag-kz;
  }

  &.flag-la {
    @extend %flag-la;
  }

  &.flag-lb {
    @extend %flag-lb;
  }

  &.flag-lc {
    @extend %flag-lc;
  }

  &.flag-li {
    @extend %flag-li;
  }

  &.flag-lk {
    @extend %flag-lk;
  }

  &.flag-lr {
    @extend %flag-lr;
  }

  &.flag-ls {
    @extend %flag-ls;
  }

  &.flag-lt {
    @extend %flag-lt;
  }

  &.flag-lu {
    @extend %flag-lu;
  }

  &.flag-lv {
    @extend %flag-lv;
  }

  &.flag-ly {
    @extend %flag-ly;
  }

  &.flag-ma {
    @extend %flag-ma;
  }

  &.flag-mc {
    @extend %flag-mc;
  }

  &.flag-md {
    @extend %flag-md;
  }

  &.flag-me {
    @extend %flag-me;
  }

  &.flag-mg {
    @extend %flag-mg;
  }

  &.flag-mh {
    @extend %flag-mh;
  }

  &.flag-mk {
    @extend %flag-mk;
  }

  &.flag-ml {
    @extend %flag-ml;
  }

  &.flag-mm {
    @extend %flag-mm;
  }

  &.flag-mn {
    @extend %flag-mn;
  }

  &.flag-mo {
    @extend %flag-mo;
  }

  &.flag-mp {
    @extend %flag-mp;
  }

  &.flag-mq {
    @extend %flag-mq;
  }

  &.flag-mr {
    @extend %flag-mr;
  }

  &.flag-ms {
    @extend %flag-ms;
  }

  &.flag-mt {
    @extend %flag-mt;
  }

  &.flag-mu {
    @extend %flag-mu;
  }

  &.flag-mv {
    @extend %flag-mv;
  }

  &.flag-mw {
    @extend %flag-mw;
  }

  &.flag-mx {
    @extend %flag-mx;
  }

  &.flag-my {
    @extend %flag-my;
  }

  &.flag-mz {
    @extend %flag-mz;
  }

  &.flag-na {
    @extend %flag-na;
  }

  &.flag-nc {
    @extend %flag-nc;
  }

  &.flag-ne {
    @extend %flag-ne;
  }

  &.flag-nf {
    @extend %flag-nf;
  }

  &.flag-ng {
    @extend %flag-ng;
  }

  &.flag-ni {
    @extend %flag-ni;
  }

  &.flag-nl {
    @extend %flag-nl;
  }

  &.flag-no {
    @extend %flag-no;
  }

  &.flag-np {
    @extend %flag-np;
  }

  &.flag-nr {
    @extend %flag-nr;
  }

  &.flag-nu {
    @extend %flag-nu;
  }

  &.flag-nz {
    @extend %flag-nz;
  }

  &.flag-om {
    @extend %flag-om;
  }

  &.flag-pa {
    @extend %flag-pa;
  }

  &.flag-pe {
    @extend %flag-pe;
  }

  &.flag-pf {
    @extend %flag-pf;
  }

  &.flag-pg {
    @extend %flag-pg;
  }

  &.flag-ph {
    @extend %flag-ph;
  }

  &.flag-pk {
    @extend %flag-pk;
  }

  &.flag-pl {
    @extend %flag-pl;
  }

  &.flag-pm {
    @extend %flag-pm;
  }

  &.flag-pn {
    @extend %flag-pn;
  }

  &.flag-pr {
    @extend %flag-pr;
  }

  &.flag-ps {
    @extend %flag-ps;
  }

  &.flag-pt {
    @extend %flag-pt;
  }

  &.flag-pw {
    @extend %flag-pw;
  }

  &.flag-py {
    @extend %flag-py;
  }

  &.flag-qa {
    @extend %flag-qa;
  }

  &.flag-re {
    @extend %flag-re;
  }

  &.flag-ro {
    @extend %flag-ro;
  }

  &.flag-rs {
    @extend %flag-rs;
  }

  &.flag-ru {
    @extend %flag-ru;
  }

  &.flag-rw {
    @extend %flag-rw;
  }

  &.flag-sa {
    @extend %flag-sa;
  }

  &.flag-sb {
    @extend %flag-sb;
  }

  &.flag-sc {
    @extend %flag-sc;
  }

  &.flag-scotland {
    @extend %flag-scotland;
  }

  &.flag-sd {
    @extend %flag-sd;
  }

  &.flag-se {
    @extend %flag-se;
  }

  &.flag-sg {
    @extend %flag-sg;
  }

  &.flag-sh {
    @extend %flag-sh;
  }

  &.flag-si {
    @extend %flag-si;
  }

  &.flag-sj {
    @extend %flag-sj;
  }

  &.flag-sk {
    @extend %flag-sk;
  }

  &.flag-sl {
    @extend %flag-sl;
  }

  &.flag-sm {
    @extend %flag-sm;
  }

  &.flag-sn {
    @extend %flag-sn;
  }

  &.flag-so {
    @extend %flag-so;
  }

  &.flag-sr {
    @extend %flag-sr;
  }

  &.flag-ss {
    @extend %flag-ss;
  }

  &.flag-st {
    @extend %flag-st;
  }

  &.flag-sv {
    @extend %flag-sv;
  }

  &.flag-sy {
    @extend %flag-sy;
  }

  &.flag-sz {
    @extend %flag-sz;
  }

  &.flag-tc {
    @extend %flag-tc;
  }

  &.flag-td {
    @extend %flag-td;
  }

  &.flag-tf {
    @extend %flag-tf;
  }

  &.flag-tg {
    @extend %flag-tg;
  }

  &.flag-th {
    @extend %flag-th;
  }

  &.flag-tj {
    @extend %flag-tj;
  }

  &.flag-tk {
    @extend %flag-tk;
  }

  &.flag-tl {
    @extend %flag-tl;
  }

  &.flag-tm {
    @extend %flag-tm;
  }

  &.flag-tn {
    @extend %flag-tn;
  }

  &.flag-to {
    @extend %flag-to;
  }

  &.flag-tr {
    @extend %flag-tr;
  }

  &.flag-tt {
    @extend %flag-tt;
  }

  &.flag-tv {
    @extend %flag-tv;
  }

  &.flag-tw {
    @extend %flag-tw;
  }

  &.flag-tz {
    @extend %flag-tz;
  }

  &.flag-ua {
    @extend %flag-ua;
  }

  &.flag-ug {
    @extend %flag-ug;
  }

  &.flag-uk {
    @extend %flag-uk;
  }

  &.flag-um {
    @extend %flag-um;
  }

  &.flag-us {
    @extend %flag-us;
  }

  &.flag-uy {
    @extend %flag-uy;
  }

  &.flag-uz {
    @extend %flag-uz;
  }

  &.flag-va {
    @extend %flag-va;
  }

  &.flag-vc {
    @extend %flag-vc;
  }

  &.flag-ve {
    @extend %flag-ve;
  }

  &.flag-vg {
    @extend %flag-vg;
  }

  &.flag-vi {
    @extend %flag-vi;
  }

  &.flag-vn {
    @extend %flag-vn;
  }

  &.flag-vu {
    @extend %flag-vu;
  }

  &.flag-wales {
    @extend %flag-wales;
  }

  &.flag-wf {
    @extend %flag-wf;
  }

  &.flag-ws {
    @extend %flag-ws;
  }

  &.flag-ye {
    @extend %flag-ye;
  }

  &.flag-yt {
    @extend %flag-yt;
  }

  &.flag-za {
    @extend %flag-za;
  }

  &.flag-zm {
    @extend %flag-zm;
  }

  &.flag-zw {
    @extend %flag-zw;
  }

  &.flag-je {
    @extend %flag-je;
  }

  &.flag-cw {
    @extend %flag-cw;
  }

  &.flag-eng {
    @extend %flag-eng;
  }

  &.flag-sgd {
    @extend %flag-sgd;
  }

  &.flag-usd {
    @extend %flag-usd;
  }

  &.flag-aud {
    @extend %flag-aud;
  }

  &.flag-jpy {
    @extend %flag-jpy;
  }

  &.flag-eur {
    @extend %flag-eur;
  }

  &.flag-sek {
    @extend %flag-sek;
  }

  &.flag-brl {
    @extend %flag-brl;
  }

  &.flag-gbp {
    @extend %flag-gbp;
  }

  &.flag-cad {
    @extend %flag-cad;
  }

  &.flag-cny {
    @extend %flag-cny;
  }

  &.flag-dkk {
    @extend %flag-dkk;
  }

  &.flag-hkd {
    @extend %flag-hkd;
  }

  &.flag-inr {
    @extend %flag-inr;
  }

  &.flag-idr {
    @extend %flag-idr;
  }

  &.flag-myr {
    @extend %flag-myr;
  }

  &.flag-nzd {
    @extend %flag-nzd;
  }

  &.flag-rub {
    @extend %flag-rub;
  }

  &.flag-chf {
    @extend %flag-chf;
  }

  &.flag-btc {
    @extend %flag-btc;
  }

  &.flag-bch {
    @extend %flag-bch;
  }

  &.flag-eth {
    @extend %flag-eth;
  }

  &.flag-ltc {
    @extend %flag-ltc;
  }

  &.flag-xrp {
    @extend %flag-xrp;
  }

  &.flag-xdg {
    @extend %flag-xdg;
  }
}
