/* Theme */
.-italic {
  font-style: italic;
}

.-bold {
  font-weight: bold;
}

.-red, .font-red {
  color: red;
}

.font-red-amazon {
  color: $orange-light;
}

.-green, .font-green {
  color: green;
}
