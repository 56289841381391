.jewellery-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.product-jewellery {
  position: relative;
  width: 200px;
  min-height: 320px;
  height: auto;
  margin: 0 11px 10px 0;
  padding: 10px 7px;
  outline: none;
  background: #fff;
  border: 1px solid #ebebeb;

  > .sale {
    position: absolute;
    top: 0;
    left: 0;
  }

  > .link {
    margin-bottom: 4px;
    cursor: pointer;

    > .name {
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #231F20;
    }

    > figure {
      position: relative;
      min-height: 100px;
      margin: 10px 0;
      text-align: center;

      > img {
        position: absolute;
        inset: 0;
        width: 100px;
        height: 100px;
        margin: auto;
      }
    }
  }

  > .status-wrap {
    margin: 0;
    text-align: center;

    > p {
      margin: 0;
      font-weight: bold;
    }
  }

  > .price-text {
    margin: 15px 0 0;
    color: #8D8F92;
    font-size: 10px;
    line-height: 8px;
    text-align: center;
  }

  > .price {
    height: 30px;
    margin: 0 0 5px;
    text-align: center;
    color: $orange-light;
    font-size: 18px;
    font-weight: bold;
  }

  > .actions {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    
    > input {
      width: 60px;
      padding: 5px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #ccc;
      border-right: 0;
      vertical-align: top;
      font-size: 12px;
      color: #231F20;
      background: transparent;
    }

    > button {
      width: 80px;
      padding: 7px;
      font-size: 11px;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 0;
    }
  }
}