.product-filter {
  position: relative;
  margin-top: 10px;
  padding: 15px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
      
  @include breakpoint-max(850px) {
    padding: 10px 7px;
  }

  > .loading {
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%); 
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    z-index: 1;

    > .bullion-loader {
      position: relative;
      font-size: 10px;
      text-indent: -9999em;
      border-top: 0.6em solid $orange-mid;
      border-right: 0.6em solid $orange-mid;
      border-bottom: 0.6em solid $orange-mid;
      border-left: 0.6em solid rgb(192 104 1 / 50%);
      transform: translateZ(0);
      animation: load 1.1s infinite linear;
    }

    .bullion-loader,
    .bullion-loader::after {
      border-radius: 50%;
      width: 52px;
      height: 52px;
      margin: -13px 0 0 -13px;
    }
  }

  > .cols {
    position: relative;
    float: left;
    width: 15%;
    height: 125px;
    padding: 5px;
    border-right: 1px solid #ccc;

    &.no-border {
      border-right: none;
    }
    
    &.small {
      width: 12%;
      
      @include breakpoint-max(935px) {
        width: 15% !important;
      }
    }

    &.medium {
      width: 13%;

      @include breakpoint-max(850px) {
        width: 20% !important;
      }
    }

    &.wide {
      width: 17%;
      border-right: 0 !important;
      
      @include breakpoint-max(935px) {
        width: 22% !important;
      }
      
      @include breakpoint-max(850px) {
        width: 24% !important;
      }
    }

    &.no-border-right {
      border-right: 0 !important;
    }

    &:last-child {
      width: 28%;
      border-right: 0;
    }
      
    @include breakpoint-max(1200px) {
      width: 20% !important;
      padding: 5px;
    }

    @include breakpoint-max(850px) {
      padding: 5px 2px;
    }

    > .heading {
      font-size: 14px;
      font-weight: bold;
      
      @include breakpoint-max(1000px) {
        font-size: 13px;
      }
      
      @include breakpoint-max(930px) {
        font-size: 12px;
      }
  
      
      @include breakpoint-max(850px) {
        font-size: 11px;
      }
    }

    > .checkbox-one-line {
      width: 100%;
      margin: 0 0 9px;

      &:last-child {
        margin-bottom: 0;

        label {
          margin-bottom: 0;
        }
      }

      input[type='checkbox']:checked + label .bullion-checkbox {
        margin-right: 0;
      }

      .bullion-checkbox {
        margin-right: 0;
        margin-left: 0;
      }

      label {
        .text {
          font-size: 13px;
      
          @include breakpoint-max(1000px) {
            font-size: 12px;
          }
      
          @include breakpoint-max(930px) {
            font-size: 11px;
          }
      
          @include breakpoint-max(850px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  &.jewellery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > .left {
      display: flex;
      justify-content: flex-start;

      > .cols {
        min-width: 100px;
        width: auto;
        height: auto;
        float: none;
        padding: 0 10px;
        border-right: 1px solid #ccc;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }

        > .heading {
          font-size: 14px;
          font-weight: bold;
        }

        > .checkbox-one-line {
          width: 100%;
          margin: 0 0 9px;
    
          &:last-child {
            margin-bottom: 0;
    
            label {
              margin-bottom: 0;
            }
          }
    
          input[type='checkbox']:checked + label .bullion-checkbox {
            margin-right: 0;
          }
    
          .bullion-checkbox {
            margin-right: 0;
            margin-left: 0;
          }
    
          label {
            .text {
              font-size: 13px;
          
              @include breakpoint-max(1000px) {
                font-size: 12px;
              }
          
              @include breakpoint-max(930px) {
                font-size: 11px;
              }
          
              @include breakpoint-max(850px) {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  &.wide {
      
    @include breakpoint-max(1200px) {
      width: 50% !important;
      float: none;
      clear: both;
    }

  }

  > .price-slider-wrap {
    width: 40% !important;
    float: right;
    padding: 5px;

    &.shrink {
      width: 31% !important;
      
      @include breakpoint-max(1200px) {
        width: 50% !important;
        float: none;
        clear: both;
      }
    }
      
    @include breakpoint-max(1200px) {
      width: 50% !important;
      float: none;
      clear: both;
    }
      
    @include breakpoint-max(935px) {
      width: 100% !important;
    }

    > .inputs {
      margin: 10px 0;
      
      > input {
        width: 48%;
        padding: 3px;
        border: 1px solid #ccc;
        border-radius: 5px;

        &:first-child {
          margin-right: 4%;
        }
      }
    }

    > #price-slider {
      margin: 10px 0;
    }

    > .value {
      .amount {
        font-size: 12px;
      }
    }

    > .clear, > .reset {
      text-align: right;

      > a {
        font-size: 12px;
      }
    }
  }

  &.auction-form {
    > .cols {
      height: 150px;

      &.small {
        @include breakpoint-max(935px) {
          width: 20% !important;
        }
      }
    }
  }
}
