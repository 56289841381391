#search-suggestion-nav {
  position: absolute;
  top: 34px;
  left: 0;
  width: 90%;
  max-height: 500px;
  height: auto;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 6;
  overflow-y: scroll;

  @include breakpoint-max(1080px) {
    width: 80%;
  }

  &.home-search {
    top: 33px;
    width: 80%;
  }

  > dl {
    margin: 0;

    > dt {
      padding: 5px 0;
      font-weight: bold;
      font-size: 13px;
    }

    > dd {
      font-size: 13px;

      > a {
        width: 100%;
        display: inline-block;
        padding: 6px 0 3px 10px;
        font-size: 13px;
        color: #000;

        &:hover {
          background: #f7f7f7;
        }
      }
    }
  }

  > p {
    margin: 0;
    font-weight: bold;
    font-size: 13px;

    &.no-result {
      font-weight: normal;
      font-style: italic;
    }
  }
}