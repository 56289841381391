/* Layout */

.wrap-header {
  width: auto;
  margin: 0 auto;
  background: #fff;
  position: relative;
}

.wrap-body {
  width: auto;
  margin: 0 auto;

  &.home {
    background: #f9f9f9;

    @include breakpoint-max(767px) {
      width: 767px;
    }
  }

  > .blogs-header-banner {
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden;

    @include breakpoint-max(767px) {
      width: 767px;
    }

    > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    > .author {
      position: absolute;
      inset: 0;
      max-width: 1200px;
      height: auto;
      margin: 0 auto;
      display: block;

      > img {
        position: absolute;
        bottom: 0;
        right: 15px;
        max-height: 90%;
        width: auto;
      }
    }

    > .content {
      position: absolute;
      width: 1200px;
      height: 100%;
      inset: 0;
      margin: 0 auto;

      > .title {
        position: absolute;
        top: 25%;
        left: 0;
        margin: 0;
        font-size: 36px;
        line-height: 37px;
        font-weight: bold;
        color: #fff;

        @include breakpoint-max(1240px) {
          left: 20px;
        }

        @include breakpoint-max(930px) {
          font-size: 34px;
        }
    
        // @include breakpoint-max(1100px) {
          // top: 15%;
          // font-size: 28px;
        // }
      }

      > .sub-title {
        position: absolute;
        top: 45%;
        left: 0;
        font-size: 16px;
        color: #fff;
        text-align: left;
    
        @include breakpoint-max(1240px) {
          left: 20px;
        }

        @include breakpoint-max(930px) {
          font-size: 14px;
        }
    
        // @include breakpoint-max(1100px) {
        //   top: 35%;
        //   font-size: 14px;
        // }
      }

      > .banner-text {
        height: 100%;
        width: auto;
      }
    }
  }

  > .gold-university-header-banner {
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden;

    @include breakpoint-max(767px) {
      width: 767px;
    }

    > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    > .author {
      position: absolute;
      inset: 0;
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: block;

      > img {
        position: absolute;
        bottom: 0;
        right: 15px;
        max-height: 100%;
        width: auto;

        @include breakpoint-max(1220px) {
          max-height: 95%;
          right: -140px;
        }
      }
    }

    > .content {
      position: absolute;
      width: 1200px;
      height: 100%;
      inset: 0;
      margin: 0 auto;

      > .title {
        position: absolute;
        top: 25%;
        left: 0;
        margin: 0;
        font-size: 36px;
        line-height: 37px;
        font-weight: bold;
        color: #fff;

        @include breakpoint-max(1240px) {
          left: 20px;
        }
        
        @include breakpoint-max(930px) {
          font-size: 34px;
        }
    
        // @include breakpoint-max(1100px) {
        //   top: 15%;
        //   font-size: 28px;
        // }
      }

      > .sub-title {
        position: absolute;
        top: 45%;
        left: 0;
        font-size: 16px;
        color: #fff;
        text-align: left;
    
        @include breakpoint-max(1240px) {
          left: 20px;
        }
        
        @include breakpoint-max(930px) {
          font-size: 14px;
        }
    
        // @include breakpoint-max(1100px) {
        //   top: 35%;
        //   font-size: 14px;
        // }
      }

      > .banner-text {
        height: 100%;
        width: auto;
      }
    }
  }

  > .wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    @include breakpoint-max(1220px) {
      padding: 0 5px;
    }
    // @include breakpoint-max(1210px) {
    //   padding: 0 0 0 5px;
    // }

    &.wrapper-full-width {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    // @include breakpoint-max(1200px) {
    //   width: 100%;
    // }

    @include breakpoint-max(767px) {
      width: 767px;
    }

    > .main-page {
      width: calc(74% - 24px);
      min-height: 1000px;
      float: left;
    
      @include breakpoint-max(988px) {
        width: 70%;
      }
    
      @include breakpoint-max(820px) {
        width: 69%;
      }
    }

    > .page {
      padding: 20px 0;
    
      @include breakpoint-max(1200px) {
        padding: 20px;
      }
    }

    > .page-404 {
      padding: 50px 0 20px;

      > p {
        font-size: 22px;
      }
    }
  }
}
