@mixin tab-body {
  >.tabs {
    margin-bottom: -1px;
    display: flex;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    font-size: 0 !important;

    @include breakpoint-max(1200px) {
      margin-top: 1px;
    }

    >li {
      padding: 10px 24px;
      background: #eee;
      border-top: 3px solid #eee;
      font-size: 18px;
      font-weight: bold;
      color: $orange-mid;
      text-align: center;
      cursor: pointer;
      @include no-select();

      &.active {
        color: #000;
        background: #fff;
        border-top: 3px solid $orange-mid;
        border-bottom: none;
      }
    }
  }

  >.body {
    padding: 20px 24px;

    .opening {
      font-size: 13px;

      >ul {
        >li {
          font-size: 13px;
          line-height: 18px;
        }

      }

      >.buttons {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;

        >a {
          text-align: center;
          font-size: 13px;
          border-radius: 4px;
        }
      }

      >p {
        margin: 0;
        font-size: 13px;
        line-height: 18px;
        margin-top: 15px;
      }

      span {
        white-space: nowrap;
      }

      .note {
        font-size: 11px;
      }
    }
  }

}

.shipping-vault-body {
  position: relative;
  display: flex;

  @include breakpoint-max(1200px) {
    width: 452px;
    margin: 0 auto;
    flex-direction: column;
  }

  >.left {
    width: calc(100% - 400px);
    background: #fff;
    border: 1px solid #f1f1f1;
    position: relative;
    float: none;
    height: auto;

    @include breakpoint-max(1200px) {
      float: none;
      width: 452px;
    }

    >.shipping-vault-tab-wrap {

      @include tab-body;

    }

    >.contact-info {
      display: flex;
      gap: 20px;
      font-size: 13px;
      padding-left: 20px;
      position: inherit;
      bottom: 10px;
      width: 100%;

      >.details-customer {
        display: flex;
        gap: 5px;
        width: 90%;

        >img {
          width: 24px;
          height: 24px;
          margin-top: 5px;
        }

        >.info {
          display: flex;
          flex-direction: column;

          >.main {
            display: flex;
            font-weight: bold;
          }

          >.sub {
            display: flex;
          }
        }
      }

      >.details-opening {
        display: flex;
        gap: 5px;
        width: 100%;

        >.info {
          display: flex;
          flex-direction: column;

          >.main {
            display: flex;
            font-weight: bold;
          }

          >.sub {
            display: flex;
            margin-right: 10px;
          }
        }
      }
    }
  }

  >.left-us {
    width: calc(100% - 400px);
    background: #fff;
    border: 1px solid #f1f1f1;
    position: relative;
    float: none;
    height: auto;

    @include breakpoint-max(1200px) {
      float: none;
      width: 452px;
    }

    >.shipping-vault-tab-wrap {

      @include tab-body;

    }

    >.contact-info-us {
      display: flex;
      gap: 10px;
      font-size: 13px;
      padding-left: 20px;
      position: inherit;
      bottom: 10px;
      width: 100%;

      >.details-customer-us {
        display: flex;
        gap: 5px;
        width: 110%;

        >img {
          width: 24px;
          height: 24px;
          margin-top: 5px;
        }

        >.info {
          display: flex;
          flex-direction: column;

          >.main {
            display: flex;
            font-weight: bold;
          }

          >.sub {
            display: flex;
          }
        }
      }

      >.details-opening-us {
        display: flex;
        gap: 5px;
        width: 110%;

        >.info {
          display: flex;
          flex-direction: column;

          >.main {
            display: flex;
            font-weight: bold;
          }

          >.sub {
            display: flex;
            margin-right: 10px;
          }
        }
      }

    }
  }

  >.right {
    position: relative;
    z-index: 1;
    background: #fff;
    float: none;
    height: auto;

    &.desktop {
      display: block;
    }

    &.tablet {
      display: none;
    }

    @include breakpoint-max(1200px) {
      width: 452px;
      float: none;

      &.desktop {
        display: none;
      }

      &.tablet {
        display: block;
        height: 460px;
      }
    }

    >figure {
      height: 100%;
      width: 100%;
      position: absolute;

      >a {
        display: inline-block;

        >.tab-banner {
          width: 400px;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;

          @include breakpoint-max(1200px) {
            width: 452px;
          }
        }
      }
    }
  }

  >.right-us {
    position: relative;
    z-index: 1;
    background: #fff;
    float: none;
    height: auto;

    &.desktop {
      display: block;
    }

    &.tablet {
      display: none;
    }

    @include breakpoint-max(1200px) {
      width: 452px;
      float: none;

      &.desktop {
        display: none;
      }

      &.tablet {
        display: block;
        height: 460px;
      }
    }

    >figure {
      height: 100%;
      width: 100%;
      position: absolute;

      >a {
        display: inline-block;

        >.tab-banner {
          width: 400px;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          @include breakpoint-max(1200px) {
            width: 452px;
          }
        }
      }
    }
  }
}