/* Variable Declaration */
$orange-light: #DC8500;
$orange-mid: #C06801;
$orange-dark: #A9621E;

$orange-form-line-default: #E3D3C3;
$orange-form-line-active: #FFA500;

$base-gray-light: #f1f2f2;

$font-black: #231f20;
$font-gray: #939598;
$font-green: #39b54a;
$font-red: #ed1c24;
$font-red-dark: #be1e2d;

$bg-color-footer: #f4f4f4;

$standard-font-size-heading: 20px;
$standard-font-size: 16px;

%ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%clearfix {
  content: '';
  display: block;
  clear: both;
}

%disable-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@mixin clickable-image-hover-effect() {
  position: relative;

  &:hover {
    &::before {
      background: radial-gradient(circle,#fff 0,#f4f4f4 100%,#fff 0);
      content: "";
      display: block;
      height: 100%;
      opacity: .05;
      position: absolute;
      width: 100%;
    }
  }
}

@mixin clickable-image-hover-effect-dark() {
  position: relative;

  &:hover {
    &::before {
      background: radial-gradient(circle,#000 0,#fff 100%,#000 0);
      content: "";
      display: block;
      height: 100%;
      opacity: .05;
      position: absolute;
      width: 100%;
    }
  }
}

//Breakpoint for responsive design breaks.
@mixin breakpoint($point) {
  @media (min-width: $point) { @content; }
}

@mixin breakpoint-max($point) {
  @media (max-width: $point) { @content; }
}

@mixin breakpoint-min-max($min, $max) {
  @media (min-width: $min) and (max-width: $max) { @content; }
}

@mixin retina-breakpoint() {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (           min-resolution: 192dpi),
    only screen and (            min-resolution: 2dppx) 
    { @content; }
}

@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

@mixin wrap-long-text($hypen: false) {
  overflow-wrap: break-word;
  word-wrap: break-word;

  @if $hypen == 'true' {
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

@mixin form-sprite-x1($x, $y) {
  background-image: url('/img/m/form-sprite-v5.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin form-sprite-x2($x, $y) {
  background-image: url('/img/m/form-sprite-retina-v5.png');
  background-repeat: no-repeat;
  background-position: $x $y;
  background-size: 147px 131px;
}

@mixin sprite-flags-x1($x, $y) {
  background-image: url('/img/m/flags-icons-default-v5.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin sprite-flags-x2($x, $y) {
  background-image: url('/img/m/flags-icons-retina-v5.png');
  background-repeat: no-repeat;
  background-position: $x $y;
  background-size: 408px 384px;
}

@mixin common-sprite($x, $y) {
  background-image: url('/img/d/common-sprite.png');
  background-repeat: no-repeat;
  background-position: $x $y;
  background-size: 781px 182px;
}

@mixin menu-sprite-x1($x, $y) {
  background-image: url('/img/sprite-menu-default-v2.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin menu-sprite-x2($x, $y) {
  background-image: url('/img/sprite-menu-retina-v2.png');
  background-repeat: no-repeat;
  background-position: $x $y;
  background-size: 611px;
}

@mixin vault-storage-us-sprite-x1($x, $y) {
  background-image: url('/img/vault-storage-us.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin vault-storage-us-sprite-x2($x, $y) {
  background-image: url('/img/vault-storage-us-retina.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin sprite-research-x1($x, $y) {
  background-image: url('/img/d/sprite-research-v2.png');
  background-repeat: no-repeat;
  background-position: $x $y;
} 

@mixin sprite-research-x2($x, $y) {
  background-image: url('/img/d/sprite-research-retina-v2.png');
  background-repeat: no-repeat;
  background-position: $x $y;
  background-size: 459px;
}

@mixin sprite-account-x1($x, $y) {
  background-image: url('/img/d/sprite-account-v2.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin sprite-account-x2($x, $y) {
  background-image: url('/img/d/sprite-account-retina-v2.png');
  background-repeat: no-repeat;
  background-position: $x $y;
  background-size: 528px;
}

@mixin home-search-bg-x1() {
  background-image: url('/img/d/home-search.jpg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 880px 350px;
}

@mixin home-search-bg-x2() {
  background-image: url('/img/d/home-search-x2.jpg');
  background-repeat: no-repeat;
  background-position: 0 0;
}

@mixin vault-storage-nz-sprite-x2($x, $y) {
  background-image: url('/img/vault-storage_NZ-retina.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin vault-storage-nz-sprite-x1($x, $y) {
  background-image: url('/img/vault-storage_NZ.png');
  background-repeat: no-repeat;
  background-position: $x $y;
}

@mixin skeleton-shiny($angle: 0, $darkerShade: #ebebeb, $ligherShade: #f9f9f9) {
  background: linear-gradient($angle, $darkerShade 0%, $ligherShade 30%, $darkerShade 60%);
  background-size: 200% 200%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position: -200% -200%;
  }
}

@mixin skeleton-solid($bg: #ebebeb) {
  background-color: #ebebeb;
}

.preload-icons-menu {
  // @extend %icon-menu-preload;
  width: 1px;
  height: 1px;
  @include menu-sprite-x1(0, 0);
  @include retina-breakpoint() {
    @include menu-sprite-x2(0, 0);
  }
}

.no-scroll {
  overflow: hidden;
}

.zoomContainer {
  z-index: 7;
}

.strikethrough {
  text-decoration: line-through;
}

.nowrap {
  white-space: nowrap;
}

.btn-close-popup {
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

.btn-disabled {
  background: #ccc !important;
  border: 1px solid #ccc !important;
  pointer-events: none;
  cursor: not-allowed;
}

.no-margin {
  margin: 0 !important;
}