.banners {
  .banner-target {
    a {
      color: #333;
    }
  }

  .extra {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-spread {
    position: relative;

    .spread-box {
      position: absolute;
      bottom: 10%;
      left: 27%;
      width: 45%;
      padding: 10px 10px 30px;
      
      @include breakpoint-max(1030px) {
        bottom: 8%;
      }

      @include breakpoint-max(960px) {
        bottom: 5%;
      }
      
      @include breakpoint-max(830px) {
        bottom: 5%;
      }

      @include breakpoint-max(790px) {
        bottom: 3%;
      }
    

      p {
        font-weight: bold;
        font-size: 14px;
      
        @include breakpoint-max(1185px) {
          font-size: 13px;
          margin-bottom: 2px;
        }
      
        @include breakpoint-max(1090px) {
          margin-top: 2px;
          font-size: 12px;
          margin-bottom: 2px;
        }
      
        @include breakpoint-max(990px) {
          margin-top: 0;
          font-size: 11px;
          margin-bottom: 2px;
        }
      
        @include breakpoint-max(865px) {
          font-size: 10px;
          margin-bottom: 2px;
        }
      }

      .amount {
        color: $orange-light;
        font-size: 16px;
        font-weight: bold;
      
        @include breakpoint-max(1185px) {
          font-size: 15px;
        }
      
        @include breakpoint-max(1090px) {
          font-size: 14px;
        }
      
        @include breakpoint-max(990px) {
          font-size: 12px;
        }
      
        @include breakpoint-max(865px) {
          font-size: 11px;
        }

        @include breakpoint-max(830px) {
          font-size: 11px;
        }
      
        @include breakpoint-max(820px) {
          font-size: 11px;
        }
      
        @include breakpoint-max(790px) {
          font-size: 10px;
        }
      }

      > .middle {
        position: absolute;
        top: 35px;
        left: 29%;
        text-align: center;
      
        @include breakpoint-max(1185px) {
          left: 28%;
          top: 28px;
        }
      
        @include breakpoint-max(1030px) {
          top: 28px;
          left: 27.5%;
        }
      
        @include breakpoint-max(990px) {
          left: 28.5%;
          top: 26px;
        }
      
        @include breakpoint-max(930px) {
          left: 26.5%;
          top: 23px;
        }

        @include breakpoint-max(900px) {
          left: 25.5%;
          top: 23px;
        }
      
        @include breakpoint-max(875px) {
          left: 25.5%;
          top: 26px;
        }
      
        @include breakpoint-max(865px) {
          left: 25.5%;
          top: 26px;
        }
      
        @include breakpoint-max(830px) {
          left: 25.5%;
          top: 26px;
        }
      
        @include breakpoint-max(790px) {
          left: 23%;
          top: 26px;
        }

        > .arrow-left {
          position: absolute;
          top: 15px;
          left: 25px;
      
          @include breakpoint-max(1100px) {
            left: 26px;
          }
      
          @include breakpoint-max(1100px) {
            top: 16px;
          }
      
          @include breakpoint-max(990px) {
            left: 28px;
          }
      
          @include breakpoint-max(865px) {
            left: 24px;
          }
      
          @include breakpoint-max(830px) {
            left: 25px;
          }

          @include breakpoint-max(790px) {
            left: 28px;
          }
        }

        > .arrow-left::after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0%);
          border-right-color: $orange-light;
          border-width: 12px;
          margin-top: -12px;
      
          @include breakpoint-max(1185px) {
            border-width: 10px;
          }
      
          @include breakpoint-max(1090px) {
            border-width: 9px;
          }
      
          @include breakpoint-max(990px) {
            border-width: 8px;
          }
        }

        > .arrow-right {
          position: absolute;
          top: 15px;
          right: 0;
      
          @include breakpoint-max(1185px) {
            right: 5px;
          }
      
          @include breakpoint-max(1100px) {
            top: 16px;
          }
      
          @include breakpoint-max(1000px) {
            right: 7px;
          }
      
          @include breakpoint-max(990px) {
            right: 12px;
          }
      
          @include breakpoint-max(865px) {
            right: 6px;
          }
      
          @include breakpoint-max(830px) {
            right: 9px;
          }

          @include breakpoint-max(790px) {
            right: 10px;
          }
        }

        > .arrow-right::after {
          right: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0%);
          border-left-color: $orange-light;
          border-width: 12px;
          margin-top: -12px;
      
          @include breakpoint-max(1185px) {
            border-width: 10px;
          }
      
          @include breakpoint-max(1090px) {
            border-width: 9px;
          }
      
          @include breakpoint-max(990px) {
            border-width: 8px;
          }
        }

        > .spread {
          margin-bottom: 0;
          font-size: 17px;
          font-weight: bold;
          letter-spacing: -0.3px;
      
          @include breakpoint-max(1185px) {
            font-size: 15px;
          }
      
          @include breakpoint-max(1090px) {
            font-size: 14px;
          }
      
          @include breakpoint-max(1030px) {
            font-size: 14px;
          }
      
          @include breakpoint-max(990px) {
            margin-top: 5px;
            font-size: 12px;
          }
      
          @include breakpoint-max(865px) {
            margin-top: 4px;
            font-size: 11px;
          }

          @include breakpoint-max(830px) {
            font-size: 11px;
          }
      
          @include breakpoint-max(820px) {
            font-size: 11px;
          }
      
          @include breakpoint-max(790px) {
            margin-top: 5px;
            font-size: 10px;
          }

          > .red {
            color: red;
          }
        }

        > p {
          margin: 4px 0 0;
          font-weight: normal;
          font-size: 12px;
          color: #8E521F;
      
          @include breakpoint-max(1090px) {
            margin-top: 3px;
            font-size: 11px;
          }
      
          @include breakpoint-max(990px) {
            font-size: 10px;
          }
      
          @include breakpoint-max(865px) {
            font-size: 9px;
          }
        }
      }
    }
  }

  .sell {
    position: relative;

    #sellScrap {
      position: absolute;
      bottom: 7.5%;
      left: 48%;
      width: 20%;
      height: 12%;
    }
  }

  .banner-home-item {
    position: relative;
    width: 100%;
    overflow: hidden;
    aspect-ratio: 864 / 297;

    > a, > span {
      position: relative;
      display: block;

      > img {
        width: 100%;
        height: auto;
      }

      > img.text {
        position: absolute;
        top: 0;
        left: 0;
      }

      >object {
        width: 100%;
      }

      > object.text {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }

    > a {
      @include clickable-image-hover-effect-dark();
    }
  }

  .welcome-to-bullionstar {
    position: relative;
    overflow: visible;
    z-index: 2;
    cursor: pointer;

    > .welcome-expanded {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background: lightgrey;
      display: flex;
      cursor: auto;
      background: url('/img/d/welcome-expanded-bg-v2.webp');
      background-size: cover;
      box-shadow: 0px 97px 62px -62px rgba(0, 0, 0, 0.4);

      > .gallery {
        display: flex;
        flex-direction: column;
        width: 43%;
        
        > .logo {
          text-align: center;
          padding: 30px 0 20px;
        }

        > .big-thumb {
          width: 100%;
          height: auto;
        }

        > .thumbnails {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 10px;

          > img {
            width: calc(50% - 3px);
            height: auto;
          }
        }
      }

      > .text {
        flex: 1;
        padding: 20px 25px 15px;
          
        @include breakpoint-max(1230px) {
          padding: 20px 15px 30px;
        }

        > h2 {
          font-size: 16px;
          
          @include breakpoint-max(1030px) {
            font-size: 15px;
          }
        }

        > p {
          font-size: 15px;
          margin-bottom: 15px;
          
          @include breakpoint-max(1230px) {
            font-size: 14px;
            margin-bottom: 10px;
          }
          
          @include breakpoint-max(1030px) {
            font-size: 13px;
          }

          a {
            color: $orange-light;
          }
        }
      }

      > .close-expanded-banner {
        position: absolute;
        right: 12px;
        bottom: 12px;
        cursor: pointer;
      }
    }
  }
}