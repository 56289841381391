.agree-checkbox-wrap {
  margin: 20px 0;

  > .checkbox-one-line {
    > label {
      font-weight: normal;
      font-size: 14px;

      > a {
        &:hover {
          color: $orange-light;
        }
      }
    }
  }
}
