
%icon-google-maps {
  width: 48px;
  height: 48px;
  display: inline-block;
  background-image: url('/img/d/google-maps-2.svg');
  background-repeat: no-repeat;
  background-size: 48px 48px;
}

%icon-check {
  width: 14px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/d/check.svg');
  background-repeat: no-repeat;
  background-size: 14px 15px;
}

%icon-check-small {
  width: 11px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/check.svg');
  background-repeat: no-repeat;
  background-size: 11px 12px;
}

%icon-i-square-info {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/d/i-square-icon.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

%icon-i-circle-info {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/d/info-i-round.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

%icon-valuation-toggle {
  width: 18px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/toggle.svg');
  background-repeat: no-repeat;
  background-size: 18px 19px;
}

%icon-calendar {
  width: 19px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/calendar.svg');
  background-repeat: no-repeat;
  background-size: 19px 20px;
}

%icon-header-search-magnifier {
  width: 13px;
  height: 14px;
  display: inline-block;
  background-image: url('/img/d/top-magnifier.svg');
  background-repeat: no-repeat;
  background-size: 13px 14px;
}

%icon-magnifier {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/magnifier.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%icon-cart {
  width: 35px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/top-cart.svg');
  background-repeat: no-repeat;
  background-size: 35px 20px;
}

%icon-arrow-head-down-black {
  width: 9px;
  height: 6px;
  display: inline-block;
  background-image: url('/img/d/arrow-head-down-black.svg');
  background-repeat: no-repeat;
  background-size: 9px 6px;
}

%icon-download {
  width: 28px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/d/download.svg');
  background-repeat: no-repeat;
  background-size: 28px 28px;
}

%icon-plus {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/d/plus.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

%icon-minus {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/d/minus.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

%icon-close {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/m/icon-close.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

%icon-news-bs-logo {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/bstar.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-news-gc-logo {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/news-gc.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-admin {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/admin.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-trashcan {
  width: 12px;
  height: 16px;
  display: inline-block;
  background-image: url('/img/d/trashcan.svg');
  background-repeat: no-repeat;
  background-size: 12px 16px;
}

%img-swipe-right-arrow {
  width: 33px;
  height: 33px;
  display: inline-block;
  background-image: url('/img/d/slide-right.svg');
  background-repeat: no-repeat;
  background-size: 33px 33px;
}

%img-swipe-left-arrow {
  width: 33px;
  height: 33px;
  display: inline-block;
  background-image: url('/img/d/slide-left.svg');
  background-repeat: no-repeat;
  background-size: 33px 33px;
}


/*********************************************************************************/
/*********************************************************************************/
                              /* Main Menu Background */
/*********************************************************************************/
/*********************************************************************************/

%bg-menu-text-gold {
  display: inline-block;
  background-image: url('/img/d/menu-gold.svg');
  background-repeat: no-repeat;
  background-size: 423px 584px;
}

%bg-menu-text-silver {
  display: inline-block;
  background-image: url('/img/d/menu-silver.svg');
  background-repeat: no-repeat;
  background-size: 423px 584px;
}

%bg-menu-text-platinum {
  display: inline-block;
  background-image: url('/img/d/menu-platinum.svg');
  background-repeat: no-repeat;
  background-size: 423px 584px;
}

%bg-menu-gold {
  display: inline-block;
  background-image: url('/img/d/bg-menu-gold.jpg');
  background-repeat: no-repeat;
  background-size: 423px 584px;
}

%bg-menu-silver {
  display: inline-block;
  background-image: url('/img/d/bg-menu-silver.jpg');
  background-repeat: no-repeat;
  background-size: 423px 584px;
}

%bg-menu-platinum {
  display: inline-block;
  background-image: url('/img/d/bg-menu-platinum.jpg');
  background-repeat: no-repeat;
  background-size: 423px 584px;
}

/*********************************************************************************/
/*********************************************************************************/
                              /* Account Details Icons */
/*********************************************************************************/
/*********************************************************************************/

%icon-account-details-info {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-info.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-info-selected {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-info-selected.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-2fa {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-2fa.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-2fa-selected {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-2fa-selected.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-password {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-password.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-password-selected {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-password-selected.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-verification {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-verification.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-verification-selected {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-verification-selected.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-upload {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-upload.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-upload-selected {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-upload-selected.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-auction {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-auction.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-account-details-auction-selected {
  width: 58px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/account-details-auction-selected.svg');
  background-repeat: no-repeat;
  background-size: 58px 58px;
}

%icon-page-prev {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url('/img/d/product-prev.svg');
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

%icon-page-next {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url('/img/d/product-next.svg');
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

/*********************************************************************************/
/*********************************************************************************/
                              /* Main Menu Icons */
/*********************************************************************************/
/*********************************************************************************/

%icon-top-vault {
  width: 29px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/d/top-vault.svg');
  background-repeat: no-repeat;
  background-size: 29px 28px;
}

%icon-top-retail {
  width: 24px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/d/top-retail.svg');
  background-repeat: no-repeat;
  background-size: 24px 28px;
}

%icon-top-contact {
  width: 24px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/d/top-contact.svg');
  background-repeat: no-repeat;
  background-size: 24px 28px;
}

%icon-top-help {
  width: 16px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/d/top-help.svg');
  background-repeat: no-repeat;
  background-size: 16px 28px;
}

%icon-arrow-head-right-orange {
  width: 6px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/menu-arrow-right.svg');
  background-repeat: no-repeat;
  background-size: 6px 12px;
}

/*********************************************************************************/
/*********************************************************************************/
                    /* Sprite Buy Gold & Silver Menu Icons */
/*********************************************************************************/
/*********************************************************************************/

%icon-bullion-savings-buy {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-1px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-1px, -8px);
  }
}

%icon-available-products {
  width: 34px;
  height: 33px;
  display: inline-block;
  @include menu-sprite-x1(-280px, -400px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-280px, -400px);
  }
}

%icon-gold {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-56px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-56px, -8px);
  }
}

%icon-all-gold {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-500px, -175px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-500px, -175px);
  }
}

%icon-gold-bar {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-556px, -175px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-556px, -175px);
  }
}

%icon-gold-coin {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-1px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-1px, -230px);
  }
}

%icon-gold-numismatic {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-57px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-57px, -230px);
  }
}

%icon-gold-jewellery {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-112px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-112px, -230px);
  }
}

%icon-silver {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-112px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-112px, -8px);
  }
}

%icon-all-silver {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-167px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-167px, -230px);
  }
}

%icon-silver-bar {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-223px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-223px, -230px);
  }
}

%icon-silver-coin {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-279px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-279px, -230px);
  }
}

%icon-silver-numismatic {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-334px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-334px, -230px);
  }
}

%icon-platinum {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-167px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-167px, -8px);
  }
}

%icon-platinum-bar {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-334px, -397px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-334px, -397px);
  }
}

%icon-platinum-coin {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-390px, -397px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-390px, -397px);
  }
}

%icon-palladium {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-167px, -342px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-167px, -342px);
  }
}

%icon-copper {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-446px, -454px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-446px, -454px);
  }
}

%icon-research {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-334px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-334px, -8px);
  }
}

%icon-coin-supplies {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-223px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-223px, -8px);
  }
}

%icon-sell-to-us {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-278px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-278px, -8px);
  }
}

%icon-bullionstar-perspective {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-167px, -64px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-167px, -64px);
  }
}

%icon-chart {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-389px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-389px, -8px);
  }
}

%icon-market-alerts {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-445px, -230px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-445px, -230px);
  }
}

%icon-shop {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-445px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-445px, -8px);
  }
}

%icon-help {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-500px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-500px, -8px);
  }
}

%icon-about {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-556px, -8px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-556px, -8px);
  }
}

%icon-contact {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-1px, -64px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-1px, -64px);
  }
}

%icon-add-funds-cash-balance {
  width: 34px;
  height: 30px;
  display: inline-block;
  @include menu-sprite-x1(-289px, -124px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-289px, -124px);
  }
}

%icon-withdraw-funds-cash-balance {
  width: 50px;
  height: 30px;
  display: inline-block;
  @include menu-sprite-x1(-334px, -124px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-334px, -124px);
  }
}

%icon-chinese-gold-market {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-167px, -120px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-167px, -120px);
  }
}

%icon-charts-data {
  width: 34px;
  height: 29px;
  display: inline-block;
  @include menu-sprite-x1(-233px, -290px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-233px, -290px);
  }
}

%icon-gold-price-data {
  width: 36px;
  height: 37px;
  display: inline-block;
  @include menu-sprite-x1(-289px, -287px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-289px, -287px);
  }
}

%icon-silver-price-data {
  width: 36px;
  height: 37px;
  display: inline-block;
  @include menu-sprite-x1(-343px, -286px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-343px, -286px);
  }
}

%icon-market-alerts-small {
  width: 36px;
  height: 37px;
  display: inline-block;
  @include menu-sprite-x1(-232px, -344px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-232px, -344px);
  }
}

%icon-why-gold {
  width: 29px;
  height: 24px;
  display: inline-block;
  @include menu-sprite-x1(-291px, -350px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-291px, -350px);
  }
}

%icon-why-silver {
  width: 27px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-347px, -350px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-347px, -350px);
  }
}

%icon-vault-sg {
  width: 29px;
  height: 25px;
  display: inline-block;
  @include menu-sprite-x1(-567px, -348px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-567px, -348px);
  }
}

%icon-vault-nz {
  width: 29px;
  height: 25px;
  display: inline-block;
  @include menu-sprite-x1(-13px, -403px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-13px, -403px);
  }
}

%icon-vault-us {
  width: 29px;
  height: 25px;
  display: inline-block;
  @include menu-sprite-x1(-179px, -459px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-179px, -459px);
  }
}

%icon-shipping-sg {
  width: 35px;
  height: 25px;
  display: inline-block;
  @include menu-sprite-x1(-398px, -459px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-398px, -459px);
  }
}

%icon-shipping-us {
  width: 33px;
  height: 25px;
  display: inline-block;
  @include menu-sprite-x1(-233px, -459px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-233px, -459px);
  }
}

%icon-sales-taxes {
  width: 33px;
  height: 25px;
  display: inline-block;
  @include menu-sprite-x1(-290px, -459px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-290px, -459px);
  }
}

%icon-vault-storage-us-intro {
  width: 90px;
  height: 90px;
  display: inline-block;
  @include vault-storage-us-sprite-x2(-25px, -295px);
  background-size: 700px;
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-25px, -295px);
  }
}

%icon-vault-storage-us-intro-online {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-26px, -153px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-26px, -153px);
  }
}

%icon-vault-storage-us-overview {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 750px;
  @include vault-storage-us-sprite-x2(-27px, -24px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-27px, -24px);
  }
}

%icon-vault-storage-us-one-year {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-127px, -15px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-127px, -15px);
  }
}

%icon-vault-storage-us-online {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-26px, -147px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-26px, -147px);
  }
}

%icon-vault-storage-us-legal {
  width: 90px;
  height: 100px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-243px, -10px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-243px, -10px);
  }
}

%icon-vault-storage-us-audits {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-466px, -18px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-466px, -18px);
  }
}

%icon-vault-storage-us-insurance {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-355px, -21px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-355px, -21px);
  }
}

%icon-vault-storage-us-withdrawals {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 650px;
  @include vault-storage-us-sprite-x2(-544px, -16px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-544px, -16px);
  }
}

%icon-vault-storage-us-bsp {
  width: 90px;
  height: 100px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-254px, -137px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-254px, -137px);
  }
}

%icon-vault-storage-us-security {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 800px;
  @include vault-storage-us-sprite-x2(-420px, -169px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-420px, -169px);
  }
}

%icon-vault-storage-us-pictures {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-us-sprite-x2(-133px, -150px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-133px, -150px);
  }
}

%icon-vault-storage-us-certificate {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 750px;
  @include vault-storage-us-sprite-x2(-625px, -156px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-625px, -156px);
  }
}

%icon-vault-storage-us-fee {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 750px;
  @include vault-storage-us-sprite-x2(-496px, -160px);
  @include retina-breakpoint() {
    @include vault-storage-us-sprite-x2(-496px, -160px);
  }
}

%icon-vault-storage-nz-intro {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-nz-sprite-x2(-26px, -279px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-26px, -279px);
  }
}

%icon-vault-storage-nz-online-intro {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-nz-sprite-x2(-468px, -291px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-468px, -291px);
  }
}

%icon-vault-storage-nz-online {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 673px;
  @include vault-storage-nz-sprite-x2(-25px, -141px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-25px, -141px);
  }
}

%icon-vault-storage-nz-overview {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 750px;
  @include vault-storage-nz-sprite-x2(-143px, -298px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-143px, -298px);
  }
}

%icon-vault-storage-nz-online-management {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-nz-sprite-x2(-190px, -375px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-190px, -375px);
  }
}

%icon-vault-storage-nz-insurance {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 687px;
  @include vault-storage-nz-sprite-x2(-354px, -20px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-354px, -20px);
  }
}

%icon-vault-storage-nz-bullion-withdrawals {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 620px;
  @include vault-storage-nz-sprite-x2(-519px, -17px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-519px, -17px);
  }
}

%icon-vault-storage-nz-bullion-savings {
  width: 90px;
  height: 100px;
  display: inline-block;
  background-size: 680px;
  @include vault-storage-nz-sprite-x2(-246px, -132px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-246px, -132px);
  }
}

%icon-vault-storage-nz-vault-security {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 800px;
  @include vault-storage-nz-sprite-x2(-417px, -172px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-417px, -172px);
  }
}

%icon-vault-storage-nz-legal-ownership {
  width: 90px;
  height: 100px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-nz-sprite-x2(-240px, -13px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-240px, -13px);
  }
}

%icon-vault-storage-nz-bullion-audits {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 675px;
  @include vault-storage-nz-sprite-x2(-448px, -22px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-448px, -22px);
  }
}

%icon-vault-storage-nz-pictures-bullion {
  width: 90px;
  height: 100px;
  display: inline-block;
  background-size: 680px;
  @include vault-storage-nz-sprite-x2(-130px, -142px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-130px, -142px);
  }
}

%icon-vault-storage-nz-vault-certificate {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 750px;
  @include vault-storage-nz-sprite-x2(-627px, -157px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-627px, -157px);
  }
}

%icon-vault-storage-nz-fee-schedule {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-size: 700px;
  @include vault-storage-nz-sprite-x2(-465px, -147px);
  @include retina-breakpoint() {
    @include vault-storage-nz-sprite-x2(-465px, -147px);
  }
}


%icon-101 {
  width: 28px;
  height: 30px;
  display: inline-block;
  @include menu-sprite-x1(-69px, -401px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-69px, -401px);
  }
}

%icon-helicopter {
  width: 36px;
  height: 18px;
  display: inline-block;
  background-image: url('/img/icon-helicopter-v2.svg');
  background-repeat: no-repeat;
  background-size: 36px 18px;
}

%icon-sdb {
  width: 34px;
  height: 29px;
  display: inline-block;
  @include menu-sprite-x1(-455px, -402px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-455px, -401px);
  }
}

%icon-jewellery {
  width: 53px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-501px, -399px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-501px, -399px);
  }
}

%icon-shop-small {
  width: 53px;
  height: 28px;
  display: inline-block;
  @include menu-sprite-x1(-64px, -459px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-64px, -459px);
  }
}

%icon-360 {
  width: 53px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-562px, -407px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-562px, -407px);
  }
}

%icon-400-oz {
  width: 53px;
  height: 29px;
  display: inline-block;
  @include menu-sprite-x1(-8px, -459px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-8px, -459px);
  }
}

%icon-silver-wafer {
  width: 53px;
  height: 34px;
  display: inline-block;
  @include menu-sprite-x1(-112px, -454px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-112px, -454px);
  }
}

%icon-auction {
  width: 54px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-56px, -511px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-56px, -511px);
  }
}

%icon-media-kit {
  width: 54px;
  height: 39px;
  display: inline-block;
  @include menu-sprite-x1(-112px, -508px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-112px, -508px);
  }
}

/*********************************************************************************/
/*********************************************************************************/
                              /* Sprite Account Icons */
/*********************************************************************************/
/*********************************************************************************/

%icon-bullion-savings {
  width: 26px;
  height: 33px;
  display: inline-block;
  @include sprite-account-x1(-59px, -152px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-59px, -152px);
  }
}

%icon-cash-bullion-balance {
  width: 29px;
  height: 33px;
  display: inline-block;
  @include sprite-account-x1(-202px, -104px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-202px, -104px);
  }
}

%icon-sell-stored-bullion {
  width: 36px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/icon-sell-bullion-v2.svg');
  background-repeat: no-repeat;
  background-size: 36px 28px;
}

%icon-withdraw-bullion {
  width: 36px;
  height: 28px;
  display: inline-block;
  background-image: url('/img/icon-withdraw-bullion-v2.svg');
  background-repeat: no-repeat;
  background-size: 36px 28px;
}

%icon-withdraw-bullion-bsp {
  width: 38px;
  height: 31px;
  display: inline-block;
  @include sprite-account-x1(-437px, -105px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-437px, -105px);
  }
}

%icon-add-funds {
  width: 32px;
  height: 25px;
  display: inline-block;
  @include sprite-account-x1(-250px, -107px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-290px, -107px);
  }
}

%icon-withdraw-funds {
  width: 45px;
  height: 27px;
  display: inline-block;
  @include sprite-account-x1(-290px, -107px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-290px, -107px);
  }
}

%icon-transactions {
  width: 28px;
  height: 25px;
  display: inline-block;
  @include sprite-account-x1(-298px, -156px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-298px, -156px);
  }
}

%icon-vault-certificate {
  width: 22px;
  height: 33px;
  display: inline-block;
  @include sprite-account-x1(-493px, -104px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-493px, -104px);
  }
}

%icon-photo-stored-bullion {
  width: 23px;
  height: 23px;
  display: inline-block;
  @include sprite-account-x1(-13px, -157px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-13px, -157px);
  }
}

%icon-live-audit-report {
  width: 32px;
  height: 31px;
  display: inline-block;
  @include sprite-account-x1(-104px, -153px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-104px, -153px);
  }
}

%icon-audit-protocols {
  width: 30px;
  height: 25px;
  display: inline-block;
  @include sprite-account-x1(-153px, -156px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-153px, -156px);
  }
}

%icon-insurance-certificate {
  width: 31px;
  height: 31px;
  display: inline-block;
  @include sprite-account-x1(-200px, -152px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-200px, -152px);
  }
}

%icon-storage-charges {
  width: 32px;
  height: 29px;
  display: inline-block;
  @include sprite-account-x1(-248px, -154px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-248px, -154px);
  }
}

%icon-account-details {
  width: 19px;
  height: 22px;
  display: inline-block;
  @include sprite-account-x1(-351px, -157px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-351px, -157px);
  }
}

%icon-account-api {
  width: 24px;
  height: 22px;
  display: inline-block;
  background-image: url('/img/icon_account_api.svg');
}

%icon-affiliate-program {
  width: 31px;
  height: 22px;
  display: inline-block;
  @include sprite-account-x1(-393px, -157px);
  @include retina-breakpoint() {
    @include sprite-account-x2(-393px, -157px);
  }
}

/*********************************************************************************/
/*********************************************************************************/
                              /* Sprite Bullion Research Icons */
/*********************************************************************************/
/*********************************************************************************/

%icon-blog {
  width: 20px;
  height: 20px;
  display: inline-block;
  @include sprite-research-x1(-178px, -53px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-178px, -53px);
  }
}

%icon-blogs {
  width: 23px;
  height: 19px;
  display: inline-block;
  @include sprite-research-x1(-10px, -220px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-10px, -220px);
  }
}

%icon-bullionstar-prices {
  width: 34px;
  height: 28px;
  display: inline-block;
  @include menu-sprite-x1(-566px, -458px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-566px, -458px);
  }
}

%icon-gold-university {
  width: 28px;
  height: 19px;
  display: inline-block;
  @include sprite-research-x1(-90px, -54px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-90px, -54px);
  }
}

%icon-central-bank-gold {
  width: 19px;
  height: 21px;
  display: inline-block;
  @include sprite-research-x1(-95px, -94px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-95px, -94px);
  }
}

%icon-chinese-essentials {
  width: 35px;
  height: 26px;
  display: inline-block;
  @include sprite-research-x1(-379px, -175px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-379px, -175px);
  }
}

%icon-video {
  width: 25px;
  height: 20px;
  display: inline-block;
  @include sprite-research-x1(-426px, -178px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-426px, -178px);
  }
}

%icon-gold-market-mechanics {
  width: 25px;
  height: 22px;
  display: inline-block;
  @include sprite-research-x1(-9px, -93px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-9px, -93px);
  }
}

%icon-gold-industry-associations {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-research-x1(-52px, -92px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-52px, -92px);
  }
}

%icon-refineries {
  width: 23px;
  height: 24px;
  display: inline-block;
  @include sprite-research-x1(-427px, -51px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-427px, -51px);
  }
}

%icon-market-place {
  width: 25px;
  height: 21px;
  display: inline-block;
  @include sprite-research-x1(-217px, -52px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-217px, -52px);
  }
}

%icon-vaults {
  width: 27px;
  height: 23px;
  display: inline-block;
  @include sprite-research-x1(-259px, -51px);
  @include retina-breakpoint() {
    @include sprite-research-x2(-259px, -51px);
  }
}

%icon-bullion-directory {
  width: 31px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-180px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-180px, -518px);
  }
}

%icon-bullion-dealers {
  width: 31px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-234px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-234px, -518px);
  }
}

%icon-miners-refiners {
  width: 30px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-290px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-290px, -518px);
  }
}

%icon-american-bullion-coins {
  width: 31px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-346px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-346px, -518px);
  }
}

%icon-depositories-shipping {
  width: 29px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-402px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-402px, -518px);
  }
}

%icon-exchanges-etfs {
  width: 28px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-459px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-459px, -518px);
  }
}

%icon-information-resources {
  width: 24px;
  height: 23px;
  display: inline-block;
  @include menu-sprite-x1(-516px, -518px);
  @include retina-breakpoint() {
    @include menu-sprite-x2(-516px, -518px);
  }
}

%icon-logout {
  width: 16px;
  height: 18px;
  display: inline-block;
  background-image: url('/img/d/logout.svg');
  background-repeat: no-repeat;
  background-size: 16px 18px;
}

%icon-login-lock {
  width: 18px;
  height: 21px;
  display: inline-block;
  background-image: url('/img/d/login.svg');
  background-repeat: no-repeat;
  background-size: 18px 21px;
}

%icon-clock {
  width: 35px;
  height: 33px;
  display: inline-block;
  background-image: url('/img/d/clock.svg');
  background-repeat: no-repeat;
  background-size: 35px 33px;
}

%icon-cart-white {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/cart.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-fb-star {
  width: 81px;
  height: 30px;
  display: inline-block;
  background-image: url('/img/d/review-facebook-star.svg');
  background-repeat: no-repeat;
  background-size: 81px 30px;
}

%icon-google-star-orange-filled {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/review-google-star-filled.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-google-star-orange {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/review-google-star.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-fb-star-blue-filled {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/review-fb-star-filled.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-br-star-filled {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/review-br-star-filled.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-br-star {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url('/img/d/review-br-star.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-tripadvisor-rating {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(/img/d/tripadvisor-notfilled.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-tripadvisor-rating-half {
  background-image: url(/img/d/review-tripadvisor-star-half.svg);
}

%icon-tripadvisor-rating-full {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(/img/d/review-tripadvisor-star-filled.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-facebook-rating-half {
  background-image: url(/img/d/review-fb-star-half.svg);
}

%icon-facebook-rating-full {
  background-image: url(/img/d/review-fb-star-filled.svg);
}

%icon-trustpilot-rating {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(/img/d/review-trustpilot-star-notfilled.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

%icon-trustpilot-rating-half {
  background-image: url(/img/d/review-trustpilot-star-half.svg);
}

%icon-trustpilot-rating-full {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(/img/d/review-trustpilot-star-filled.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
}

/*********************************************************************************/
/*********************************************************************************/
                                /* Logo Us Icons */
/*********************************************************************************/
/*********************************************************************************/

%logo-facebook {
  width: 150px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/facebook-reviews1.svg');
  background-repeat: no-repeat;
  background-size: 150px 58px;
}

%logo-google {
  width: 150px;
  height: 50px;
  display: inline-block;
  background-image: url('/img/d/google-reviews1.svg');
  background-repeat: no-repeat;
  background-size: 150px 50px;
}

%logo-bullion {
  width: 334px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/bullionreviews.svg');
  background-repeat: no-repeat;
  background-size: 334px 58px;
}

%icon-top-logo {
  width: 334px;
  height: 58px;
  display: inline-block;
  background-image: url('/img/d/top-logo.svg');
  background-repeat: no-repeat;
  background-size: 334px 58px;
}

%icon-footer-facebook {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-facebook.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-footer-twitter {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-x-v2.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-footer-instagram {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-instagram.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-footer-google {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-google-plus.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-footer-youtube {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-youtube-v2.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-footer-rss {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-rss.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%icon-footer-telegram {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/icon-telegram.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

/*********************************************************************************/
/*********************************************************************************/
                                  /* Contact Icons */
/*********************************************************************************/
/*********************************************************************************/

%contact-phone {
  width: 56px;
  height: 64px;
  display: inline-block;
  background-image: url('/img/d/contact-phone.svg');
  background-repeat: no-repeat;
  background-size: 56px 64px;
}

%contact-sms {
  width: 50px;
  height: 64px;
  display: inline-block;
  background-image: url('/img/d/contact-sms.svg');
  background-repeat: no-repeat;
  background-size: 50px 64px;
}

%contact-email {
  width: 58px;
  height: 42px;
  display: inline-block;
  background-image: url('/img/d/icon-email.svg');
  background-repeat: no-repeat;
  background-size: 58px 42px;
}

/*********************************************************************************/
/*********************************************************************************/
                      /* Arrow sprite mapping collection */
/*********************************************************************************/
/*********************************************************************************/

%arrow-head-up-svg {
  width: 12px;
  height: 7px;
  display: inline-block;
  background-image: url('/img/d/arrow-head-up.svg');
  background-repeat: no-repeat;
  background-size: 12px 7px;
}

%arrow-head-down-svg {
  width: 12px;
  height: 7px;
  display: inline-block;
  background-image: url('/img/d/arrow-head-down.svg');
  background-repeat: no-repeat;
  background-size: 12px 7px;
}

%arrow-head-down-white-svg {
  width: 10px;
  height: 6px;
  display: inline-block;
  background-image: url('/img/d/menu-arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 10px 6px;
}

/* %arrow-head-up {
  width: 12px;
  height: 7px;
  display: inline-block;
  @include common-sprite(-624px, -101px);
}

%arrow-head-down {
  width: 12px;
  height: 7px;
  display: inline-block;
  @include common-sprite(-606px, -101px);
} */
/*********************************************************************************/
/*********************************************************************************/
                      /* Radio sprite mapping collection */
/*********************************************************************************/
/*********************************************************************************/

%chbox-default {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/checkbox-unchecked.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%chbox-active {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/checkbox-checked.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%rdo-default {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/checkbox-off.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%rdo-active {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/checkbox-on.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

/*********************************************************************************/
/*********************************************************************************/
                                  /* Form Icons */
/*********************************************************************************/
/*********************************************************************************/

%icon-form-check {
  width: 12px;
  height: 10px;
  display: inline-block;
  background-image: url('/img/d/check.svg');
  background-repeat: no-repeat;
  background-size: 12px 10px;
}

%icon-form-mail {
  width: 18px;
  height: 14px;
  display: inline-block;
  background-image: url('/img/d/form-email.svg');
  background-repeat: no-repeat;
  background-size: 18px 14px;
}

%icon-form-lock {
  width: 17px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/form-password.svg');
  background-repeat: no-repeat;
  background-size: 17px 20px;
}

%icon-form-user {
  width: 15px;
  height: 17px;
  display: inline-block;
  background-image: url('/img/d/form-name.svg');
  background-repeat: no-repeat;
  background-size: 15px 17px;
}

%icon-form-pin {
  width: 15px;
  height: 17px;
  display: inline-block;
  background-image: url('/img/d/form-address.svg');
  background-repeat: no-repeat;
  background-size: 15px 17px;
}

%icon-form-buildings {
  width: 19px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/form-city.svg');
  background-repeat: no-repeat;
  background-size: 19px 19px;
}

%icon-form-mail-pin {
  width: 21px;
  height: 22px;
  display: inline-block;
  background-image: url('/img/d/form-postcode.svg');
  background-repeat: no-repeat;
  background-size: 21px 22px;
}

%icon-form-map-pin {
  width: 21px;
  height: 23px;
  display: inline-block;
  background-image: url('/img/d/form-address.svg');
  background-repeat: no-repeat;
  background-size: 21px 22px;
}

%icon-form-tax {
  width: 19px;
  height: 14px;
  display: inline-block;
  background-image: url('/img/d/form-tax.svg');
  background-repeat: no-repeat;
  background-size: 19px 14px;
}

%icon-form-pin-code {
  width: 17px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/form-pin.svg');
  background-repeat: no-repeat;
  background-size: 17px 20px;
}

%icon-form-dollar-sign {
  width: 13px;
  height: 21px;
  display: inline-block;
  background-image: url('/img/d/form-amount.svg');
  background-repeat: no-repeat;
  background-size: 13px 21px;
}

%icon-form-house {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url('/img/d/form-house.svg');
  background-repeat: no-repeat;
  background-size: 15px 15px;
}

%icon-form-edit {
  width: 21px;
  height: 17px;
  display: inline-block;
  background-image: url('/img/d/form-edit.svg');
  background-repeat: no-repeat;
  background-size: 21px 17px;
}

%icon-form-bank {
  width: 18px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/form-bank.svg');
  background-repeat: no-repeat;
  background-size: 18px 20px;
}

%icon-form-swift {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/form-swift.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%icon-form-bank-account-number {
  width: 17px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/form-bank-account.svg');
  background-repeat: no-repeat;
  background-size: 17px 20px;
}

%icon-form-credit {
  width: 17px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/d/form-credit.svg');
  background-repeat: no-repeat;
  background-size: 17px 20px;
}

%icon-form-bitcoin {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/form-bitcoin.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%icon-form-branch-code {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/d/form-branch-code.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%icon-form-apply {
  width: 20px;
  height: 19px;
  display: inline-block;
  background-image: url('/img/apply-v2.svg');
  background-repeat: no-repeat;
  background-size: 20px 19px;
}

%icon-form-paperclip {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-image: url('/img/paperclip.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
}

%icon-form-creditcard {
  width: 18px;
  height: 16px;
  display: inline-block;
  background-image: url('/img/creditcard.svg');
  background-repeat: no-repeat;
  background-size: 18px 16px;
}

/*********************************************************************************/
/*********************************************************************************/
                      /* Home sprite mapping collection */
/*********************************************************************************/
/*********************************************************************************/

%bg-home-search {
  width: 880px;
  height: 350px;
  display: inline-block;
  @include home-search-bg-x1();
  @include retina-breakpoint() {
    @include home-search-bg-x2();
  }
}

/*********************************************************************************/
/*********************************************************************************/
                      /* Blogs sprite mapping collection */
/*********************************************************************************/
/*********************************************************************************/

%blog-subscribe-bg {
  width: 319px;
  height: 226px;
  display: inline-block;
  background-image: url('/img/d/blogs-subscribe-bg.jpg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 319px 226px;
}

/*********************************************************************************/
/*********************************************************************************/
                      /* Vault sprite mapping collection */
/*********************************************************************************/
/*********************************************************************************/

%vault-bsp {
  width: 98px;
  height: 107px;
  display: inline-block;
  background-image: url('/img/d/vault-bsp.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 98px 107px;
}

%vault-bullion-audits {
  width: 74px;
  height: 86px;
  display: inline-block;
  background-image: url('/img/d/vault-bullion-audits.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 74px 86px;
}

%vault-bullion-withdrawals {
  width: 88px;
  height: 74px;
  display: inline-block;
  background-image: url('/img/d/vault-bullion-withdrawals.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 88px 74px;
}

%vault-cash-bullion-account {
  width: 75px;
  height: 75px;
  display: inline-block;
  background-image: url('/img/d/vault-cash-bullion-account.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 75px 75px;
}

%vault-fee-schedule {
  width: 70px;
  height: 73px;
  display: inline-block;
  background-image: url('/img/d/vault-fee-schedule.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 70px 73px;
}

%vault-insurance-coverage {
  width: 82px;
  height: 102px;
  display: inline-block;
  background-image: url('/img/d/vault-insurance-coverage.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 82px 102px;
}

%vault-legal-ownership {
  width: 79px;
  height: 99px;
  display: inline-block;
  background-image: url('/img/d/vault-legal-ownership1.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 79px 99px;
}

%vault-online-accessibility {
  width: 92px;
  height: 77px;
  display: inline-block;
  background-image: url('/img/d/vault-online-accessibility.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 92px 77px;
}

%vault-pictures-bullion {
  width: 84px;
  height: 94px;
  display: inline-block;
  background-image: url('/img/d/vault-pictures-bullion.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 84px 94px;
}

%vault-security-surveillance {
  width: 81px;
  height: 86px;
  display: inline-block;
  background-image: url('/img/d/vault-security-surveillance.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 81px 86px;
}

%vault-storage-overview {
  width: 77px;
  height: 84px;
  display: inline-block;
  background-image: url('/img/d/vault-storage-overview.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 77px 84px;
}

/*********************************************************************************/
/*********************************************************************************/
                      /* Flag icon sprite mapping collection */
/*********************************************************************************/
/*********************************************************************************/

%flag-ad {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -24px);
  }
}

%flag-ae {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -24px);
  }
}

%flag-af {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -24px);
  }
}

%flag-ag {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -24px);
  }
}

%flag-ai {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -24px);
  }
}

%flag-al {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -24px);
  }
}

%flag-am {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -24px);
  }
}

%flag-an {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -24px);
  }
}

%flag-ao {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -24px);
  }
}

%flag-ar {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -24px);
  }
}

%flag-as {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -24px);
  }
}

%flag-at {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -24px);
  }
}

%flag-au {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -48px);
  }
}

%flag-aw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -48px);
  }
}

%flag-ax {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -48px);
  }
}

%flag-az {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -48px);
  }
}

%flag-ba {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -48px);
  }
}

%flag-bb {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -48px);
  }
}

%flag-bd {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -48px);
  }
}

%flag-be {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -48px);
  }
}

%flag-bf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -48px);
  }
}

%flag-bg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -48px);
  }
}

%flag-bh {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -48px);
  }
}

%flag-bi {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -48px);
  }
}

%flag-bj {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -48px);
  }
}

%flag-bm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -48px);
  }
}

%flag-bn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -48px);
  }
}

%flag-bo {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -72px);
  }
}

%flag-br {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -72px);
  }
}

%flag-bs {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -72px);
  }
}

%flag-bt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -72px);
  }
}

%flag-bv {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-27px, -19px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-27px, -19px);
  }
}

%flag-bw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -72px);
  }
}

%flag-by {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -72px);
  }
}

%flag-bz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -72px);
  }
}

%flag-ca {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -72px);
  }
}

%flag-catalonia {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-121px, -19px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-121px, -19px);
  }
}

%flag-cc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -72px);
  }
}

%flag-cd {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -72px);
  }
}

%flag-cf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -72px);
  }
}

%flag-cg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -72px);
  }
}

%flag-ch {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-289px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-289px,  -72px);
  }
}

%flag-ci {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -72px);
  }
}

%flag-ck {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -72px);
  }
}

%flag-cl {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -72px);
  }
}

%flag-cm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -96px);
  }
}

%flag-cn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -96px);
  }
}

%flag-co {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -96px);
  }
}

%flag-cr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -96px);
  }
}

%flag-cs {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-369px, -19px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-369px, -19px);
  }
}

%flag-cu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -96px);
  }
}

%flag-cv {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -96px);
  }
}

%flag-cx {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -96px);
  }
}

%flag-cy {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -96px);
  }
}

%flag-cz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -96px);
  }
}

%flag-de {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -96px);
  }
}

%flag-dj {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -96px);
  }
}

%flag-dk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -96px);
  }
}

%flag-dm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -96px);
  }
}

%flag-do {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -96px);
  }
}

%flag-dz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -96px);
  }
}

%flag-ec {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -120px);
  }
}

%flag-ee {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -120px);
  }
}

%flag-eg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -120px);
  }
}

%flag-eh {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -120px);
  }
}

%flag-england {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-103px, -35px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-103px, -35px);
  }
}

%flag-er {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -120px);
  }
}

%flag-es {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -120px);
  }
}

%flag-et {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -120px);
  }
}

%flag-europeanunion {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-178px, -35px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-178px, -35px);
  }
}

%flag-farm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-198px, -35px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-198px, -35px);
  }
}

%flag-fi {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -120px);
  }
}

%flag-fj {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -120px);
  }
}

%flag-fk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -120px);
  }
}

%flag-fm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -120px);
  }
}

%flag-fo {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -120px);
  }
}

%flag-fr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -120px);
  }
}

%flag-ga {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -120px);
  }
}

%flag-gb {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -120px);
  }
}

%flag-gd {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -144px);
  }
}

%flag-ge {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -144px);
  }
}

%flag-gf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px, -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px, -168px);
  }
}

%flag-gg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-49px, -145px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-49px, -145px);
  }
}

%flag-gh {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -144px);
  }
}

%flag-gi {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -144px);
  }
}

%flag-gl {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -144px);
  }
}

%flag-gm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -144px);
  }
}

%flag-gn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -144px);
  }
}

%flag-gp {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px, -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px, -192px);
  }
}

%flag-gq {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -144px);
  }
}

%flag-gr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -144px);
  }
}

%flag-gs {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -144px);
  }
}

%flag-gt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -144px);
  }
}

%flag-gu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -144px);
  }
}

%flag-gw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -144px);
  }
}

%flag-gy {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -144px);
  }
}

%flag-hk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -168px);
  }
}

%flag-hm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-122px, -51px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-122px, -51px);
  }
}

%flag-hn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -168px);
  }
}

%flag-hr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -168px);
  }
}

%flag-ht {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -168px);
  }
}

%flag-hu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -168px);
  }
}

%flag-id {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -168px);
  }
}

%flag-ie {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -168px);
  }
}

%flag-il {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -168px);
  }
}

%flag-im {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-217px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-217px,  -168px);
  }
}

%flag-in {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -168px);
  }
}

%flag-io {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-293px, -51px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-293px, -51px);
  }
}

%flag-iq {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -168px);
  }
}

%flag-ir {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -168px);
  }
}

%flag-is {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -168px);
  }
}

%flag-it {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -168px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -168px);
  }
}

%flag-jm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -192px);
  }
}

%flag-jo {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -192px);
  }
}

%flag-jp {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -192px);
  }
}

%flag-ke {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -192px);
  }
}

%flag-kg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -192px);
  }
}

%flag-kh {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -192px);
  }
}

%flag-ki {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -192px);
  }
}

%flag-km {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -192px);
  }
}

%flag-kn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -192px);
  }
}

%flag-kp {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -192px);
  }
}

%flag-kr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -192px);
  }
}

%flag-kw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -192px);
  }
}

%flag-ky {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -192px);
  }
}

%flag-kz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -192px);
  }
}

%flag-la {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -192px);
  }
}

%flag-lb {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -192px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -192px);
  }
}

%flag-lc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -216px);
  }
}

%flag-li {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -216px);
  }
}

%flag-lk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -216px);
  }
}

%flag-lr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -216px);
  }
}

%flag-ls {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -216px);
  }
}

%flag-lt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -216px);
  }
}

%flag-lu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -216px);
  }
}

%flag-lv {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -216px);
  }
}

%flag-ly {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -216px);
  }
}

%flag-ma {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -216px);
  }
}

%flag-mc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -216px);
  }
}

%flag-md {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -216px);
  }
}

%flag-me {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -216px);
  }
}

%flag-mg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -216px);
  }
}

%flag-mh {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -216px);
  }
}

%flag-mk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -240px);
  }
}

%flag-ml {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -240px);
  }
}

%flag-mm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -240px);
  }
}

%flag-mn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -240px);
  }
}

%flag-mo {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -240px);
  }
}

%flag-mp {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -240px);
  }
}

%flag-mq {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -240px);
  }
}

%flag-mr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -240px);
  }
}

%flag-ms {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -240px);
  }
}

%flag-mt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -240px);
  }
}

%flag-mu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -240px);
  }
}

%flag-mv {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -240px);
  }
}

%flag-mw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -240px);
  }
}

%flag-mx {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -240px);
  }
}

%flag-my {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -240px);
  }
}

%flag-mz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -240px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -240px);
  }
}

%flag-na {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -264px);
  }
}

%flag-nc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -264px);
  }
}

%flag-ne {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -264px);
  }
}

%flag-nf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -264px);
  }
}

%flag-ng {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -264px);
  }
}

%flag-ni {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -264px);
  }
}

%flag-nl {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -264px);
  }
}

%flag-no {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -264px);
  }
}

%flag-np {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -265px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -265px);
  }
}

%flag-nr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -264px);
  }
}

%flag-nu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -264px);
  }
}

%flag-nz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -264px);
  }
}

%flag-om {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -264px);
  }
}

%flag-pa {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -264px);
  }
}

%flag-pe {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -264px);
  }
}

%flag-pf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -264px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -264px);
  }
}

%flag-pg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -288px);
  }
}

%flag-ph {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -288px);
  }
}

%flag-pk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -288px);
  }
}

%flag-pl {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -288px);
  }
}

%flag-pm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-523px, -83px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-523px, -83px);
  }
}

%flag-pn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -288px);
  }
}

%flag-pr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -288px);
  }
}

%flag-ps {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -288px);
  }
}

%flag-pt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -288px);
  }
}

%flag-pw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -288px);
  }
}

%flag-py {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -288px);
  }
}

%flag-qa {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -288px);
  }
}

%flag-re {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -120px);
  }
}

%flag-ro {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -288px);
  }
}

%flag-rs {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -288px);
  }
}

%flag-ru {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -288px);
  }
}

%flag-rw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -288px);
  }
}

%flag-sa {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -288px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -288px);
  }
}

%flag-sb {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -312px);
  }
}

%flag-sc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -312px);
  }
}

%flag-scotland {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-731px, -19px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-731px, -19px);
  }
}

%flag-sd {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -312px);
  }
}

%flag-se {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -312px);
  }
}

%flag-sg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -312px);
  }
}

%flag-sh {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -312px);
  }
}

%flag-si {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -312px);
  }
}

%flag-sj {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-28px, -99px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-28px, -99px);
  }
}

%flag-sk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -312px);
  }
}

%flag-sl {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -312px);
  }
}

%flag-sm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -312px);
  }
}

%flag-sn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -312px);
  }
}

%flag-so {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -312px);
  }
}

%flag-sr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -312px);
  }
}


%flag-ss {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -312px);
  }
}

%flag-st {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -312px);
  }
}

%flag-sv {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -312px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -312px);
  }
}

%flag-sy {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -336px);
  }
}

%flag-sz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -336px);
  }
}

%flag-tc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -336px);
  }
}

%flag-td {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -336px);
  }
}

%flag-tf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -336px);
  }
}

%flag-tg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -336px);
  }
}

%flag-th {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -336px);
  }
}

%flag-tj {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -336px);
  }
}

%flag-tk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -336px);
  }
}

%flag-tl {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -336px);
  }
}

%flag-tm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -336px);
  }
}

%flag-tn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -336px);
  }
}

%flag-to {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -336px);
  }
}

%flag-tr {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -336px);
  }
}

%flag-tt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -336px);
  }
}

%flag-tv {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -336px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -336px);
  }
}

%flag-tw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(0px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(0px,  -360px);
  }
}

%flag-tz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-24px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-24px,  -360px);
  }
}

%flag-ua {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-48px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-48px,  -360px);
  }
}

%flag-ug {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-72px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-72px,  -360px);
  }
}

%flag-uk {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-361px, -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-361px, -120px);
  }
}

%flag-um {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-619px, -67px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-619px, -67px);
  }
}

%flag-us {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-96px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-96px,  -360px);
  }
}

%flag-uy {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-120px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-120px,  -360px);
  }
}

%flag-uz {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-144px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-144px,  -360px);
  }
}

%flag-va {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-168px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-168px,  -360px);
  }
}

%flag-vc {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-192px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-192px,  -360px);
  }
}

%flag-ve {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-216px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-216px,  -360px);
  }
}

%flag-vg {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-240px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-240px,  -360px);
  }
}

%flag-vi {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-264px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-264px,  -360px);
  }
}

%flag-vn {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-288px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-288px,  -360px);
  }
}

%flag-vu {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-312px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-312px,  -360px);
  }
}

%flag-wales {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-657px, -67px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-657px, -67px);
  }
}

%flag-wf {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-336px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-336px,  -360px);
  }
}

%flag-ws {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-360px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-360px,  -360px);
  }
}

%flag-ye {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -24px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -24px);
  }
}

%flag-yt {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -48px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -48px);
  }
}

%flag-za {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -72px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -72px);
  }
}

%flag-zm {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -96px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -96px);
  }
}

%flag-zw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -120px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -120px);
  }
}

%flag-je {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -144px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -144px);
  }
}

%flag-cw {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -216px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -216px);
  }
}

%flag-eng {
  width: 24px;
  height: 24px;
  display: inline-block;
  @include sprite-flags-x1(-384px,  -360px);
  @include retina-breakpoint() {
    @include sprite-flags-x2(-384px,  -360px);
  }
}

%flag-sgd {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/singapore-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-usd {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/america-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-aud {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/australia-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-eur {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/europe-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-jpy {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/japan-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-sek {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/sweden-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-brl {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/brazil-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-gbp {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/british-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-cad {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/canada-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-cny {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/china-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-dkk {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/denmark-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-hkd {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/hongkong-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-inr {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/india-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-idr {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/indonesia-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-myr {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/malaysia-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-nzd {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/new-zealand-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-rub {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/russia-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-chf {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('/img/d/swiss-rectangle-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
}

%flag-btc {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/bitcoin-v1.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%flag-bch {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/bitcoincash.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%flag-eth {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/ethereum.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%flag-ltc {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/litecoin.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%flag-xrp {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/ripple.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

%flag-xdg {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('/img/dogecoin.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

// %icon-menu-preload {
//   width: 1px;
//   height: 1px;
//   @include menu-sprite-x1(0, 0);
//   @include retina-breakpoint() {
//     @include menu-sprite-x2(0, 0);
//   }
// }
