
  /* Functional styling;
   * These styles are required for noUiSlider to function.
   * You don't need to change these rules to apply your design.
   */
  .noUi-target,
  .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .noUi-target {
    position: relative;
    direction: ltr;
  }
  .noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; /* Fix 401 */
  }
  .noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .noUi-handle {
    position: relative;
    z-index: 1;
  }
  .noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
     its values is > 50%. */
    z-index: 10;
  }
  .noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
    transition: left 0.3s, top 0.3s;
  }
  .noUi-state-drag * {
    cursor: inherit !important;
  }

  /* Painting and performance;
   * Browsers can paint handles in their own layer.
   */
  .noUi-base,
  .noUi-handle {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  /* Slider size and handle placement;
   */
  .noUi-horizontal {
    height: 8px;
  }
  .noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    left: -5px;
    top: -7px;
  }
  .noUi-handle-lower { left: -10px !important; }
  .noUi-handle-upper { left: -10px !important; }
  .noUi-vertical {
    width: 18px;
  }
  .noUi-vertical .noUi-handle {
    width: 20px;
    height: 20px;
    left: -10px;
    top: -17px;
  }

  /* Styling;
   */
  .noUi-background {
    background: #FAFAFA;
    box-shadow: inset 0 1px 1px #f0f0f0;
  }
  .noUi-connect {
    background: $orange-mid;
    box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
    -webkit-transition: background 450ms;
    transition: background 450ms;
  }
  .noUi-origin {
    border-radius: 2px;
  }
  .noUi-target {
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
  }
  .noUi-target.noUi-connect {
    box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
  }

  /* Handles and cursors;
   */
  .noUi-draggable {
    cursor: w-resize;
  }
  .noUi-vertical .noUi-draggable {
    cursor: n-resize;
  }
  .noUi-handle {
    border: 1px solid #D3D3D3;
    border-radius: 50%;
    background: $orange-mid;
    cursor: default;
  }
  .noUi-active {
    border: 6px solid $orange-mid;
    background: #fff;
  }

  /* Handle stripes;
   */
  .noUi-handle:before,
  .noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    /*background: #E8E7E6;*/
    left: 14px;
    top: 6px;
  }
  .noUi-handle:after {
    left: 17px;
  }
  .noUi-vertical .noUi-handle:before,
  .noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
  }
  .noUi-vertical .noUi-handle:after {
    top: 17px;
  }

  /* Disabled state;
   */
  [disabled].noUi-connect,
  [disabled] .noUi-connect,
  [disabled] .noUi-handle {
    background: #B8B8B8;
  }
  [disabled].noUi-origin,
  [disabled] .noUi-handle,
  [disabled] .noUi-handle {
    cursor: not-allowed;
  }

  /* Base;
   *
   */
  .noUi-pips,
  .noUi-pips * {
  -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .noUi-pips {
    position: absolute;
    color: #999;
  }

  /* Values;
   *
   */
  .noUi-value {
    position: absolute;
    top: 18px;
    text-align: center;
    font-size: 10px;
    display: none;
  }
  .noUi-value-sub {
    color: #ccc;
    font-size: 10px;
  }

  /* Markings;
   *
   */
  .noUi-marker {
    position: absolute;
    background: #CCC;
  }
  .noUi-marker-sub {
    background: #AAA;
  }
  .noUi-marker-large {
    background: #AAA;
  }

  /* Horizontal layout;
   *
   */
  .noUi-pips-horizontal {
    padding: 10px 0;
    height: 30px;
    top: 100%;
    left: 0;
    width: 100%;
  }
  .noUi-value-horizontal {
    -webkit-transform: translate3d(-50%,50%,0);
    transform: translate3d(-50%,50%,0);
  }

  .noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 1px;
    height: 5px;
    display: none;
  }
  .noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
  }
  .noUi-marker-horizontal.noUi-marker-large {
    height: 10px;
    display: block;
  }

  /* Vertical layout;
   *
   */
  .noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
  }
  .noUi-value-vertical {
    -webkit-transform: translate3d(0,-50%,0);
    transform: translate3d(0,-50%,0);
    padding-left: 25px;
  }

  .noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
  }
  
  .noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
  }
  
  .noUi-marker-vertical.noUi-marker-large {
    width: 15px;
  }
