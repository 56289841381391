@import 'desktop/vars';
@import 'desktop/fonts';
@import 'desktop/base';
@import 'desktop/theme';
@import 'desktop/layout';
@import 'desktop/module';
@import 'desktop/state';
@import 'desktop/common';

// @import 'desktop/pages/createaccount';
// @import 'desktop/pages/help';
// @import 'desktop/pages/audit';
// @import 'desktop/pages/insurance_certificate';
// @import 'desktop/pages/e_booklet';
// @import 'desktop/pages/vault_certificate';
// @import 'desktop/pages/setalerts';
// @import 'desktop/pages/photos';
// @import 'desktop/pages/vault';
// @import 'desktop/pages/add_funds';
// @import 'desktop/pages/withdraw_funds';
// @import 'desktop/pages/transactions';
// @import 'desktop/pages/storage_charges';
// @import 'desktop/pages/affiliate';
// @import 'desktop/pages/audit_protocols';
// @import 'desktop/pages/dashboard';
// @import 'desktop/pages/contact';
// @import 'desktop/pages/aboutus';
// @import 'desktop/pages/myaccount_links';
// @import 'desktop/pages/our_shop';
// @import 'desktop/pages/bsp';
// @import 'desktop/pages/reset_password';
// @import 'desktop/pages/login_page';
@import 'desktop/pages/wholepage';
// @import 'desktop/pages/news';
// @import 'desktop/pages/accountcreated';
// @import 'desktop/pages/details';
// @import 'desktop/pages/video';
// @import 'desktop/pages/vault_checkout';
// @import 'desktop/pages/sell';
@import 'desktop/pages/order_confirmation';
// @import 'desktop/pages/gold_university';
// @import 'desktop/pages/home';
@import 'desktop/pages/product_details';
// @import 'desktop/pages/careers';
// @import 'desktop/pages/investor_relation';
// @import 'desktop/pages/faces';
// @import 'desktop/pages/story';
// @import 'desktop/pages/blogs';
// @import 'desktop/pages/board';
@import 'desktop/pages/product_overview';
// @import 'desktop/pages/checkout';
@import 'desktop/pages/search';
// @import 'desktop/pages/press_room';
// @import 'desktop/pages/duediligence';
// @import 'desktop/pages/charts';
// @import 'desktop/pages/pictures'; /* not in use anymore */
// @import 'desktop/pages/transfer_in';
// @import 'desktop/pages/price_today';
// @import 'desktop/pages/best_price_guarantee';
// @import 'desktop/pages/language';
// @import 'desktop/pages/unsubscribe';
// @import 'desktop/pages/privacy';
// @import 'desktop/pages/bullionstar_cash_account';
// @import 'desktop/pages/why_buy';
// @import 'desktop/pages/articles';
// @import 'desktop/pages/sell_gold';
// @import 'desktop/pages/bitcoin';
// @import 'desktop/pages/available_instock';
// @import 'desktop/pages/creditcard';
// @import 'desktop/pages/ira';
// @import 'desktop/pages/gold_bar_400_oz';
// @import 'desktop/pages/sdb';
// @import 'desktop/pages/myauction';
// @import 'desktop/pages/auction_checkout';
@import 'desktop/pages/us';
// @import 'desktop/pages/taxes';
// @import 'desktop/pages/shipping';
// @import 'desktop/pages/vault_storage_us';
@import 'desktop/pages/shipping_vault_tabs';
// @import 'desktop/pages/vault_storage_nz';
@import 'desktop/modules/contact_us_buttons_v2';
// @import 'desktop/pages/competition_price';
// @import 'desktop/pages/reviews';
// @import 'desktop/pages/dragon_silver';
// @import 'desktop/pages/financial_reports';
// @import 'desktop/pages/media_kit';

@media print {
  	body { -webkit-print-color-adjust: exact;  }
	.wrap-header, .sidebar, .main-footer, .header-announcement, .cookiePolicyContent { display: none; }
	.main-page { width: 100% !important; float: none !important; }
  	a:after { content:''; }
  	a[href]:after { content: none !important; }
}