.main-footer {
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding-bottom: 30px;
  border-top: 6px solid $orange-dark;
  background: #f4f4f4;

  @include breakpoint-max(767px) {
    width: 767px;
  }

  > .top {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 15px 0;

    @include breakpoint-max(1230px) {
      padding: 20px 15px 0;
    }

    > div {
      min-height: 65px;
      height: auto;

      @include breakpoint-max(1180px) {
        width: 25%;
        margin: 0;
        padding: 0;
      }

      &:last-child {
        margin: 0;
        padding: 0;
      }

      > p {
        margin-bottom: 8px;
        color: $orange-light;
        font-size: 14px;
        font-weight: bold;

        @include breakpoint-max(1180px) {
          font-size: 13px;
        }

        @include breakpoint-max(1000px) {
          font-size: 12px;
        }
      }

      > ul {
        > li {
          line-height: 20px;

          > a {
            font-size: 12px;
            color: #3F3933;

            &:hover {
              color: $orange-mid;
            }

            @include breakpoint-max(1180px) {
              font-size: 11px;
            }
          }
        }
      }
    }

    > .get-to-know {
      > ul {
        column-count: 2;

        > li {
          margin-right: 40px;

          @include breakpoint-max(1230px) {
            margin-right: 20px;
          }
        }

        @include breakpoint-max(1180px) {
          column-count: 1;
          display: flex;
          flex-flow: row wrap;

          li {
            width: 100%;
          }
        }
      }

      > .address {
        margin-top: 25px;
        width: max-content;

        > p {
          font-family: Helvetica, Arial, sans-serif;
          font-size: 11px;
          line-height: 14px;

          @include breakpoint-max(1180px) {
            font-size: 10px;
          }
        }
      }
    }

    > .collaborate {
      margin: 0 70px 0 20px;
      padding: 0;

      @include breakpoint-max(1180px) {
        margin: 0;
        padding: 0;
      }
    }

    > .bs-products {
      margin: 0;
      padding: 0;
      // padding: 0 20px;

      @include breakpoint-max(1230px) {
        // margin: 0 30px;
      }
      @include breakpoint-max(1155px) {
        // padding: 0 20px 0 0;
      }

      > ul {
        column-count: 2;

        @include breakpoint-max(1180px) {
          column-count: 1;
          display: flex;
          flex-flow: row wrap;

          li {
            width: 100%;
          }

          li:nth-child(1) {
            order: 1;
          }

          li:nth-child(6) {
            order: 2;
          }

          li:nth-child(2) {
            order: 3;
          }

          li:nth-child(7) {
            order: 4;
          }

          li:nth-child(3) {
            order: 5;
          }

          li:nth-child(8) {
            order: 6;
          }

          li:nth-child(4) {
            order: 7;
          }

          li:nth-child(9) {
            order: 8;
          }

          li:nth-child(5) {
            order: 9;
          }

          li:nth-child(10) {
            order: 10;
          }
        }
      }
    }

    > .connect {
      margin-left: 20px;

      > .fb {
        @extend %icon-footer-facebook;
      }

      > .tw {
        @extend %icon-footer-twitter;
      }

      > .ig {
        @extend %icon-footer-instagram;
      }

      > .gp {
        @extend %icon-footer-google;
      }

      > .yt {
        @extend %icon-footer-youtube;
      }

      > .rss {
        @extend %icon-footer-rss;
      }

      > .telegram {
        @extend %icon-footer-telegram;
      }

      @include breakpoint-max(1180px) {
        margin-left: 0;
      }

      @include breakpoint-max(793px) {
        padding-right: 0;
      }

      > p {
        margin-bottom: 5px;
      }

      > a {
        margin-right: 5px;
        
        @include clickable-image-hover-effect-dark();
      }
      
      > .footer-reviews {
        margin-top: 20px;
        max-width: 217px;
        width: 100%;

        > .review-title {
          margin-bottom: 8px;
          color: $orange-light;
          font-size: 14px;
          font-weight: bold;
  
          @include breakpoint-max(1180px) {
            font-size: 13px;
          }
  
          @include breakpoint-max(1000px) {
            font-size: 12px;
          }
        }

        > .review-link {
          display: inline-block;
          width: 66px;
          text-align: center;
          margin-bottom: 25px;
          margin-right: 5px;
          position: relative;

          @include clickable-image-hover-effect-dark();

          &:nth-child(3n+1) {
            margin-right: 0;
            @include breakpoint-max(915px) {
              margin-right: 8px;
            }
          }

          > img {
            max-width: 100%;
          }

          &.review-bbb {
            width: 132px;

            > img {
              position: relative;
              top: 8px;
              @include breakpoint-max(915px) {
                top: 0;
              }
            }
          }
        }

        .rating-star-default {
          position: absolute;
          left: 0;
          width: 66px;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0;
          list-style: none;
          z-index: 1;
          margin-top: 2px;

          li {
            display: inline-block;
            
            span {
              @extend %icon-google-star-orange;
              margin-left: 1px;
              z-index: 1;
            }
          }

        }

        .rating-star {
          position: absolute;
          left: 0;
          width: auto;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0;
          list-style: none;
          z-index: 2;
          margin-top: 2px;

          li {
            display: inline-block;
            
            span {
              @extend %icon-google-star-orange-filled;
              margin-left: 1px;
              z-index: 2;
            }
          }
        }

        .rating-star-blue {
          li {
            span {
              @extend %icon-fb-star-blue-filled;
              margin-left: 1px;
              z-index: 2;
            }
          }
        }

        .rating-circle-default {
          position: absolute;
          left: 0;
          width: 66px;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0;
          list-style: none;
          z-index: 1;
          margin-top: 2px;

          li {
            display: inline-block;
            
            span {
              @extend %icon-tripadvisor-rating;
              margin-left: 1px;
              z-index: 1;
            }
          }
        }

        .rating-circle {
          position: absolute;
          left: 0;
          width: auto;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0;
          list-style: none;
          z-index: 2;
          margin-top: 2px;

          li {
            display: inline-block;
            
            span {
              @extend %icon-tripadvisor-rating-full;
              margin-left: 1px;
              z-index: 2;
            }
          }
        }
        
        .rating-star-green-default {
          position: absolute;
          left: 0;
          width: 66px;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0;
          list-style: none;
          z-index: 1;
          margin-top: 2px;

          li {
            display: inline-block;
            
            span {
              @extend %icon-trustpilot-rating;
              margin-left: 1px;
              z-index: 1;
            }
          }
        }

        .rating-star-green {
          position: absolute;
          left: 0;
          width: auto;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          font-size: 0;
          list-style: none;
          z-index: 2;
          margin-top: 2px;

          li {
            display: inline-block;
            
            span {
              @extend %icon-trustpilot-rating-full;
              margin-left: 1px;
              z-index: 2;
            }
          }
        }
      }
    }
  }
}

.cookiePolicy {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #f5f5f5;
  z-index: 1001;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

.cookiePolicyContent {
  max-width: 900px;
  width: 60%;
  margin: 0 auto;
  padding: 10px 0;
  position: static;
  font-size: 12px;
}

.cookiePolicyContent .button-cell {
  padding-left: 20px;
  vertical-align: middle;
}