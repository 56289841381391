.page-map {
  position: relative;
  width: 100%;
  margin: 20px auto 60px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  overflow: hidden;

  > .map-info {
    position: relative;
    width: 300px;
    height: 312px;
    padding: 14px 10px;
    background: #fff;
    z-index: 2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @include breakpoint-max(1050px) {
      width: 100%;
      margin: 0 auto;
      height: auto;
    }

    .address-wrap {
      font-size: 16px;

      @include breakpoint-max(1050px) {
        float: left;
        width: 48%;
        margin-top: 10px;
        padding-right: 7px;
      }
    }

    .support-tab-wrap {

      @include breakpoint-max(1050px) {
        float: right;
        width: 52%;
      }

      > .body {
        padding: 10px;

        @include breakpoint-max(1050px) {
          padding: 8px;
        }
      }
    }

    .address {
      display: block;
      color: #000;

      > img {
        margin-right: 10px;
      }
    }
    
    p {
      margin: 0;
      font-size: 16px;
    }

    .storefront {
      margin: 10px 0;
      font-style: italic;
      font-size: 16px;
    }
  }

  > .map-img-wrap {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 300px);
    display: block;
    z-index: 1;

    @include breakpoint-max(1050px) {
      position: static;
      width: 100%;
    }

    > img {
      position: absolute;
      top: 0;
      right: -100px;
      height: 312px;

      @include breakpoint-max(1050px) {
        position: static;
        width: 100%;
        height: auto;
      }
    }

    > button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 105px;
      margin: 0 auto;
    }
  }
}