.bullion-select {
  position: relative;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;

  &.with-flag {
    > select {
      padding-left: 34px;
    }

    > .flag {
      margin: 0 0 0 4px;
      z-index: 6;
    }
  }

  > select {
    position: relative;
    width: 100%;
    height: 30px;
    z-index: 5;
    padding: 12px 30px 0 4px;
    font-size: 14px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    appearance: none;
    -ms-apperance: none;
    background: transparent;
  }

  > .image {
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 2;
    @extend %arrow-head-down-svg;
  }

}

.bullion-select-with-icon {
  position: relative;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;

  > .icon {
    position: absolute;
    top: 12px;
    left: 0;
    z-index: 2;
    @extend %icon-form-apply;

    &.icon-map {
      @extend %icon-form-map-pin;
    }
  }

  > select {
    position: relative;
    width: 100%;
    height: 30px;
    z-index: 5;
    padding: 12px 30px 0 25px;
    font-size: 14px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    appearance: none;
    -ms-apperance: none;
    background: transparent;
  }

  > .image {
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 2;
    @extend %arrow-head-down-svg;
  }

}
