/* Product Home Item */
.product {
  position: relative;
  width: 212px;
  min-height: 345px;
  height: auto;
  padding: 10px 13px;
  margin-right: 10px;
  float: left;
  outline: none;
  flex-grow: 0;
  background: #fff;
  border: 1px solid #ebebeb;

  &:last-child {
    margin-right: 0;
  }

  > .most-ordered {
    position: absolute;
    top: -3px;
    left: -7px;
    margin: 0 0 5px;
    padding: 1px 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;

    > span {
      position: absolute;
      top: 5px;
      left: 12px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;

      > span {
        font-size: 11px;
      }
    }
  }

  > .link {
    margin-bottom: 4px;
    display: block;
    cursor: pointer;

    > .name {
      width: 180px;
      height: 60px;
      overflow: hidden;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      color: #231F20;

      @include breakpoint-max(996px) {
        font-size: 12px;
      }

      @include breakpoint-max(800px) {
        font-size: 11px;
      }
    }

    > figure {
      position: relative;
      min-height: 100px;
      margin: 10px 0;
      text-align: center;

      > img {
        position: absolute;
        inset: 0;
        width: 100px;
        height: 100px;
        margin: auto;
      }
    }
  }

  > .status {
    text-align: center;
    font-weight: bold;
    
    &.stock-qty {
      margin-bottom: 0;
    }

    > span {
      color: #4a4a4a;
      font-weight: normal;
      font-size: 12px;
    }

    > .stock-quantity {
      font-size: 13px;
      font-weight: bold;
      color: green;
    }
  }

  > .as-low-as {
    color: #8D8F92;
    margin: 15px 0 0;
    font-size: 10px;
    line-height: 8px;
    text-align: center;
  }

  > .price {
    margin-bottom: 15px;
    color: $orange-light;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
  }

  > .actions {
    margin-bottom: 10px;
    text-align: center;
    
    > input {
      width: 60px;
      height: 30px;
      padding: 5px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #ccc;
      border-right: 0;
      vertical-align: top;
      font-size: 12px;
      color: #231F20;
      background: transparent;
    }

    > button {
      width: 80px;
      height: 30px;
      padding: 7px;
      font-size: 11px;
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 0;
    }

    .btn-auction-link {
      margin-bottom: 5px;
      width: calc(100% - 8px);
    }
  }

  > .extra {
    min-height: 15px;
    margin-bottom: 0;
    color: #E71C24;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
  }

  > .note {
    color: #231F20;
    font-size: 12px;
    line-height: 1.4286;
  }
}

.product-search-new {
  min-height: 375px;

  > .lto-timer {
    width: 100%;
    text-align: center;

    > .name {
      display: flex;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      color: #000;

      > img {
        vertical-align: -24px;
        margin-right: 7px;
      }
    }

    > .time {
      margin: -25px 0 0 8px;
      font-size: 12px;
      font-weight: 700;
    }
  }

  > .empty-placeholder {
    height: 32px;
  }

  > .price-old {
    height: auto;
    margin: 12px 0 1px;
    font-size: 11px;
    text-align: center;

    > span {
      text-decoration: line-through;
      font-size: 11px;
    }
  }

  > .price-new {
    margin-bottom: 20px;
    text-align: center;
    font-size: 15px;
    line-height: 1.1;
    font-weight: 700;
    color: #be1e2d;
  }
}

/* Product Search Page Item */
.product-search {
  width: 200px;
  height: auto;
  margin-right: 15px;
  padding: 0 10px 0 0;
  float: left;
  outline: none;
  flex-grow: 0;

  &:last-child {
    margin-right: 0;
  }

  @include breakpoint-max(1200px) {
    width: 240px;
    margin-right: 20px;
  }

  @include breakpoint-max(1108px) {
    width: 220px;
  }

  @include breakpoint-max(1032px) {
    width: 250px;
    margin-right: 40px;
  }

  @include breakpoint-max(889px) {
    width: 250px;
    margin-right: 20px;
  }

  @include breakpoint-max(833px) {
    width: 230px;
    margin-right: 20px;
  }

  @include breakpoint-max(833px) {
    width: 220px;
    margin-right: 15px;
  }

  > header {
    cursor: pointer;

    > .name {
      height: 56px;
      overflow: hidden;
      display: table-cell;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      vertical-align: middle;
      color: #231F20;
    }

    > figure {
      position: relative;
      min-height: 70px;
      margin-bottom: 20px;
      text-align: center;

      > img {
        position: absolute;
        inset: 0;
        margin: auto;
      }
    }
  }

  > .as-low-as {
    color: #8D8F92;
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 16px;
  }

  > .price {
    margin-bottom: 10px;
    color: #C02332;
    font-weight: bold;
    font-size: 13px;
  }

  > .actions {
    margin-bottom: 10px;
    
    > input {
      width: 70px;
      margin-right: 5px;
      padding: 4px 5px 5px;
      border-radius: 4px;
      border: 1px solid #ccc;
      vertical-align: top;
      font-size: 12px;
      color: #231F20;
      background: transparent;

      @include breakpoint-max(1200px) {
        width: 80px;
      }
    }

    > button {
      padding: 4px 15px;
      
      > img {
        width: 11px;
        vertical-align: 0;
      
        @include breakpoint-max(800px) {
          width: 10px;
        }
      }
    }
  }

  > .extra {
    color: #C02332;
    font-size: 9px;

    > img {
      width: 15px;
      vertical-align: -5px;
    }
  }

  > .note {
    color: #231F20;
    font-size: 12px;
    line-height: 14px;

    @include breakpoint-max(996px) {
      font-size: 11px;
    }

    @include breakpoint-max(800px) {
      font-size: 10px;
    }
  }
}

/* Product Item */
.product-overview-default-wrap {
  width: 100%;
  padding: 25px 0 15px;

  > .top {
    &.link {
      cursor: pointer;
    }

    > figure {
      position: relative;
      width: 100px;
      float: left;
      margin: 0 10px 0 0;
      text-align: center;
      
      @include breakpoint-max(1117px) {
        margin-bottom: 10px;
      }

      > .sale {
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
      }
    }

    > .name {
      width: 28%;
      float: left;
      margin-bottom: 10px;
      padding-right: 15px;
      
      @include breakpoint-max(1117px) {
        width: 70%;
        margin-bottom: 20px;
      }
      
      // @include breakpoint-max(845px) {
      //   width: 70%;
      //   margin-bottom: 20px;
      // }

      > h2 {
        margin: 0 0 15px;
        font-size: 16px;
        line-height: 22px;
      }

      > ul {
        > li {
          font-size: 14px;
        }
      }
    }

    > .desc {
      width: 56%;
      float: left;
      font-size: 14px;
      
      @include breakpoint-max(1117px) {
        width: 100%;
        float: none;
        clear: both;
      }

      > p {
        font-size: 14px;
      }
    }
  }

  > .bottom {

    @include breakpoint-max(1117px) {
      margin-top: 10px;
    }

    > table {
      width: 84%;
      margin-left: 111px;
      
      @include breakpoint-max(1117px) {
        width: 100%;
        margin-left: 0;
      }

      > colgroup {
        > col:nth-child(1) {
          
          @include breakpoint-max(1117px) {
            width: 33%;
          }
      
          @include breakpoint-max(823px) {
            width: 30%;
          }

        }

        > col:nth-child(2) {
      
          @include breakpoint-max(1117px) {
            width: 37%;
          }
      
          @include breakpoint-max(823px) {
            width: 40%;
          }
        }

        > col:nth-child(3) {
          
          @include breakpoint-max(1117px) {
            width: 34%;
          }
      
          @include breakpoint-max(823px) {
            width: 30%;
          }
        }
      }

      > tbody {
        > tr {
          border-bottom: 1px solid #ebebeb;
          position: relative;

          &:last-child {
            border-bottom: none;
          }
  
          > td {
            padding: 6px 0;
          }
        }

        td {
          font-size: 14px;
        }

        .see-more {
          padding: 10px;
          text-align: center;
          font-weight: bold;
          background: #f7f7f7;
          border: 1px solid #ebebeb;
          cursor: pointer;
        }

        .promo {
          margin-top: 2px;
          display: block;
          color: #be1e2d;

          > img {
            margin-right: 5px;
            vertical-align: -4px;
          }
        }

        .f-11 {
          font-size: 12px;
        }

        .strike {
          text-decoration: line-through;
        }

        .name {
          p {
            margin: 0;
          }
          
          a {
            display: block;
            font-weight: bold;
            font-size: 14px;
            color: #000;

            @include breakpoint-max(990px) {
              font-size: 12px;
            }

            > .name-status {
              white-space: nowrap;

              > .date {
                color: #4a4a4a;
                font-weight: normal;
              }
            }
          }

          .location {
            width: 100%;
            font-size: 9px;

            .date {
              color: #7d7d7d;
              font-weight: normal;
              font-size: 10px;
            }
          }

          .bs-tooltip-2 {
            > img {
              width: 12px;
              vertical-align: -2px;
            }

            > .body {

              @include breakpoint-max(825px) {
                left: -15px;
              }

              > .arrow {

                @include breakpoint-max(825px) {
                  left: 22px;
                }
              }
            }

            .country {
              font-weight: bold;
            }

            .status {
              padding: 3px 0;
            }
          }
        }

        .price-wrap {
          padding: 5px 0 5px 21px;
      
          @include breakpoint-max(1200px) {
            padding: 5px 0 5px 19px;
          }
      
          @include breakpoint-max(1140px) {
            padding: 5px 0 5px 17px;
          }
      
          @include breakpoint-max(993px) {
            padding: 0 0 0 15px;
          }
      
          @include breakpoint-max(900px) {
            padding: 0 0 0 8px;
          }
      
          @include breakpoint-max(823px) {
            padding: 0 0 0 5px;
          }

          .info {
            width: 100%;
            font-size: 12px;
      
            @include breakpoint-max(990px) {
              min-width: 175px;
              width: 100%;
            }
            
            tr {
              &:first-child, &:nth-child(3) {
                td {
                  padding-top: 5px;
                }
              }

              &:last-child, &:nth-child(2) {
                td {
                  padding-bottom: 5px;
                }
              }
            }

            td {

              @include breakpoint-max(990px) {
                font-size: 12px;
              }

              @include breakpoint-max(850px) {
                font-size: 11px;
              }
            }
          }

          > .price-old {
            margin-bottom: 0;
            font-size: 11px;

            > span {
              text-decoration: line-through;
            }
          }

          > .price-new {
            margin-bottom: 0;
            font-size: 17px;
            font-weight: 700;
            color: #ed1c24;
          }

          > .save {
            margin-bottom: 15px;
            font-size: 11px;
          }
        }

        .controls {
          width: 100%;
          padding-bottom: 5px;
          text-align: right;
          position: relative;
          
          input {
            width: 77px;
            height: 30px;
            padding: 0 3px ;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
      
            @include breakpoint-max(990px) {
              width: 65px;
            }
      
            @include breakpoint-max(800px) {
              width: 50px;
            }
          }

          .btn-common-radius, .btn-common-hollow {
            padding: 5px 10px;
            text-align: left;
            font-size: 13px;
            vertical-align: bottom;
      
            @include breakpoint-max(1138px) {
              padding: 5px 6px;
            }
      
            @include breakpoint-max(993px) {
              padding: 5px;
              font-size: 12px;
            }

            > img {
              width: 11px;
            }
          }

          .btn-email-notif {
            padding: 5px 8px;

            @include breakpoint-max(840px) {
              padding: 6px 4px;
              font-size: 11px;
            }
          }

          .btn-auction-link {
            padding: 4px 8px;

            @include breakpoint-max(840px) {
              padding: 6px 4px;
              font-size: 11px;
            }
          }
        }

        .options {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          width: max-content;
          gap: 5px;
        }
      }
    }
  }
}

.sales-table-text {
  padding-left: 3px;
  padding-top: 2px;
  vertical-align: middle;
}
