/* arvo-700 - latin */
@font-face {
  font-family: Arvo;
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/arvo-v9-latin-700-2.eot'); /* IE9 Compat Modes */
  src: local('Arvo Bold'), local('Arvo-Bold'),
    url('/fonts/arvo-v9-latin-700-2.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/arvo-v9-latin-700-2.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/arvo-v9-latin-700-2.woff') format('woff'), /* Modern Browsers */
    url('/fonts/arvo-v9-latin-700-2.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/arvo-v9-latin-700-2.svg#Arvo') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: dotsfont;
  src: url('/fonts/dotsfont.eot');
  src: url('/fonts/dotsfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/dotsfont.woff') format('woff'),
    url('/fonts/dotsfont.ttf') format('truetype'),
    url('/fonts/dotsfont.svg#dotsfontregular') format('svg');
  font-display: swap;
}

@font-face {
  font-family: AvenirLTStd-Heavy;
  src: url('/fonts/AvenirLTStd-Heavy.otf'),
    url('/fonts/AvenirLTStd-Heavy.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/AvenirLTStd-Heavy.woff') format('woff'), /* Modern Browsers */
    url('/fonts/AvenirLTStd-Heavy.ttf') format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: AvenirLTStd-Roman;
  src: url('/fonts/AvenirLTStd-Roman.otf'),
    url('/fonts/AvenirLTStd-Roman.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/AvenirLTStd-Roman.woff') format('woff'), /* Modern Browsers */
    url('/fonts/AvenirLTStd-Roman.ttf') format('truetype'); /* Safari, Android, iOS */
  font-display: swap;
}
