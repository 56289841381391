.main-menu {
  &:hover {
    > .menu {
      display: block;
    }
  }
}

.menu {
  position: absolute;
  top: 32px;
  left: 0;
  width: auto;
  display: none;
  z-index: 1000;
  box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

  > .menu-first {
    width: 288px;
    height: 100%;
    background: #fff;

    > li {
      > a {
        position: relative;
        display: block;
        padding: 3px 8px 3px 0;
        font-size: 13px;
        color: #000;
        text-decoration: none;

        &::after {
          @extend %clearfix;
        }

        &:hover, &:active {
          text-decoration: none;
        }

        &:hover {
          color: $orange-mid;
        }

        &.product-launch {
          @include clickable-image-hover-effect();

          > img {
            width: 100%;
          }
        }

        > .icon {
          float: left;
          width: 54px;
          height: 39px;
          vertical-align: -14px;
        }

        > .text {
          float: left;
          width: calc(100% - 54px);
          padding: 10px 0;
          line-height: 1.4;
        }

        > .icon-bullion-savings {
          @extend %icon-bullion-savings-buy;
          width: 53px;
        }

        > .icon-gold {
          @extend %icon-gold;
        }

        > .icon-available-products {
          @extend %icon-available-products;
        }

        > .icon-silver {
          @extend %icon-silver-coin;
          width: 52px;
        }

        > .icon-research {
          @extend %icon-research;
        }

        > .icon-platinum {
          @extend %icon-platinum;
          width: 53px;
        }

        > .icon-palladium {
          @extend %icon-palladium;
          width: 53px;
        }

        > .icon-copper {
          @extend %icon-copper;
        }

        > .icon-coin-supplies {
          @extend %icon-coin-supplies;
          width: 53px;
        }

        > .icon-sell-to-us {
          @extend %icon-sell-to-us;
        }

        > .icon-chart {
          @extend %icon-chart;
        }

        > .icon-market-alerts {
          @extend %icon-market-alerts;
        }

        > .icon-shop {
          @extend %icon-shop;
        }

        > .icon-help {
          @extend %icon-help;
        }

        > .icon-about {
          @extend %icon-about;
        }

        > .icon-contact {
          @extend %icon-contact;
        }

        > .icon-auction {
          @extend %icon-auction;
        }

        &:has(.icon-auction) {
          background-image: url('/img/d/nav-auction-bg.jpg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          @include retina-breakpoint() {
            background-image: url('/img/d/nav-auction-bg-x2.jpg');
          }
        }

        /* > .arrow {
          position: absolute;
          top: 19px;
          right: 10px;
          @extend %arrow-head-down-svg;
        } */
      }

      > .dropdown {
        > li {
          position: relative;
          width: 100%;
          cursor: pointer;

          > a {
            display: block;
            padding: 2px 15px 2px 40px;
            font-size: 13px;
            color: #000;

            &::after {
              @extend %clearfix;
            }

            > .arrow {
              position: absolute;
              top: 19px;
              right: 13px;
              @extend %icon-arrow-head-right-orange;
            }

            &:hover, &.active {
              color: $orange-mid;
            }

            > .icon {
              float: left;
              width: 54px;
              height: 39px;
              vertical-align: middle;
            }

            > .text {
              float: left;
              width: calc(100% - 54px);
              padding: 8px 0 0;
              line-height: 1.4;
            }

            > .icon-all-gold {
              @extend %icon-all-gold;
              width: 53px;
            }

            > .icon-gold-bars {
              @extend %icon-gold;
              width: 52px;
            }

            > .icon-gold-coins {
              @extend %icon-gold-coin;
            }

            > .icon-gold-jewellery {
              @extend %icon-gold-jewellery;
              width: 52px;
            }

            > .icon-gold-numismatics {
              @extend %icon-gold-numismatic;
              width: 53px;
            }

            > .icon-all-silver {
              @extend %icon-all-silver;
            }

            > .icon-silver-bars {
              @extend %icon-silver-bar;
              width: 53px;
            }

            > .icon-silver-coins { 
              @extend %icon-silver-coin;
              width: 52px;
            }

            > .icon-silver-numismatics {
              @extend %icon-silver-numismatic;
              width: 53px;
            }

            > .icon-platinum-bar {
              @extend %icon-platinum;
              width: 53px;
            }
    
            > .icon-platinum-coin {
              @extend %icon-platinum-coin;
              width: 53px;
            }
    
            > .icon-copper {
              @extend %icon-copper;
              width: 53px;
            }

            > .icon-jewellery {
              @extend %icon-jewellery;
            }

            > .icon-silver-wafer {
              @extend %icon-silver-wafer;
            }

            > .icon-auction {
              @extend %icon-auction;
            }
          }
        }
      }
    }

    > li.nav-banner {
      position: relative;
      
      > a {
        padding: 0;

        .nav-banner-text {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
      
  > .menu-third-wrap {
    position: absolute;
    left: 288px;
    width: 423px;
    height: 100%;
    display: block;
    border-top: 1px solid #dedede;       
    border-right: 1px solid #dedede;       
    border-bottom: 1px solid #dedede;       
    box-shadow: 2px 4px 12px -4px rgba(0,0,0, 70%);
    z-index: 101;
    background: #fff;

    > .shadow {
      position: absolute;
      bottom: -1px;
      left: -15px;
      width: 30px;
      height: 30px;
      display: inline-block;
      z-index: 100;
      box-shadow: 0 7px 5px -6px rgba(122,122,122, 70%);
    }
    
    > .menu-third-level {
      width: 100%;
      height: 100%;
      overflow-y: scroll;

      &.gold {
        height: 100%;
        @extend %bg-menu-gold;
        background-position: left bottom;

        > ul {
          padding: 50px 0 0;
          height: 100%;
          @extend %bg-menu-text-gold;
        }
      }

      &.silver {
        @extend %bg-menu-silver;
        background-position: left bottom;

        > ul {
          padding: 50px 0 0;
          height: 100%;
          @extend %bg-menu-text-silver;
        }
      }

      &.platinum {
        @extend %bg-menu-platinum;
        background-position: left bottom;

        > ul {
          padding: 50px 0 0;
          height: 100%;
          @extend %bg-menu-text-platinum;
        }
      }

      > p {
        padding: 8px 20px;
        margin: 0;
        font-size: 22px;
        font-weight: bold;
        color: $orange-mid;
      }

      > ul {
        width: 100%;

        > li {
          > a {
            display: block;
            padding: 4px 15px;
            font-size: 13px;
            color: #000;

            > .icon {
              width: 54px;
              height: 39px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  > .arrow {
    position: absolute;
    top: 1px;
    left: 139px;
    background: #88b7d5;

    @include breakpoint-max(883px) {
      left: 114px;
    }

    @include breakpoint-max(802px) {
      left: 105px;
    }
  }

  > .arrow::after, > .arrow::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  > .arrow::after {
    border-color: rgba(255, 255, 255, 0%);
    border-bottom-color:#fff;
    border-width: 10px;
    margin-left: -10px;
  }

  > .arrow::before {
    border-color: rgba(204, 204, 204, 0%);
    border-bottom-color: #dedede;
    border-width: 11px;
    margin-left: -11px;
  }
}

.research-menu {
  position: relative;

  @include breakpoint-max(850px) {
    padding: 0 5px;
  }

  @include breakpoint-max(817px) {
    font-size: 11px;
  }

  &:hover {
    .menu {
      display: flex;
      align-items: stretch; /* align items in Cross Axis */
      align-content: stretch; /* Extra space in Cross Axis */
    }
  }

  > a {
    padding: 0 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 43px;
  }

  > .menu {
    position: absolute;
    top: 32px;
    left: 0;
    min-width: 288px;
    width: auto;
    display: none;
    z-index: 100;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    @include breakpoint-max(1085px) {
      right: 0;
      left: auto;
    }

    > .menu-first {
      width: 288px;
      height: 100%;
      border: 1px solid #dedede;
      background: #fff;

      > li {
        > a {
          position: relative;
          display: block;
          padding: 8px 8px 8px 0;
          font-size: 13px;
          color: #000;
          text-decoration: none;

          &:hover, &:active {
            text-decoration: none;
          }

          &:hover {
            color: $orange-mid;
          }

          > .icon-parent {
            width: 40px;
            display:inline-block;
            text-align: center;

            > .icon {
              display: inline-block;
              vertical-align: middle;
            }

            > .icon-gold-university {
              @extend %icon-gold-university;
            }

            > .icon-bullion-directory {
              @extend %icon-bullion-directory;
            }

            > .icon-blogs {
              @extend %icon-blogs;
            }
            
            > .icon-bullionstar-prices {
              @extend %icon-bullionstar-prices;
            }

            > .icon-101 {
              @extend %icon-101;
            }

            > .icon-video {
              @extend %icon-video;
            }

            > .icon-why-gold {
              @extend %icon-why-gold;
            }

            > .icon-why-silver {
              @extend %icon-why-silver;
            }

            > .icon-qe-defender {
              @extend %icon-helicopter;
            }
          }
        }

        > .dropdown {
          > li {
            position: relative;
            width: 100%;
            cursor: pointer;

            > a {
              display: block;
              padding: 8px 15px 8px 25px;
              font-size: 13px;
              color: #000;

              > .icon-parent {
                width: 40px;
                display:inline-block;
                text-align: center;

                > .icon {
                  display: inline-block;
                  vertical-align: middle;
                }

                > .icon-gold-market-mechanics {
                  @extend %icon-gold-market-mechanics;
                }

                > .icon-gold-industry-associations {
                  @extend %icon-gold-industry-associations;
                }

                > .icon-central-bank-gold {
                  @extend %icon-central-bank-gold;
                }

                > .icon-gold-market-place {
                  @extend %icon-market-place;
                }

                > .icon-gold-vault {
                  @extend %icon-vaults;
                }

                > .icon-mints-refineries {
                  @extend %icon-refineries;
                }

                > .icon-chinese-gold-market {
                  @extend %icon-chinese-gold-market;
                }

                > .icon-chinese-essentials {
                  @extend %icon-chinese-essentials;
                }

                > .icon-blog {
                  @extend %icon-blog;
                }

                > .icon-bullion-dealers {
                  @extend %icon-bullion-dealers;
                }

                > .icon-miners-refiners {
                  @extend %icon-miners-refiners;
                }

                > .icon-american-bullion-coins {
                  @extend %icon-american-bullion-coins;
                }

                > .icon-exchanges-etfs {
                  @extend %icon-exchanges-etfs;
                }

                > .icon-depositories-shipping {
                  @extend %icon-depositories-shipping;
                }

                > .icon-information-resources {
                  @extend %icon-information-resources;
                }
              }

              > .arrow {
                position: absolute;
                top: 14px;
                right: 13px;
                @extend %icon-arrow-head-right-orange;
              }

              &:hover, &.active {
                color: $orange-mid;
              }
            }

            > .menu-third-level {
              display: none;
            }

            &:hover {
              color: $orange-mid;

              > .menu-third-level {
                position: absolute;
                top: 5px;
                left: 286px;
                width: 250px;
                height: auto;
                display: block;
                border-top: 1px solid #dedede;       
                border-right: 1px solid #dedede;       
                border-bottom: 1px solid #dedede;       
                box-shadow: 2px 4px 10px -6px rgba(0,0,0, 70%);
                background: #fff;

                // @include breakpoint-max(990px) {
                //   left: -250px;
                //   right: 0;
                //   box-shadow: -3px 4px 12px -6px rgba(0,0,0, 70%);
                // }

                // @include breakpoint-max(768px) {
                //   width: 225px;
                //   left: -226px;
                //   right: 0;
                // }

                > ul {
                  width: 100%;

                  > li {
                    > a {
                      display: block;
                      padding: 8px 10px;
                      font-size: 13px;
                      color: #000;

                      &:hover {
                        color: $orange-mid;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.charts-data, .vault-storage, .bullion-center {
  &:hover {
    .menu {
      display: flex;
      align-items: stretch; /* align items in Cross Axis */
      align-content: stretch; /* Extra space in Cross Axis */
    }
  }

  > .menu {
    position: absolute;
    top: 32px;
    left: 0;
    min-width: 288px;
    width: auto;
    display: none;
    z-index: 100;
    box-shadow: 0 4px 12px -4px rgba(0,0,0, 70%);

    > .menu-first {
      width: 288px;
      height: 100%;
      background: #fff;

      > li {
        > a {
          position: relative;
          display: block;
          padding: 3px 8px 3px 0;
          font-size: 13px;
          color: #000;
          text-decoration: none;

          &:hover, &:active {
            text-decoration: none;
          }

          &:hover {
            color: $orange-mid;
          }

          > .icon-parent {
            width: 40px;
            display:inline-block;
            text-align: center;

            > .icon {
              display: inline-block;
              vertical-align: middle;
            }

            > .icon-charts-data {
              @extend %icon-charts-data;
            }

            > .icon-gold-price-data {
              @extend %icon-gold-price-data;
            }

            > .icon-silver-price-data {
              @extend %icon-silver-price-data;
            }

            > .icon-market-alerts {
              @extend %icon-market-alerts-small;
            }

            > .icon-vault-sg {
              @extend %icon-vault-sg;
            }

            > .icon-vault-nz {
              @extend %icon-vault-nz;
            }

            > .icon-vault-us {
              @extend %icon-vault-us;
            }

            > .icon-shipping-sg {
              @extend %icon-shipping-sg;
            }

            > .icon-shipping-us {
              @extend %icon-shipping-us;
            }

            > .icon-sales-taxes {
              @extend %icon-sales-taxes;
            }

            > .icon-sdb {
              @extend %icon-sdb;
            }

            > .icon-shop-small {
              @extend %icon-shop-small;
            }

            > .icon-360 {
              @extend %icon-360;
            }

            > .icon-400-oz {
              @extend %icon-400-oz;
            }
          }
        }
      }
    }
  }
}

.vault-storage {
  > .menu {
    left: 0;
    min-width: 240px;
    width: auto;

    > .menu-first {
      width: 240px;
    }
  }
}

.bullion-center {
  > .menu {
    left: 0;
    min-width: 220px;
    width: auto;

    @include breakpoint-max(1085px) {
      right: 0;
      left: auto;
    }

    > .menu-first {
      width: 220px;
    }
  }
}
