body {
  &.sticky {
    &.openedSubMenu {
      .wrap-body {
        position: absolute;
        left: 0;
        right: 0;
      }
  
      .main-header {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .header-announcement {
      display: none;

      ~ div > .wrap-header {
        #search-suggestion-popup-wrapper > .search-suggestion-popup > .search-suggestion-body {
          max-height: calc(100vh - 170px);
  
          @include breakpoint-max(785px) {
            max-height: calc(100vh - 235px);
          }
        }
      }
    }

    .main-header {
      position: fixed;
      z-index: 8;
      // transition: top 0.2s ease-in-out;
      // animation-name: fade-in-header;
      // animation-duration: 0.2s;
      // animation-fill-mode: forwards;
      top: 0;
      box-shadow: 0 -7px 10px 10px rgba(0,0,0,50%);

      #search-suggestion-popup-wrapper {
        position: fixed;
      }

      // #search-suggestion-popup-wrapper > .search-suggestion-popup > .search-suggestion-body {
      //   max-height: calc(100vh - 170px);

      //   @include breakpoint-max(785px) {
      //     max-height: calc(100vh - 235px);
      //   }
      // }

      // .menu {
      //   top: 30px;
      // }
      // .top {
      //   height: 32px;
    
      //   > .content {
      //     > .left > ul {
      //       padding: 7px 0;
      //     }
    
      //     .right {
      //       > .show-products-in {
      //         padding: 3px;
      //       }
    
      //       > .currency-wrap {
      //         padding: 8px;

      //         &:hover {
      //           > .dropdown {
      //             top: 30px;
      //           }
      //         }
      //       }
    
      //       > .we-speak {
      //         padding: 3px 9px 18px;
              
      //         &:hover {
      //           > .dropdown {
      //             top: 30px;

      //             > .arrow {
      //               &:before {
      //                 border-width: 9px;
      //               }
      //               &:after {
      //                 border-width: 8px;
      //               }
      //             }
      //           }
      //         }

      //         > .triangle {
      //           top: 32%;
      //         }
      //       }
    
      //       .show-products-in {
      //         > label {
      //           display: flex;
      //           align-items: center;
    
      //           > .bs-tooltip-2 {
      //             padding: 0 5px 0 15px;
                  
      //             > .body {
      //               top: 21px;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      // > .bottom {
      //   height: 30px;
      //   > .nav {
      //     > .links {
      //       > li {
      //         > a {
      //           line-height: 30px;
      //         }
      //       }
      //     }
      //     > .crypto-banner {
      //       display: none;
      //     }
      //   }
      // }
      // > .middle {
      // height: auto;
      // padding: 0;
      // > .main-search {
      //   margin: 10px 0 0 3%;

      //   @include breakpoint-max(940px) {
      //     margin-left: 1%;
      //   }
      // }
      // > .right {
      //   > .login {
      //     > .text {
      //       display: none;
      //     }
      //     > .btn-common {
      //       margin: 10px 5px 0 0;
      //     }
      //   }
      //   > .cart {
      //     margin: 17px 0 0;
      //     padding: 0 5px 10px
      //   }
      //   > .account-info {
      //     > .balance > a {
      //       padding-top: 12px;
      //     }
      //     > .hello {
      //       margin-top: 11px;
      //     }
      //   }
      //   > .extra-link-wrap {
      //     top: 1px;
      //   }
      // }
      // .logo img {
      //   @include breakpoint-max(1110px) {
      //     margin-top: 0;
      //   }
      //   @include breakpoint(909px) {
      //       width: 264px;
      //       height: auto;
      //   }
      //   @include breakpoint-max(875px) {
      //     margin-top: 2px;
      //     margin-bottom: 2px;
      //   }
      //   @include breakpoint-max(808px) {
      //     margin-top: 6px;
      //     margin-bottom: 6px;
      //   }
      // }
      // }
    }

    @keyframes fade-in-header {
      from {
        top: -125px;
      }

      to {
        top: 0;
      }
    }
  }
  
  .header-announcement {
    ~ div > .wrap-header {
      #search-suggestion-popup-wrapper > .search-suggestion-popup > .search-suggestion-body {
        max-height: calc(100vh - 230px);

        @include breakpoint-max(785px) {
          max-height: calc(100vh - 260px);
        }
      }
    }
  }
}

.main-header {
  // position: fixed;
  min-width: 768px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: #fff;
  // position: fixed;
  // z-index: 8;
  // transition: top 0.3s ease-in-out;
  // animation-name: fade-in-header;
  // animation-duration: 0.3s;
  // animation-fill-mode: forwards;
  // top: 0;
  
  > .top {
    width: 100%;
    height: 32px;
    background: #f1f1f1;

    > .content {
      position: relative;
      max-width: 1200px;
      width: 100%;
      height: 100%;
      margin: 0 auto;

      @include breakpoint-max(1230px) {
        padding: 0 10px;
      }

      @include breakpoint-max(890px) {
        padding: 0 5px;
      }

      > .left {
        > ul {
          padding: 7px 0;
          display: flex;
          align-items: center;

          // @include breakpoint-max(1200px) {
          //   padding-left: 10px;
          // }
          
          > li {
            margin-right: 2%;
            font-size: 12px;

            @include breakpoint-max(920px) {
              margin-right: 1%;
              font-size: 11px;
            }

            img {
              width: 14px;
              vertical-align: top;
            }

            .call {
              margin-right: 2px;
            }

            > a {
              display: inline-block;
              color: #333;

              &:hover {
                text-decoration: underline;
              }

              @include breakpoint-max(1000px) {
                font-size: 11px;
              }

              @include breakpoint-max(920px) {
                font-size: 10px;
              } 

              > .call-us {
                margin-right: 55px;
              }
            }

            > .open-online {
              margin-top: 1px;
              display: inline-block;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      > .right {
        width: auto;
        white-space: nowrap;
        position: absolute;
        top: 2px;
        right: 0;

        @include breakpoint-max(1220px) {
          right: 5px;
        }

        @include breakpoint-max(920px) {
          right: 2px;
        }

        > .we-speak {
          position: relative;
          padding: 3px 9px 18px;
          cursor: pointer;

          @include breakpoint-max(805px) {
            margin: 0;
            // padding: 8px 5px 12px;
          }

          > .flag {
            position: static;
            height: 21px;
            margin: 0;
            display: block;
          }

          > .triangle {
            position: absolute;
            top: 32%;
            right: 0;
            width: 0;
            height: 0;
            border-top: 3px solid #333;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
          }

          > .dropdown {
            display: none;
          }

          &:hover {
            > .dropdown {
              position: absolute;
              top: 30px;
              right: 0;
              width: 280px;
              padding: 15px;
              display: block;
              background: #fff;
              border: 1px solid #ccc;
              border-radius: 5px;
              z-index: 10;
              cursor: auto;
              box-shadow:0 3px 10px rgba(0, 0, 0, 25%);

              @include breakpoint(1440px) {
                right: -95px;
              }
              
              > .arrow {
                position: absolute;
                top: 0;
                right: 20px;
                background: #88b7d5;

                @include breakpoint(1440px) {
                  right: 115px;
                }
              }

              > .arrow::after, > .arrow::before {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
              }

              > .arrow::after {
                border-color: rgba(255, 255, 255, 0%);
                border-bottom-color: #fff;
                border-width: 8px;
                margin-left: -8px;
              }

              > .arrow::before {
                border-color: rgba(204, 204, 204, 0%);
                border-bottom-color: #ccc;
                border-width: 9px;
                margin-left: -9px;
              }

              > p {
                margin-bottom: 5px;
                font-size: 14px;
                text-align: left;
                color: #000;
                font-weight: bold;
              }

              > ul {
                > li {
                  text-align: left;

                  > a {
                    display: block;
                    color: #000;
                    font-size: 14px;

                    &:hover {
                      color: $orange-light;
                    }

                    > span {
                      position: static;
                      margin-right: 5px;
                      vertical-align: -8px;
                    }
                  }
                }
              }
            }
          }
        }

        > .currency-wrap {
          position: relative;
          width: 45px;
          margin: 0 5px;
          padding: 8px 8px 12px;
          text-align: center;
          line-height: 1;
          cursor: pointer;

          @include breakpoint-max(800px) {
            margin: 0;
            // padding: 14px 3px;
          }

          > .type {
            display: block;
            font-weight: bold;
            font-size: 13px;
            @include no-select();

            @include breakpoint-max(800px) {
              font-size: 11px;
            }
          }

          > .triangle {
            position: absolute;
            top: 41%;
            right: 0;
            width: 0;
            height: 0;
            border-top: 3px solid #333;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
          }

          > .dropdown {
            display: none;
          }

          &:hover {
            > .dropdown {
              position: absolute;
              top: 30px;
              left: -107px;
              width: 230px;
              padding: 13px;
              display: block;
              background: #fff;
              border: 1px solid #ccc;
              border-radius: 5px;
              z-index: 10;
              cursor: auto;
              box-shadow:0 3px 10px rgba(0, 0, 0, 25%);
              
              @include breakpoint-max(1250px) {
                left: -138px;
                padding: 10px;
              }

              @include breakpoint-max(968px) {
                left: -125px;
              }

              > .arrow {
                position: absolute;
                top: 0;
                left: 129px;
                background: #88b7d5;

                @include breakpoint-max(1250px) {
                  left: 160px;
                }

                @include breakpoint-max(968px) {
                  left: 147px;
                }
              }

              > .arrow::after, > .arrow::before {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
              }

              > .arrow::after {
                border-color: rgba(255, 255, 255, 0%);
                border-bottom-color: #fff;
                border-width: 10px;
                margin-left: -10px;
              }

              > .arrow::before {
                border-color: rgba(204, 204, 204, 0%);
                border-bottom-color: #ccc;
                border-width: 11px;
                margin-left: -11px;
              }

              > .radio-one-line {
                margin: 0 0 5px;

                &:last-child {
                  margin-bottom: 0;
                }

                .bullion-radio-title-half {
                  margin: 6px 0 0 6px;

                  @include breakpoint-max(1083px) {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }

        > .show-products-in {
          // padding: 3px 0;
          margin-top: -5px;

          // @include breakpoint-max(855px) {
          //   padding: 4px 0;
          // }
          
          > label {
            margin: 0;
            font-size: 12px;
            line-height: 1;
            display: flex;
            align-items: center;

            @include breakpoint-max(1000px) {
              font-size: 11px;
            }

            @include breakpoint-max(920px) {
              font-size: 10px;
            } 

            > .bs-tooltip-2 {
              padding: 10px 5px 10px 15px;
              margin-left: -10px;
  
              > img {
                cursor: pointer;
              }
  

              > .body {
                top: 34px;
                left: auto;
                right: -49px;
                width: 350px;
                height: auto;
                white-space: normal;
  
                > .arrow {
                  left: auto;
                  right: 63px;
                }
  
                > p {
                  margin: 0;
                  font-size: 12px;
                  font-weight: normal;
                }
              }
            }

            > select {
              margin-left: 0;
              padding: 2px;
              border-radius: 3px;
              background: #f2f2f2;
              border: 1px solid #a2a2a2;
            }
          }
        }
      }
    }
  }

  > .middle {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 55px;
    padding: 0;

    // @include breakpoint-max(1200px) {
    //   width: 100%;
    // }

    // @include breakpoint-max(1024px) {
    //   height: 80px;
    //   padding: 10px 0 0;
    // }
    
    > h1 {
      margin: 0;
    }

    > .float-top {
      position: absolute;
      top: -3px;
      left: 18px;
      z-index: 100;

      @include breakpoint(1200px) {
        left: 30px;
      }

      @include breakpoint-max(1200px) {
        left: 40px;
      }

      @include breakpoint-max(1110px) {
        left: 29px;
      }

      @include breakpoint-max(1024px) {
        left: 23px;
      }

      @include breakpoint-max(980px) {
        left: 21px;
      }

      @include breakpoint-max(950px) {
        left: 19px;
      }

      @include breakpoint-max(875px) {
        left: 17px;
      }

      @include breakpoint-max(808px) {
        left: 15px;
      }
  
      > a {
        font-size: 13px;
      }
    }

    .logo {
      a {
        display: inline-block;
      }

      img {
        // width: 334px;
        // height: 58px;
        height: auto;
        width: 270px;
        margin-top: 1px;

        @include breakpoint-max(1200px) {
          // margin-left: 10px;
        }

        @include breakpoint-max(1110px) {
          // width: 310px;
          // height: 58px;
          // margin-top: 0;
        }

        @include breakpoint-max(1024px) {
          // width: 300px;
          // height: 58px;
        }

        @include breakpoint-max(1000px) {
          margin-top: 6px;
          width: 210px;
          // width: 280px;
          // height: 55px;
          // margin-top: 4px;
        }

        // @include breakpoint(951px) {
        //   width: 276px;
        // }
        @include breakpoint-max(950px) {
          // width: 270px;
          // height: 53px;
        }
        @include breakpoint-max(908px) {
          // width: 264px;
          // height: auto;
          // margin-top: 1px;
        }

        @include breakpoint-max(890px) {
          // width: 210px;
          // margin-top: 6px;
          // height: 47px;
          // margin-bottom: 2px;
        }

        @include breakpoint-max(808px) {
          width: 200px;
          height: 40px;
          // margin-top: 6px;
          // margin-bottom: 6px;
        }
      }
    }

    > .main-search {
      width: 36%;
      margin: 11px 0 0 3%;

      // @include breakpoint-max(1180px) {
      //   width: 35%;
      // }

      @include breakpoint-max(1240px) {
        width: 35%;
      }

      @include breakpoint-max(1130px) {
        width: 28%;
      }

      @include breakpoint-max(940px) {
        // width: 28%;
        margin-left: 1%;
      }

      > .search {
        position: relative;
        width: 100%;
        height: 33px;
        font-size: 0;

        > input {
          width: 90%;
          height: 33px;
          padding: 6px 25px 7px 7px;
          font-size: 13px;
          display: inline-block;
          border: 1px solid #ccc;
          border-radius: 0;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          background: #f5f5f5;

          @include breakpoint-max(1080px) {
            width: 83%;
          }

          &::placeholder {
            font-style: italic;
            font-size: 13px;
          }

          &::-ms-clear {
            display: none;
          }
        }

        > img {
          position: absolute;
          top: 11px;
          right: 12%;
          cursor: pointer;

          @include breakpoint-max(1080px) {
            right: 22%;
          }
        }

        > button {
          position: absolute;
          top: 0;
          height: 33px;
          padding: 8px 12px;
          display: inline-block;
          font-size: 12px;
          border: 0;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          background: $orange-light;
          color: #fff;

          &:hover {
            background: #ca6f20;
          }
        }
      }
    }

    > .right {
      position: relative;

      > .login {
        position: relative;
        margin: 0 5px 0 15px;

        @include breakpoint-max(950px) {
          margin: 0 10px;
        }

        @include breakpoint-max(870px) {
          margin: 0;
        }

        > .text {
          position: absolute;
          width: 180px;
          left: -185px;
          top: 17px;

          @include breakpoint-max(1000px) {
            width: 160px;
            left: -170px;
          }

          @include breakpoint-max(870px) {
            left: -145px;
            width: 140px;
          }

          img {
            width: 100%;
          }
        }

        > .btn-common {
          margin: 11px 5px 0 0;
          padding: 8px 15px;
          font-size: 14px;
          line-height: 1;
          text-align: center;
          text-transform: uppercase;
          border-radius: 3px;

          @include breakpoint-max(950px) {
            padding: 8px 10px;
            font-size: 13px;
            margin: 12px 5px 0 0;
          }

          // &:first-child {
          //   padding: 5px 26px;

          //   @include breakpoint-max(950px) {
          //     padding: 5px 10px;
          //   }
          // }

          // &:last-child {
          //   // margin-left: 10px;
          //   padding: 5px 17px;

          //   @include breakpoint-max(950px) {
          //     padding: 5px 8px;
          //   }
          // }

          &.white {
            background: #fff;
            color: $orange-mid;
            border: 1px solid $orange-mid;

            &:hover {
              color: $orange-light;
              border: 1px solid $orange-light;
            }
          }
        }

        > a {
          margin-top: 18px;
        }
      }

      > .account-info {
        > div {
          margin: 0;
          line-height: 1;

          @include breakpoint-max(986px) {
            margin: 0;
          }

          > p {
            margin-bottom: 0;
            display: block;
            line-height: 14px;
            font-size: 12px;
            color: #000;

            @include breakpoint-max(931px) {
              font-size: 11px;
            }
          }

          > a {
            display: block;

            > .title {
              margin-bottom: 0;
              display: block;
              line-height: 13px;
              font-size: 11px;
              color: #000;

              @include breakpoint-max(931px) {
                font-size: 11px;
              }

              @include breakpoint-max(880px) {
                font-size: 10px;
              }
            }

            > .cash-amount, > .bullion-amount {
              font-weight: bold;
            }

            > span {
              color: $orange-light;
              font-size: 13px;
            }
          }
        }

        > .balance {
          margin-right: 10px;

          @include breakpoint-max(808px) {
            margin-right: 5px;
          }
          
          > a {
            padding: 14px 5px;
          }
        }

        > .hello {
          position: relative;
          margin: 14px 15px 0 5px;
          padding: 0 0 11px 23px;
          cursor: pointer;

          > img {
            position: absolute;
            top: 5px;
            left: 0;
          }

          // @include breakpoint-max(998px) {
          //   margin: 19px 10px 0 5px;
          // }

          // @include breakpoint-max(951px) {
          //   margin: 19px 5px 0;
          // }

          > p {
            font-size: 11px;
            @include no-select();

            > .name {
              width: 95px;
              display: block;
              @extend %ellipsis;
            }
          }

          > b {
            font-size: 12px;
            @include no-select();
          }

          > .triangle {
            position: absolute;
            bottom: 15px;
            right: 5px;
            width: 0;
            height: 0;
            border-top: 3px solid #333;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
          }

          > .dropdown {
            display: none;
          }

          &:hover {
            > .dropdown {
              position: absolute;
              top: 38px;
              right: -50px;
              width: 250px;
              padding: 10px 10px 40px;
              display: block;
              background: #fff;
              border: 1px solid #ccc;
              border-radius: 5px;
              z-index: 10;
              box-shadow:0 3px 10px rgba(0, 0, 0, 25%);

              @media (max-height: 820px) {
                width: 470px;
              }

              > ul {
                margin-bottom: 0;

                @media (max-height: 820px) {
                  column-count: 2;
                }

                > li {
                  > a {
                    display: block;
                    padding: 6px 0;
                    font-size: 12px;
                    color: #000;

                    @media (max-height: 820px) {
                      min-height: 45px;
                      display: flex;
                      align-items: center;
                    }

                    &:hover {
                      color: $orange-mid;
                    }

                    > .icon-parent {
                      width: 50px;
                      display:inline-block;
                      text-align: center;

                      > span {
                        margin-right: 5px;
                        vertical-align: middle;
                      }

                      > .icon-cash-bullion-balance {
                        @extend %icon-cash-bullion-balance;
                      }

                      > .icon-add-funds {
                        @extend %icon-add-funds;
                      }

                      > .icon-withdraw-funds {
                        @extend %icon-withdraw-funds;
                      }

                      > .icon-sell-stored-bullion {
                        @extend %icon-sell-stored-bullion;
                      }

                      > .icon-withdraw-bullion {
                        @extend %icon-withdraw-bullion;
                      }

                      > .icon-withdraw-bsp {
                        @extend %icon-withdraw-bullion-bsp;
                      }

                      > .icon-vault-certificate {
                        @extend %icon-vault-certificate;
                      }

                      > .icon-photo-bullion {
                        @extend %icon-photo-stored-bullion;
                      }

                      > .icon-bullion-savings-program {
                        @extend %icon-bullion-savings;
                      }

                      > .icon-live-audit-report {
                        @extend %icon-live-audit-report;
                      }

                      > .icon-audit-protocols {
                        @extend %icon-audit-protocols;
                      }

                      > .icon-insurance-certificate {
                        @extend %icon-insurance-certificate;
                      }

                      > .icon-storage-charges {
                        @extend %icon-storage-charges;
                      }

                      > .icon-auction {
                        @extend %icon-auction;
                        @include menu-sprite-x1(-59px, -515px);
                        @include retina-breakpoint() {
                          @include menu-sprite-x2(-59px, -515px);
                        }
                        width: 54px;
                        height: 30px;
                      }

                      > .icon-transactions {
                        @extend %icon-transactions;
                      }

                      > .icon-account-details {
                        @extend %icon-account-details;
                      }

                      > .icon-account-api {
                        @extend %icon-account-api;
                      }

                      > .icon-affiliate-program {
                        @extend %icon-affiliate-program;
                      }
                    }
                  }
                }
              }

              > .logout-wrap {
                position: absolute;
                bottom: 7px;
                right: 20px;

                > a {
                  color: #000;
                }

                .icon-logout {
                  @extend %icon-logout;
                  margin-right: 5px;
                  vertical-align: -4px;
                }
              }

              > .arrow {
                position: absolute;
                top: 0;
                right: 56px;
                background: #88b7d5;
              }

              > .arrow::after, > .arrow::before {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
              }

              > .arrow::after {
                border-color: rgba(255, 255, 255, 0%);
                border-bottom-color: #fff;
                border-width: 10px;
                margin-left: -10px;
              }

              > .arrow::before {
                border-color: rgba(204, 204, 204, 0%);
                border-bottom-color: #ccc;
                border-width: 11px;
                margin-left: -11px;
              }
            }
          }
        }
      }

      > .cart {
        position: relative;
        margin: 18px 0 0;
        padding: 0 5px 10px;
        cursor: pointer;

        // @include breakpoint-max(1200px) {
        //   margin-right: 5px;
        // }

        // @include breakpoint-max(950px) {
        //   margin: 25px 10px 0 0;
        // }

        > #shopping-cart-count {
          position: absolute;
          top: -4px;
          left: 13px;
          width: 23px;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          color: #BF6928;
          @include no-select();
        }

        > img {
          width: 35px;
          height: 20px;
        }

        > .cart-dropdown {
          display: none;
          position: absolute;
          top: 31px;
          right: 0;
          width: 400px;
          max-height: 700px;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 5px;
          z-index: 10;
          cursor: auto;
          @include no-select();
          box-shadow:0 3px 10px rgba(0, 0, 0, 25%);

          @include breakpoint-max(1220px) {
            right: 5px;
          }

          img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
          }

          > .arrow {
            position: absolute;
            top: 0;
            right: 20px;
            background: #88b7d5;
          }

          > .arrow::after, > .arrow::before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          > .arrow::after {
            border-color: rgba(255, 255, 255, 0%);
            border-bottom-color: #fff;
            border-width: 10px;
            margin-left: -10px;
          }

          > .arrow::before {
            border-color: rgba(204, 204, 204, 0%);
            border-bottom-color: #ccc;
            border-width: 11px;
            margin-left: -11px;
          }
        }
      }

      > .extra-link-wrap {
        position: absolute;
        top: 1px;
        right: 0;
        margin: 0;

        @include breakpoint-max(1200px) {
          right: 10px;
        }

        > a {
          color: #000;
          font-size: 12px;

          > img {
            vertical-align: -2px;
          }

          &:hover {
            color: $orange-mid;
          }
        }
      }
    }
  }

  #search-suggestion-popup-wrapper {
    position: absolute;
    top: 87px;
    width: 100%;
    z-index: 8;

    > .search-suggestion-bg {
      background-color: #000;
      position: absolute;
      width: 100vw;
      height: 100vh;
      opacity: 0.7;
      z-index: 1;
    }

    > .search-suggestion-popup {
      z-index: 8;
      background: #fff;
      position: relative;
      width: 1030px;
      left: 50%;
      top: 15px;
      transform: translateX(-50%);
      border-top: 4px solid $orange-light;

      @include breakpoint-max(1050px) {
        width: calc(100% - 20px);
        min-width: 728px;
      }

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-radius: 2px;
        position: absolute;
        top: -15px;
        left: 50%;
        margin-left: -15px;
        border-bottom: 15px solid $orange-light;
      }

      .search-suggestion-header {
        padding: 8px 25px;
        background: #f9f9f9;

        > .search-suggestion-title {
          font-size: 15px;
          font-weight: 700;
        }

        > .search-suggestion-close {
          float: right;
          margin-right: -15px;
          cursor: pointer;
          padding: 0 5px;
        }
      }
        
      > .search-suggestion-body {
        padding: 0 0 15px;
        max-height: calc(100vh - 170px);
        min-height: 200px;
        width: 100%;
        display: flex;

        @include breakpoint-max(785px) {
          max-height: calc(100vh - 235px);
        }

        > .no-result {
          margin: 0;
          padding: 10px 0 0;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
        }

        > .search-result-left {
          width: 505px;
          padding: 0 20px;
          overflow-y: auto;

          @include breakpoint-max(1050px) {
            width: auto;
            flex: 1;
            padding-right: 10px;
          }

          > .search-banner {
            margin-top: 8px;

            > a {
              display: block;
              @include clickable-image-hover-effect-dark();
              @extend %disable-drag;
            }

            img {
              @extend %disable-drag;
              width: 100%;
            }
          }
        
          > .search-suggestion-group {
            margin-top: 4px;

            > .group-header {
              padding: 5px 0;
              border-bottom: 1px solid #ebebeb;

              > .group-title {
                font-size: 13px;
                font-weight: 700;
                display: inline-block;
                max-width: 370px;
                line-height: 1.3;
                color: #333;
              }

              > .view-all {
                @extend %disable-drag;
                float: right;
                font-size: 12px;
                margin-top: 4px;
                font-weight: 700;
                color: $orange-light;
              }
            }

            > .group-list > li {
                border-bottom: 1px solid #ebebeb;

                &:last-child {
                  border-bottom: none;
                }

              &.promo {
                .product-img::before {
                  background-image: url(/img/m/sprite-retina-v12.png);
                  background-position: -122px -68px;
                  background-repeat: no-repeat;
                  background-size: 333px;
                  bottom: 0;
                  content: "";
                  display: block;
                  height: 12px;
                  left: 0;
                  position: absolute;
                  width: 30px;
                }

                .price {
                  color: $font-red-dark;

                  > .price-label {
                    color: #8d8f92;
                  }
                }
              }

              &.auction {
                .product-img::before {
                  background: #cd5c5c;
                  color: #fff;
                  content: "Auction";
                  display: inline-block;
                  font-size: 8px;
                  text-align: center;
                  padding: 0 4px;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }
              }

              &.unavailable {
                .price {
                  color: red !important;
                }
              }

              > a {
                @extend %disable-drag;
                display: flex;
                padding: 4px 4px 4px 0;
                align-items: center;
                position: relative;

                &:hover {
                  background: #f1f1f1;
                }

                > .product-img {
                  display: inline-block;
                  overflow: hidden;
                  margin-right: 8px;
                  position: relative;

                  > img {
                    height: 40px;
                    width: 40px;
                  }
                }

                > .name {
                  color: #333;
                  flex: 1;
                  font-size: 13px;
                  line-height: 1.3;
                  padding-right: 20px;

                  @include breakpoint-max(1050px) {
                    padding-right: 10px;
                  }
                }

                > .price {
                  color: #333;
                  font-size: 14px;
                  font-weight: 700;
                  // min-width: 120px;
                  text-align: right;
                  position: relative;
                  line-height: 1;
                  
                  > .price-label {
                    font-size: 11px;
                    font-weight: 400;
                    color: #8d8f92;
                    display: block;
                    line-height: 1;
                    margin-bottom: 2px;

                    > small {
                      font-size: 11px;
                      text-decoration: line-through;
                      color: #8d8f92;
                    }
                  }
                }
              }
            }
          }

          > .btn-show-all {
            width: 100%;
            text-align: center;
            margin-top: 15px;
          }
        }

        > .search-result-right {
          flex: 1;
          padding: 0 20px;
          display: flex;
          flex-direction: column;

          @include breakpoint-max(1050px) {
            padding-left: 10px;
          }

          > .preview {
            margin-top: 8px;
            margin-bottom: 10px;
            min-height: 175px;

            > .preview-item {
              display: flex;

              &.promo {
                .price {
                  color: $font-red-dark !important;
                }
                .preview-img::before {
                  background-image: url(/img/m/sprite-retina-v12.png);
                  background-position: -206px -114px;
                  background-repeat: no-repeat;
                  background-size: 559px;
                  content: "";
                  display: block;
                  height: 18px;
                  left: 10px;
                  position: absolute;
                  top: 0;
                  width: 48px;          
                }
              }

              &.auction {
                .preview-img::before {
                  background: #cd5c5c;
                  color: #fff;
                  content: "Auction";
                  display: inline-block;
                  font-size: 12px;
                  text-align: center;
                  padding: 2px 5px;
                  position: absolute;
                  bottom: -3px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }

              &.unavailable {
                .price {
                  color: red !important;
                }
              }

              > .preview-details {
                flex: 1;
                padding-right: 20px;
                @include breakpoint-max(1050px) {
                  padding-right: 10px;  
                }

                > a {
                  @extend %disable-drag;
                  
                  > .preview-title {
                    font-size: 13px;
                    font-weight: 700;
                    display: block;
                    max-width: 370px;
                    line-height: 1.3;
                    padding: 5px 0;
                    border-bottom: 1px solid #ebebeb;
                    color: #333;
                    margin-bottom: 10px;
                  }
      
                  > .preview-highlights {
                    display: flex;
                    
                    .highlights-left {
                      margin-right: 40px;
                      @include breakpoint-max(1050px) {
                        margin-right: 10px;
                      }
                    }
  
                    .highlights-right {
                      > .coin-detail {
                        display: flex;
                        flex-wrap: wrap;
                        
                        > div:first-child {
                          min-width: 100px;
                          padding-right: 10px;
                        }
                      }
                    }
    
                    .hlabel {
                      font-size: 12px;
                      color: #333;
                      display: block;
                      line-height: 1.2;
                    }
                    .hvalue {
                      font-size: 12px;
                      font-weight: 700;
                      color: #333;
                      display: block;
                      margin-bottom: 8px;
                    }
                  }
                }
              }
  
              > .preview-action {
                max-width: 145px;
                
                > a {
                  @extend %disable-drag;

                  > .preview-img {
                    text-align: center;
                    margin-top: 3px;
                    margin-bottom: 8px;
                    position:relative;
  
                    > img {
                      @extend %disable-drag;
                    }
                  }
                  > .preview-price {
                    > .price-label {
                      color: #8d8f92;
                      font-size: 11px;
                      font-weight: 400;
                      display: block;
                      text-align: center;
                      padding: 0;
                      line-height: 1;
                      margin-bottom: 2px;
  
                      > small {
                        font-size: 11px;
                        text-decoration: line-through;
                        color: #8d8f92;
                      }
                    }
                    > .price {
                      text-align: center;
                      display: block;
                      font-weight: 700;
                      font-size: 14px;
                      color: $orange-light;
                      line-height: 1;
                      margin-bottom: 6px;
                    }
                  }
                }

                > .preview-form {
                  > button, > a {
                    padding: 4px 10px;
                    font-size: 10px;
                  }
                  > .actions {
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
    
                    > input {
                      background: transparent;
                      border: 1px solid #ccc;
                      border-bottom-left-radius: 4px;
                      border-right: 0;
                      border-top-left-radius: 4px;
                      color: #231f20;
                      font-size: 12px;
                      vertical-align: top;
                      padding: 0 5px;
                      height: 25px;
                      width: 60px;
                    }
                    > button {
                      @extend %disable-drag;
                      border: 0;
                      border-radius: 0;
                      border-bottom-right-radius: 4px;
                      border-top-right-radius: 4px;
                      height: 25px;
                      padding: 4px 10px;
                      width: auto;
                      font-size: 10px;
                      line-height: 1;
                    }
                  }
                }
              }
            }
          }
          
          > .tags-wrapper {
            overflow-y: auto;
            flex: 1;

            > .tags {
              margin-bottom: 8px;

              &.showmore {
                > a {
                  &:nth-child(n+12) {
                    display: inline-block;
                  }
                }
              }

              > .tags-title {
                border-bottom: 1px solid #ebebeb;
                color: #333;
                display: block;
                font-size: 13px;
                font-weight: 700;
                line-height: 1.3;
                margin-bottom: 10px;
                max-width: 370px;
                padding: 5px 0;
              }
              > a {
                @extend %disable-drag;
                color: #737373;
                font-size: 13px;
                display: inline-block;
                padding: 5px 6px;
                border: 1px solid #333;
                border-radius: 3px;
                line-height: 1;
                margin-bottom: 9px;
                margin-right: 3px;
  
                &:hover {
                  background: #e8e8e8;
                  border: 1px solid #333;
                  color: #333;
                }

                &:nth-child(n+12) {
                  display: none;
                }
              }

              >.btn-more {
                @extend %disable-drag;
                font-weight: 400;
                padding: 5px 6px;
                font-size: 12px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }

  > .bottom {
    width: 100%;
    height: 32px;
    background: $orange-light;

    > .nav {
      position: relative;
      max-width: 1200px;
      width: 100%;
      height: 100%;
      margin: 0 auto;

      // @include breakpoint-max(1200px) {
      //   width: 100%;
      // }

      > .links {
        width: 100%;

        @include breakpoint-max(1200px) {
          padding: 0 5px;
        }

        > li {
          float: left;
          position: relative;
          // margin: 0 10px 0 0;
          // padding: 0 17px;

          // @include breakpoint-max(1050px) {
          //   // margin-right: 5px;
          //   padding: 0 3px;
          // }

          // @include breakpoint-max(1024px) {
          //   // margin-right: 3px;
          //   padding: 0 2px;
          // }

          // @include breakpoint-max(850px) {
          //   padding: 0 5px;
          // }

          // &:first-child {
          //   margin-left: 0;
          // }

          // &:last-child {
          //   margin-right: 0;
          // }

          > a {
            width: 100%;
            padding: 0 17px;
            display: inline-block;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            line-height: 32px;

            @include breakpoint-max(1100px) {
              padding: 0 12px;
            }

            @include breakpoint-max(1030px) {
              font-size: 11px;
            }

            @include breakpoint-max(980px) {
              padding: 0 6px;
            }
                        
            @include breakpoint-max(895px) {
              font-size: 10px;
            }
                        
            @include breakpoint-max(815px) {
              padding: 0 3px;
            }

            // @include breakpoint-max(1100px) {
            //   padding: 0 9px;
            // }

            // @include breakpoint-max(990px) {
            //   font-size: 11px;
            //   padding: 0 8px;
            // }

            // @include breakpoint-max(920px) {
            //   font-size: 11px;
            //   padding: 0 5px;
            // }
                        
            // @include breakpoint-max(875px) {
            //   padding: 0 4px;
            //   font-size: 10px;
            // }

            // @include breakpoint-max(850px) {
            //   padding: 0 2px;
            // }

            // @include breakpoint-max(750px) {
            //   padding: 0 3px;
            // }
          }

          .submenu {
            display: none;
          }

          &:hover {
            background: $orange-mid;

            .submenu {
              position: absolute;
              top: 43px;
              left: -340px;
              padding: 5px;
              display: flex;
              background: #fff;
              border: 1px solid #ccc;
              border-radius: 5px;
              z-index: 10;

              > .arrow {
                position: absolute;
                top: 0;
                left: 370px;
                background: #88b7d5;
              }

              > .arrow::after, > .arrow::before {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
              }

              > .arrow::after {
                border-color: rgba(255, 255, 255, 0%);
                border-bottom-color: #fff;
                border-width: 10px;
                margin-left: -10px;
              }
              
              > .arrow::before {
                border-color: rgba(204, 204, 204, 0%);
                border-bottom-color: #ccc;
                border-width: 11px;
                margin-left: -11px;
              }

              > div {
                display: flex;  
                align-items: stretch;
                margin-right: 5px;

                &:last-child {
                  margin-right: 0;
                }

                > .bg {
                  align-items: stretch; /* align items in Cross Axis */
                  align-content: stretch; /* Extra space in Cross Axis */
                  padding: 20px;
                  text-align: center;
                  background: linear-gradient(to bottom,  #fff 0%,#fff 51%,#fff 51%,#f6f7f7 74%,#f1f2f2 100%); /* W3C */

                  > .title {
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 1;
                  }

                  > span {
                    font-size: 11px;
                  }

                  > img {
                    display: block;
                    margin: 10px 0;
                  }

                  > p {
                    margin: 10px 0;
                    font-size: 11px;
                  }

                  > a {
                    padding: 3px 15px;
                    border-radius: 3px;
                    font-weight: normal;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      > .crypto-banner {
        position: absolute;
        top: 3px;
        text-align: right;
        right: 0;
        width: 130px;

        img {
          border-radius: 4px;
          width: 100%;
        }

        @include breakpoint-max(1220px) {
          right: 5px;
        }

        // @include breakpoint-max(1100px) {
        //   top: 4px;
        //   right: 5px;
        //   img {
        //     width: 80%;
        //   }
        // }

        @include breakpoint-max(850px) {
          top: 5px;
          // right: 2px;

          img {
            width: 70%;
          }
        }
      }
    }
  }
}
