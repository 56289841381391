.support-tab-wrap {
  margin-top: 5px;

  > .tabs {
    margin-bottom: -1px;
    display: inline-block;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    font-size: 0 !important;

    > li {
      padding: 6px 10px;
      display: inline-block;
      background: #eee;
      border-top: 3px solid #eee;
      font-size: 12px;
      font-weight: bold;
      color: $orange-light;
      cursor: pointer;
      @include no-select();

      &.active {
        color: #000;
        background: #fff;
        border-top: 3px solid $orange-light;
        border-bottom: none;
      }
    }
  }

  > .body {
    padding: 8px;
    border: 1px solid #f3f3f3;

    .opening {
      font-size: 13px;

      h6 {
        margin: 0 0 2px;
        font-size: 13px;
      }
      
      > p {
        margin: 0;
        font-size: 13px;
        line-height: 17px;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .opening.support {
    border: none;
    padding: 0;
  }
}

.fat-orange-line {
  margin-top: 10px;
  margin-bottom: -1px;
  border-top: 3px solid #DC8500;
  float: left;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-bottom: 1px solid white;
  padding: 6px 10px;

  h4 {
    font-size: 15px !important;
  }
}

.opening.support {
  clear: both;
  border: 1px solid #f3f3f3;
  padding: 6px 10px;
}

.opening.support.alone {
  border: none;
  padding: 0;

  > p {
    font-size: 16px;
  } 
}

.help-wrap {
  .opening {
    h3, h4 {
      margin: 8px 0;
    }

    h3 {
      font-size: 15px !important;
    }

    p {
      font-size: 14px !important;
      margin: 0 !important;
    }
  }
}