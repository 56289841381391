.toggle-button-form-field-wrap {
  > label {
    padding: 10px 5px 0 0;
  }
}

.toggle-button-wrap {
  margin: 10px 0;

  .toggle-checkbox {
    display: none;
    
    // add default box-sizing for this scope
    &,
    &::after,
    &::before,
    & *,
    & *::after,
    & *::before,
    & + .toggle-button {
      box-sizing: border-box;

      &::selection {
        background: none;
      }
    }
    
    + .toggle-button {
      outline: 0;
      display: block;
      width: 60px;
      height: 20px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &::after,
      &::before {
        position: relative;
        top: -5px;
        display: block;
        content: '';
        width: 30px;
        height: 30px;
        background: linear-gradient(#fff, #f2f2f2);
        box-shadow: 0 0 0 1px rgb(0 0 0 / 20%), 0 2px 8px 0 rgb(0 0 0 / 20%);
      }
      
      &::after {
        left: 0;
      }
      
      &::before {
        display: none;
      }
    }
    
    &:checked + .toggle-button::after {
      left: 50%;
    }
  }

  // themes
  .bullionstar-toggle {
    + .toggle-button {
      background: #e85656;
      border-radius: 2em;
      transition: all .4s ease;

      &::after {
        border-radius: 50%;
        background: #fff;
        transition: all .2s ease;
      }
    }
    
    &:checked + .toggle-button {
      background: #1bc67c;
    }
  }
}
