#cart-list-wrap {
  > .empty {
    > p {
      margin: 0;
      padding: 10px 0;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }

  > .shopping-cart-heading {
    padding: 10px 10px 10px 15px;
    border-bottom: 1px solid #e5e5e5;

    > p {
      margin: 0;
      font-size: 15px;
      font-weight: bold;
      @include no-select();
    }
  }

  > .shopping-cart-item-container {
    max-height: 58vh;
    overflow-y: auto;
    //pointer-events: none;
    
    > .shopping-cart-item-wrap {
      width: 100%;
      padding: 15px 0;
      display: block;
      background: #fff;
      border-bottom: 1px solid #e5e5e5;
      pointer-events: none;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      
      > .item {
        padding-left: 10px;

        > figure {
          width: 80px;
          text-align: center;

          > a {
            pointer-events: auto;
          }
        }
        
        > .info {
          position: relative;
          margin-left: 85px;
      
          > .title {
            width: 85%;
            margin-bottom: 5px;
            font-size: 13px;
            font-weight: bold;

            > a {
              color: #000;
              pointer-events: auto;
            }
          }
      
          > .remove {
            position: absolute;
            top: 0;
            right: 10px;
            display: inline-block;
            pointer-events: auto;
            
            > img {
              width: 25px;
              opacity: .5;
            }
          }
      
          > .expected-release-date {
            margin-bottom: 5px;
            font-size: 11px;
            font-style: italic;
            color: #ccc;
          }
      
          > .count-controls {
            margin-bottom: 5px;
      
            input {
              width: 70px;
              height: 26px;
              margin-right: 3px;
              padding-left: 3px;
              display: inline-block;
              border-radius: 4px;
              border: 1px solid #ccc;
              font-weight: bold;
              font-size: 13px;
              color: #000;
              pointer-events: auto;
            }
      
            .add {
              display: inline-block;
              pointer-events: auto;
              
              > img {
                width: 25px;
                height: 25px;  /* For IE 11 */
                vertical-align: -8px;
              }
            }
          }
      
          .price {
            margin-bottom: 5px;
            font-size: 11px;
            color: #888;
            white-space: nowrap;
            @include no-select();
          }
      
          .total {
            margin-bottom: 0;
            font-size: 13px;
            font-weight: bold;
            color: #000;
            white-space: nowrap;
            @include no-select();
          }
        }
      }

      > .warning {
        margin: 10px 10px 0;
        background: #fff4cd;
        border: 1px solid #feefb6;
        display: table;

        > p {
          width: 100%;
          margin: 0;
          padding: 8px 10px;
          font-size: 12px;
          color: #6e5c1a;
        }

        > .close-box {
          width: 15%;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          font-weight: bold;
          font-size: 13px;
          cursor: pointer;
          color: #ab9750;
          border-left: 1px solid #f0e3ae;
          pointer-events: auto;
        }
      }
    }
  }

  > .shopping-cart-footer {
    padding: 10px 10px 10px 15px;
    border-top: 1px solid #e5e5e5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #f5f5f5;

    > .cart-items-total {
      margin: 9px 0 0;
      font-weight: bold;
      font-size: 14px;
      @include no-select();
      
      > .shopping-cart-total-products {
        color: $orange-light;
        font-size: 16px;
      }
    }

    > a {
      padding-left: 25px; 
      padding-right: 25px;
      font-size: 18px;
    }
  }
}