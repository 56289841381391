.page-retail-shop {
  margin-bottom: 40px;

  > h2 {
    margin-bottom: 20px;
    font-size: $standard-font-size-heading;
    line-height: 32px;
  }

  > .retail-shop {
    padding: 0;
    margin-bottom: 20px;
    display: block;

    > img {
      width: 100%;
    }
  }

  > .visit-wrap {
    > h3 {
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 26px;
    }

    > .visit {
      margin-bottom: 20px;

      > li {
        margin-bottom: 5px;
        font-size: $standard-font-size;

        > img {
          margin-right: 5px;
          vertical-align: 0;
        }
      }
    }
  }

  .more {
    margin: 30px auto 10px;
    display: flex;
    justify-content: space-between;

    > a {
      padding: 10px;
      display: block;
      border-radius: 5px;
      border: 2px solid $orange-mid;
      font-weight: bold;
      text-align: center;
      font-size: $standard-font-size;
    }
  }
}
