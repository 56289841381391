.btn-contact-us-wrap {
  display: flex;
  flex-wrap: wrap;

  a, div {
    display: block;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .text {
    display: block;
    font-size: 15px;
    font-weight: bold;
    color: #000;
    padding-top: 8px;
    width: 250px;
  }

  .number {
    display: block;
    font-size: 14px;
    color: #000;
  }

  img {
    display: block;
    float: left;
    margin-right: 10px;
  }

  .btn-contact-call {
    > img {
      width: 43px;
      height: 64px;
    }
  }

  .btn-contact-text {
    > img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      margin-top: 2px;
    }
  }

  .btn-contact-email {
    margin-bottom: 10px;

    > img {
      width: 24px;
      height: 24px;
      margin-top: 2px;
    }

    > text {
      padding-top: 12px;
    }
  }

  .phone-numbers {
    .img-cell {
      img {
        width: 24px;
        height: 24px;
      }

      padding-right: 4px;
      padding-top: 4px;
    }

    .number-cell {
      font-size: 14px;
      line-height: 14px;
      padding-top: 4px;
    }

    .number-note {
      font-size: 12px;
    }
  }
}
