.order-confirmation-wrap {
  position: relative;
  width: 73%;
  overflow: hidden;
  float: left;
  padding: 20px;
  color: #333;
  font-size: 12px;

  h1 {
    font-size: $standard-font-size-heading;
    margin: 22px 0 25px;
  }

  h2 {
    font-size: 20px;
    margin: 18px 0;
  }

  p {
    font-size: 14px;
  }

  > div.order-number {
    font-size: 12px;
    font-weight: bold;
    background-color: #fafafa !important;
    height: 38px;
    line-height: 40px;
    padding-left: 15px;
  }

  > .invoice-number {
    float: right;
    margin-right: 15px;
  }

  > .info-table {
    font-size: 12px;
    border-spacing: 0;
    width: 100%;
    max-width: 860px;

    .customer-info {
      width: 35%;
    }

    .date {
      white-space: nowrap;
    }

    .sign-box {
      float: right;
      border: 3px solid red;
      width: 20px;
      height: 20px;
      margin: 2px;
    }

    .title {
      margin: 2px 0;
      white-space: nowrap;
    }

    td {
      font-size: 12px;
      padding: 10px 10px 10px 15px;
      vertical-align: top;
    }

    td p {
      font-size: 12px;
    }

    .total-amount {
      color: #dc8500;
      font-size: 14px;
      font-weight: bold;
    }

    .amount.total-amount {
      color: #dc8500;
    }

    td:not(:last-child) {
      border-right: 2px solid #fafafa;
    }

    td.border-top {
      border-top: 2px solid #fafafa;
    }
  }

  > .item {
    width: 100%;
    max-width: 860px;

    th {
      background-color: #fafafa !important;
    }

    td, th {
      padding: 12px 3px;
      font-size: 12px;
      vertical-align: top;
    }

    th.quantity {
      width: 65px;
    }

    .maximize {
      width: 100%;
    }

    .quantity {
      padding-left: 15px  !important;
      white-space: nowrap;
    }

    td.quantity {
      padding-right: 15px  !important;
    }

    td.manufacturer {
      padding-left: 5px !important;
      white-space: nowrap;
    }

    .sign-box-left, .sign-box-right {
      border: 2px solid black;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      margin-top: -4px;
    }

    .sign-box-left {
      float: left;
    }

    .sign-box-right {
      float: right;
    }

    .amount {
      width: 100px;
      min-width: 100px;
      padding-left: 5px !important;
      padding-right: 15px !important;
      white-space: nowrap;
      text-align: right;
    }

    td {
      border-bottom: 1px solid #fafafa;
    }

    .product-sn td {
      border: none !important;
      padding: 12px 3px 0;
    }

    .sn td {
      padding: 0 3px 12px;
    }

  }

  .summary {
    width: 100%;
    max-width: 860px;
    margin: 0 0 30px;

    .button-cell {
      vertical-align: top;
      width: 100%;
      padding-left: 3px;
      padding-top: 15px;
    }

    table {
      td {
        padding: 5px 3px 5px 15px;
        border-bottom: none;
        font-size: 12px;
        white-space: nowrap;
        text-align: left;
      }

      td.amount {
        padding: 5px 15px 5px 20px;
        text-align: right;
      }

      td.total-amount {
        font-size: 14px;
        font-weight: bold;
      }

      td.amount.total-amount {
        color: #dc8500;
      }

      .-bold {
        border-top: 1px solid #fafafa;
      }

      .no-border.-bold {
        border-top: none;
      }

      tr.vat td {
        font-size: 11px;
        padding-top: 0;
      }
    }
  }

  > .contact {
    padding: 30px 15px;
    font-size: 12px;

    .title {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 12px;
    }

    .bold {
      font-weight: bold;
    }

    .field {
      font-weight: bold;
    }

    > h2 {
      padding: 0 0 18px;
      margin: 0;
    }

    span, p, div {
      font-size: 12px;
    }
  }

  > .other {
    background-color: #fafafa !important;
    padding: 30px 15px;
    font-size: 12px;
    
    &.light {
      border-top: none;
      background-color: white !important;

      @include breakpoint-max(940px) {
        padding: 15px;
      }
  
      @include breakpoint-max(815px) {
        padding: 20px 0;
      }
    }

    .title {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 12px;
    }

    .bold {
      font-weight: bold;
    }

    .field {
      font-weight: bold;
    }

    > h2 {
      padding: 0 0 18px;
      margin: 0;
    }

    span, p, div {
      font-size: 12px;
    }
  }

  > .order-status-wrap {
    margin-top: 30px;

    > h2 {
      margin: 0;
      padding: 10px;
      font-size: 14px;
    }
  }

  .status {
    width: 100%;

    th {
      background-color: #fafafa !important;
      font-size: 12px;
    }

    td, th {
      padding: 7px 38px 7px 10px;
      font-weight: normal;
    }

    td {
      border-bottom: 1px solid #fafafa;
      font-size: 12px;
    }
  }

  tr.no-border td {
    border: none !important;
  }

  .contact-table {
    td {
      padding-right: 8px;
    }

    td.text {
      padding-right: 24px;
      color: #333;
      text-decoration: none;
      white-space: nowrap;
    }

    .open-email-us {
      cursor: pointer;
    }
  }

  .printer-friendly {
    position: absolute;
    top: 35px;
    right: 30px;
    font-size: 12px;
    font-weight: bold;

    img {
      margin-right: 3px;
      margin-top: -3px;
    }

    a {
      color: #333;
    }
  }

  .order-status-button:hover {
    background-color: #A9621E !important;
  }

  .emailFooter {
    text-align: left !important;
  }

  .printOnly {
    display: none;
  }

  .email-table {
    width: 100% !important;
    box-shadow: none !important;
    margin: 0 !important;
  }
}

@media print {
  .wrap-body>.wrapper {
    width: 900px;
  }

  .printer-friendly {
    display: none;
  }

  .dont-print {
    display: none;
  }

  .button-cell a {
    display: none;
  }

  .order-confirmation-wrap {
    width: 100% !important;
  }

  .order-confirmation-wrap img {
    display: inline-block;
  }

  .order-confirmation-wrap .other h2 {
    display: block;
  }

  .order-confirmation-wrap .order-status-wrap {
    display: none;
  }
}
