/* Base */
html {
  height: 100%;
  width: 100%;
  min-height: 1265px;
  overflow-y: scroll;

  @include breakpoint(1200px) {
    overflow-x: hidden;
  }
}

body { 
  height: 100%;
  width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

sup {
  color: #000;
  font-size: 8px;
}

*, body {
  font-family: Arial, Helvetica, sans-serif;
}

@include breakpoint-max(855px) {
  *, body {
    letter-spacing: normal;
  }
}

h1, h2, h3, h4, h5 ,h6 {
  margin: 10px 0;
  font-weight: bold;
}

.optional::placeholder {
  font-style: italic;
  font-size: 13px;
}

.txt-pin-code {
  font-family: dotsfont, sans-serif;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: -2px;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

p {
  font-size: 13px;
}

a {
  color: $orange-mid;
  @include no-select();

  &:hover, &:active, &:focus, &:visited {
    text-decoration: none;
    outline: none;
    color: $orange-mid;
  }

  &:hover {
    color: $orange-mid;
  }
}

textarea:focus, input:focus{
  outline: none;
}

/* stylelint-disable property-no-vendor-prefix */

input[type=number] {
  -moz-appearance:textfield;

  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; 
  }
}

input[readonly] {
  color: #939598;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
}
/* stylelint-enable property-no-vendor-prefix */