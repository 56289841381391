
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.auction-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;

  > .product-auction {
    position: relative;
    width: 208px;
    min-height: 411px;
    height: auto;
    padding: 15px 7px;
    outline: none;
    background: #fff;
    border: 1px solid #ebebeb;

    &.wide {
      width: 424px;

      > a {
        color: inherit;
        
        > header {
          margin-bottom: 4px;
          cursor: pointer;
      
          > .name {
            font-size: 20px;
          }
        }
      }
    }

    @keyframes bellshake {
      0% { transform: rotate(0); }
      15% { transform: rotate(3deg); }
      30% { transform: rotate(-3deg); }
      45% { transform: rotate(2deg); }
      80% { transform: rotate(-2deg); }
      90% { transform: rotate(1deg); }
      100% { transform: rotate(0); }
    }
    
    &:hover, &:focus {
      /* animate bell icon to shake */
      .bell.watchlist {
        animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
        backface-visibility: hidden;
        transform-origin: top right;
      }
    }

    > .time-left {
      margin: 0 auto 8px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      color: $font-black;
      display: block;
      width: fit-content;

      &.lessThan24hrs {
        color: $orange-light;
      }
      &.lessThan15min {
        color: $font-red-dark;
      }
    }

    > .ends {
      margin: 0 auto 5px;
      text-align: center;
      line-height: 1;
      color: #8D8F92;
      font-size: 12px;
      display: block;
      width: fit-content;
    }

    > .bell {
      position: absolute;
      top: 0;
      right: 5px;
      cursor: pointer;

      &.watchlisted {
        color: $orange-mid;
      }
    }

    > a {
      color: inherit;
      
      > header {
        margin-bottom: 4px;
        cursor: pointer;
    
        > .name {
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          line-height: 1.2;
          color: #231F20;
        }
    
        > figure {
          position: relative;
          min-height: 100px;
          margin: 10px 0;
          text-align: center;
    
          > img {
            position: absolute;
            inset: 0;
            width: 100px;
            height: 100px;
            margin: auto;
          }
        }
      }
    }

    > .price-text {
      margin: 0 0 2px;
      text-align: center;
      color: #333;
      transition: color 0.5s;
    }

    > .price-text-hint {
      font-size: 11.5px;
      text-align: center;
      color: #8D8F92;
    }

    > .price {
      height: 27px;
      margin: 0;
      text-align: center;
      color: $orange-light;
      font-size: 18px;
      font-weight: bold;
    }

    > .autobid-reached {
      color: $font-red-dark;
      font-size: 12px;
      text-align: center;
      line-height: 1;
      margin-bottom: 1px;
    }

    > .actions {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      
      > input {
        width: 80px;
        padding: 5px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid #ccc;
        border-right: 0;
        vertical-align: top;
        font-size: 12px;
        color: #231F20;
        background: transparent;
      }
  
      > button {
        width: 80px;
        padding: 7px;
        font-size: 11px;
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0;
        
        &.btn-edit-bid, &.btn-view-bids {
          border-radius: 4px;
        }
      }
    }

    > .bid-count {
      cursor: pointer;
      text-align: center;
      line-height: 1;
      color: $orange-mid;
      font-size: 12px;

      &.no-bid {
        cursor: auto;
        color: #8D8F92;
      }
    }

    > .btn-buy-now {
      display: block;
      width: 100%;
      padding: 5px;
      max-width: 160px;
      margin: 0 auto;
    }

    &.ended {
      background: #fff;
      opacity: .8;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        background: linear-gradient(-225deg, rgba(216,216,216,30%) 30%, rgba(255,255,255,70%) 50%, rgba(234,234,234,67%) 85%);
        z-index: 2;
        opacity: 0.6;
        cursor: pointer;
      }
    
      > .actions {
        position: relative;
        z-index: 3;
      }

      > .bid-count {
        position: relative;
        z-index: 3;
      }
      
      &.winner {
        > a > header > figure::after {
          content: '';
          display: block;
          width: 180px;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url("/img/icon_auctionwon-v2.svg");
          background-repeat: no-repeat;
          background-position: center;
          z-index: 3;
        }
      }
      // &:has(.price-text.my-leading) {
      //   --border-size: 3px;
      //   --border-angle: 0turn;
      //   background-image: conic-gradient(from var(--border-angle), #eaeaea, #eaeaea 50%, #eaeaea), conic-gradient(from var(--border-angle), transparent 20%, #ecc090, #ff8500);
      //   background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
      //   background-position: center center;
      //   background-repeat: no-repeat;
      //   animation: bg-spin 3s linear infinite;
      // }
    }

    // @keyframes bg-spin {
    //   to {
    //     --border-angle: 1turn;
    //   }
    // }

    // @keyframes bg-spin {
    //   to {
    //     --border-angle: 1turn;
    //   }
    // }

    &.my-leading {
      > .price-text {
        color: #0c9358;
      }
    }

    &.change-leading:not(.my-leading) {
      animation: pulse-orange 7s ease;
      box-shadow: 0 0 0 0 rgb(218 134 23 / 100%);

      > .price-text, > .price {
        color: $font-red;
      }
    }

    @keyframes pulse-orange {
      0% {
        box-shadow: 0 0 0 0 rgb(218 134 23 / 80%);
      }
      
      70% {
        box-shadow: 0 0 0 10px rgb(218 134 23 / 0%);
      }
      
      100% {
        box-shadow: 0 0 0 0 rgb(218 134 23 / 0%);
      }
    }
  }

  > .no-product {
    font-size: 14px;
  }
}