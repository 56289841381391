.search-help-page-box {
  position: relative;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;

  > input {
    width: calc(100% - 98px);
    height: 100%;
    margin-left: 5px;
    padding: 5px;
    border: none;
    font-size: 14px;
    outline: none;

    &::-ms-clear {
      display: none;
    }
  }

  > #help-search-clear {
    position: absolute;
    top: 0;
    right: 35px;
    padding: 10px 5px;
    cursor: pointer;
  }

  > .btn-search {
    position: absolute;
    top: 0;
    right: 0;
    width: 98px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $orange-light;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;

    > .image {
      width: 28px;
      padding: 4px;
    }

    > span {
      color: #fff;
      font-size: 16px;
    }
  }

  > .result {
    position: absolute;
    top: 39px;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 400px;
    padding: 0 10px;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 20;
    overflow-y: auto;

    > ul {
      > li {
        > a {
          padding: 7px 5px;
          display: block;
          font-size: 14px;
          color: #000;

          &:hover {
            background: #f7f7f7;
          }
        }
      }
    }

    > p {
      margin: 0;

      &.no-result {
        padding: 10px 0;
        font-style: italic;
      }
    }
  }
}
