.show-other-fields {
  position: relative;
  padding: 10px 0;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;

  > p {
    font-size: inherit;
  }

  > .image {
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 2;
    @extend %arrow-head-down-svg;

    &.active {
      @extend %arrow-head-up-svg;
    }
  }
}

.account-heading-no-toggle {
  padding: 10px 0;
  font-weight: bold;
  font-size: 14px;
}
