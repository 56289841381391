.verification {
  margin-bottom: 15px;
  font-size: 14px;
}

.terms {
  font-size: 14px;
}

.amount {
  white-space: nowrap;
}

.no-break {
  white-space: nowrap;
}

.customer-info {
  .info {
    margin-bottom: 5px;

    .name {
      margin: 0;
    }
  }
}

input.amount {
  text-align: right;
}

svg text {
  -webkit-touch-callout: none;
  user-select: none;
  pointer-events: none;
}

@media print {
  html {
    min-height: 0;
    overflow-y: auto;
  }

  a[href]::after {
    content: "";
  }
}
